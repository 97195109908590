import styled, { css } from 'styled-components'

import DataShower from 'pages/Consortium/SolicitationProcess/Components/DataShower'
import SelectComponent from 'components/Inputs/AutoCompleteSelect'
import NewInput from 'components/NewInput'

const spacePlusError = css`
  &&& {
    margin-bottom: ${({ theme }) => theme.spacing(7)}px;

    div {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }

    span {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`

export const DataDisplay = styled(DataShower)`
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`
export const DelimiterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    max-width: 630px;
    padding-bottom: 0;
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    padding-right: ${({ theme }) => theme.spacing(3)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0;
  }
`

export const DelimiterInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div {
    &:first-child {
      margin-bottom: ${({ theme }) => theme.spacing(4)}px;
    }

    &:last-child {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;

      @media ${({ theme }) => theme.mediaQueries.sm} {
        margin-bottom: ${({ theme }) => theme.spacing(4)}px;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 615px;
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    width: 610px;
    padding-right: ${({ theme }) => theme.spacing(3)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    padding: 0;
  }
`

export const Select = styled(SelectComponent)`
  margin: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(2)}px`};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(2)}`}px;
  }
`

export const InputPersonalData = styled(NewInput)<{ isLast?: boolean }>`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  ${({ error }) => error && spacePlusError}
  &&& {
    div {
      margin-bottom: ${({ isLast }) => isLast && 0}px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    margin: ${({ theme }) => `0 ${theme.spacing(2)}px`};
  }
`
