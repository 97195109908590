import { Types, IDocument } from './types'

export function GetDocumentsRequest() {
  return {
    type: Types.GET_DOCUMENTS_REQUEST,
  }
}

export function GetDocumentsSuccess(payload: IDocument[]) {
  return {
    type: Types.GET_DOCUMENTS_SUCCESS,
    payload,
  }
}

export function GetDocumentsFailure() {
  return {
    type: Types.GET_DOCUMENTS_FAILURE,
  }
}
