import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'

import StepBar from 'components/StepBar'

import { PageTip } from 'pages/Consortium/commons'
import { Steps } from 'pages/Consortium/SolicitationProcess/Types'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { Proponent } from 'pages/Consortium/SolicitationProcess/Types/Form'
import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'

import { StepComponentProps } from 'hooks/FluxManagement/types'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'

import { ApplicationState } from 'store/ApplicationState'
import { ICustomerProfile } from 'store/modules/CustomerProfile/types'

import { validForm, hasGrossMonthlyIncomeError } from './utils'

import * as c from './cards'
import * as s from './styles'

const InformationConfirmation: React.FC = () => {
  const { hire, page, buttons } = TextsConsortium()
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()

  const [formData, setFormData] = React.useState<Proponent>()
  const [isFormEdit, setIsFormEdit] = React.useState<boolean>(false)

  const grossMonthlyIncome = useSelector(
    (state: ApplicationState) => state.consortiumIncomeValidate
  )

  const { data: clientData } = useSelector(
    (state: { customerProfile: ICustomerProfile }) => state.customerProfile
  )

  React.useEffect(() => {
    if (form && !formData) {
      setFormData(form.proposal.proponente)
    }

    if (formData && formData.nome === '' && clientData) {
      setFormData({
        ...formData,
        cpf: clientData.cpfCnpj,
        nome: clientData.customerName,
        email: clientData.customerEmail,
        codigo_nacionalidade: 'BRASILEIRA',
        dt_nascimento: moment(clientData.customerBirthdate).format(
          'DD/MM/YYYY'
        ),
        telefone: {
          ddi: '55',
          ddd: clientData.customerAreaCode,
          numero: clientData.customerPhoneNumber,
          tipo:
            clientData.customerPhoneNumber.length === 9 ? 'CELULAR' : 'FIXO',
        },
      })
    }
  }, [form, formData, clientData])

  React.useLayoutEffect(() => {
    const layerChildrenElement = document.querySelector('#layer-children')
    if (layerChildrenElement) {
      layerChildrenElement.scrollTo({
        top: 0,
      })
    }
  }, [])

  const isDisabled = React.useCallback(() => {
    if (formData) {
      return validForm(formData, hasGrossMonthlyIncomeError(grossMonthlyIncome))
    }
    return false
  }, [formData, grossMonthlyIncome])

  const handlerSubmit = () => {
    if (formData) {
      updateForm({
        ...form,
        proposal: {
          ...form.proposal,
          proponente: formData,
        },
      })

      instantiate(Steps.HIRE_STEP_TWO)
    }
  }

  React.useEffect(() => {
    if (formData) {
      const address = formData.endereco_residencial

      if (!isFormEdit) {
        if (
          !address.bairro ||
          !address.cep ||
          !address.cidade ||
          !address.logradouro ||
          !address.numero ||
          !address.uf
        ) {
          setIsFormEdit(true)
        }
      }
    }
  }, [formData, isFormEdit, setIsFormEdit])

  if (!formData) {
    return null
  }

  return (
    <>
      <StepBar
        title={hire.title}
        type={page}
        actualStep={hire.steps.informationConfirmation.actualStep}
        totalNumberOfSteps={hire.stepMax}
      />
      <s.ContentContainer>
        <s.ColumnSide lg={3} md={10}>
          <PageTip
            title={hire.steps.informationConfirmation.title}
            subtitle={hire.steps.informationConfirmation.subtitle}
          />
        </s.ColumnSide>
        <s.Column lg={8} md={10}>
          <c.CardStructure
            title={hire.steps.informationConfirmation.containerFirst.title}
            link={hire.steps.informationConfirmation.containerFirst.link}
          >
            <c.PersonalDataCard formData={[formData, setFormData]} />
          </c.CardStructure>
          <c.CardStructure
            title={hire.steps.informationConfirmation.containerSecond.title}
            link={hire.steps.informationConfirmation.containerSecond.link}
          >
            <c.ContactCard formData={[formData, setFormData]} />
          </c.CardStructure>

          <c.CardStructure
            title={hire.steps.informationConfirmation.containerFourth.title}
            link={
              !isFormEdit
                ? hire.steps.informationConfirmation.containerFourth.link
                : undefined
            }
          >
            <c.AddressCard
              formData={[formData, setFormData]}
              isFormEdit={isFormEdit}
            />
          </c.CardStructure>

          <c.CardStructure
            title={hire.steps.informationConfirmation.containerThird.title}
          >
            <c.DocumentCard formData={[formData, setFormData]} />
          </c.CardStructure>
          <c.CardStructure
            title={hire.steps.informationConfirmation.containerFifth.title}
          >
            <c.PpeCard formData={[formData, setFormData]} />
          </c.CardStructure>
          <c.CardStructure
            lastItemMarginBottom
            title={hire.steps.informationConfirmation.containerSixth.title}
          >
            <c.FinancialCard formData={[formData, setFormData]} />
          </c.CardStructure>
        </s.Column>
      </s.ContentContainer>
      <s.FooterContainer>
        <s.FooterContent>
          <s.RowContent>
            <s.Column lg={3} />
            <s.Column lg={8} md={11}>
              <s.ButtonAlign>
                <s.NextButton
                  data-testid="button-next-id"
                  color="secondary"
                  disabled={!isDisabled()}
                  onClick={handlerSubmit}
                >
                  {buttons.next}
                </s.NextButton>
              </s.ButtonAlign>
            </s.Column>
          </s.RowContent>
        </s.FooterContent>
      </s.FooterContainer>
    </>
  )
}

export default InformationConfirmation
