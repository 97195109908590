import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  StepComponentProps,
  useStackFluxManagement,
  useFormFluxManagement,
} from 'hooks/FluxManagement'
import { useToast } from 'hooks/toast'

import { Steps } from 'pages/Consortium/SolicitationProcess/Types/Steps.constant'
import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'

import { SummarySectionProps } from '../localComponents/SummarySection/models'

import { ClearQuotes } from 'store/modules/Consortium/QuoteReservation/action'
import { ProposalSimulationsClearData } from 'store/modules/Consortium/ProposalSimulations/action'

export interface Summary {
  consortium: SummarySectionProps
  portion: SummarySectionProps
  payment: SummarySectionProps
}

export const useDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = useCallback(() => setIsDrawerOpen(!isDrawerOpen), [
    setIsDrawerOpen,
    isDrawerOpen,
  ])

  return { isDrawerOpen, toggleDrawer }
}

export const useModal = () => {
  const { resetForm } = useFormFluxManagement()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const resetSimulation = useCallback(() => {
    resetForm()

    dispatch(ClearQuotes())
    dispatch(ProposalSimulationsClearData())
  }, [resetForm, dispatch])

  const toggleModal = useCallback(() => setIsModalOpen(!isModalOpen), [
    setIsModalOpen,
    isModalOpen,
  ])

  return { resetSimulation, isModalOpen, toggleModal }
}

export const useErrorFeedback = (
  data: FormProposalConsortium | null,
  isLoading: boolean,
  hasError: boolean,
  numberOfSends: number
) => {
  const { addToast, removeToast, toasts } = useToast()
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  useEffect(() => {
    if (!isLoading && toasts && toasts.length > 0) {
      setTimeout(() => {
        removeToast(toasts[0].id)
      }, 1500)
    }
  }, [isLoading, removeToast, toasts])

  useEffect(() => {
    if (hasError) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar solicitação. ',
        description: 'Por favor, tente novamente.',
      })
    }
  }, [hasError, numberOfSends, addToast])

  useEffect(() => {
    if (data) {
      return instantiate(Steps.REQUEST_RESULT)
    }
    if (!data) {
      if (numberOfSends === 3) {
        return instantiate(Steps.REQUEST_RESULT)
      }
    }
  }, [numberOfSends, addToast, instantiate, data])
}
