import styled, { css } from 'styled-components'
import Text, { IProps } from 'components/Text'
import Avatar from 'assets/images/user.png'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import Button from 'components/Button'

interface IItemSideMenu extends IProps {
  active: boolean
  onClick: () => void
}

const itemSideMenuActive = css`
  border-left: 2px solid ${({ theme }) => theme.colors.blue4};
`

export const ContainerCmp = styled(Container)`
  height: 100vh;
  margin-top: 21px;
  padding-bottom: ${({ theme }) => theme.spacing(9)}px;
`

export const RowCmp = styled(Row)`
  @media ${props => props.theme.mediaQueries.lgUp} {
    & > div {
      &:first-child {
        margin-right: ${({ theme }) => theme.spacing(2)}px;
      }
      &:last-child {
        margin-left: ${({ theme }) => theme.spacing(2)}px;
      }
    }
  }
`

export const Column = styled(Col)``

export const WrapperCard = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;

  & > div {
    padding: 0;
  }
`
export const SideMenuContainer = styled.header`
  position: sticky;
  top: 49px;
`

export const TitleSideMenu = styled(Text).attrs(() => ({
  type: 'subheading',
  color: 'gray6',
  bold: true,
}))`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const ContentSideMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const ItemSideMenu = styled(Text).attrs(() => ({
  type: 'caption',
  color: 'gray6',
}))<IItemSideMenu>`
  min-height: 28px;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 2px solid ${({ theme }) => theme.colors.gray1};
  cursor: pointer;

  ${({ active }) => active && itemSideMenuActive}
`

export const SubtitleSideMenu = styled(Text).attrs(() => ({
  type: 'subheading',
  color: 'gray6',
  textTransform: 'uppercase',
  bold: true,
}))`
  margin: ${({ theme }) => theme.spacing(3)}px 0;
`

export const CardSideMenu = styled.main`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  align-items: center;
`

export const AvatarSideMenu = styled.img.attrs(() => ({
  src: Avatar,
  alt: 'Avatar PRO',
}))`
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

export const WrapperCardSideMenu = styled.div``

export const TitleCardSideMenu = styled(Text).attrs(() => ({
  type: 'headline',
  color: 'gray6',
}))`
  font-weight: 400;
`

export const SubtitleCardSideMenu = styled(Text).attrs(() => ({
  type: 'caption',
  color: 'gray5',
}))``

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing(2)}px;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: 100vw;
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
`

export const FooterContent = styled(Container)``

export const RowContent = styled(Row)`
  @media ${props => props.theme.mediaQueries.lgUp} {
    & > div {
      &:first-child {
        margin-right: ${({ theme }) => theme.spacing(2)}px;
      }
      &:last-child {
        margin-left: ${({ theme }) => theme.spacing(2)}px;
      }
    }
  }
`

export const ButtonAlign = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const NextButton = styled(Button)`
  width: 100%;
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 180px;
  }
`

export const TitleCard = styled(Text).attrs(() => ({
  type: 'headline',
  bold: true,
  color: 'gray6',
}))`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const ArticleCard = styled.article`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  max-width: 630px;
`

export const TextArticleCard = styled.span`
  ${({ theme }) => theme.fontTypes.body};
  color: ${({ theme }) => theme.colors.gray5};
`

export const LinkArticleCard = styled.span`
  ${({ theme }) => theme.fontTypes.body};
  color: ${({ theme }) => theme.colors.blue4};
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export const ButtonCard = styled.button`
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(4)}px;
  ${({ theme }) => theme.fontTypes.button};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.blue4};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.blue4};
  cursor: pointer;
`

export const WrapperCardInsured = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  box-sizing: border-box;
  border-radius: 7px;
  padding: ${({ theme }) => theme.spacing(7)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const ScrollableWrapper = styled.div.attrs(() => ({
  id: 'scroll-container',
}))`
  overflow: auto;
  padding: 0px;
  position: relative;
  height: 100%;
`

export const NavWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
  width: 100%;
  position: sticky;
  top: 0;
`

export const NavTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const TagsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`

export const BadgeNotification = styled.span`
  padding: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.magenta2};
  margin-left: 4px;
  margin-bottom: 6px;
`

export const Tag = styled.div<{ active: boolean }>`
  display: flex;
  height: 28px;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(3)}px;

  ${({ active, theme }) =>
    active
      ? `
    border-left: 2px solid ${theme.colors.blue4};
  `
      : ''}

  &:hover {
    background-color: #ebebeb;
  }
`

export const TagText = styled(Text).attrs(() => ({
  type: 'caption',
  color: 'gray6',
  bold: true,
}))`
  cursor: pointer;
`

export const ScrollableContainer = styled.div.attrs(() => ({
  id: 'scroll-container',
}))`
  overflow: auto;
  padding: 0px;
  position: relative;
  height: calc(100vh - 64px);
`
