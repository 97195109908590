import { cpf } from 'cpf-cnpj-validator'
import { errorObject } from '../../types'

export const validationSpouseName = (
  value: string,
  isTouched: boolean
): errorObject | boolean => {
  if (isTouched && value === '') {
    return {
      icon: 'information',
      message: 'Esse campo é obrigatório.',
    }
  }

  return false
}

export const validationDocumentSpouse = (
  spouseDocument: string,
  isTouched: boolean
): errorObject | boolean => {
  if (isTouched && spouseDocument) {
    if (!cpf.isValid(spouseDocument)) {
      return {
        icon: 'information',
        message: 'CPF inválido. Por favor, verifique o número',
      }
    }
  }

  return false
}
