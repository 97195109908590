export const TIPO_PESSOA = {
  FISICA: 1,
  JURIDICA: 2,
}

export const TIPO_BUSCA = {
  CREDITO: 1,
  PARCELA: 2,
  CREDITO_E_PARCELA: 3,
}

export const CODIGO_PRODUTO = {
  IMOVEL: 1,
  AUTOMOVEL: 2,
}

export const CODIGO_CANAL = {
  PRO: 2,
  CUSTOMER: 1,
}
