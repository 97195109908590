import { Types, IAction, IProposalConsortiumState } from './types'

const INITIAL_STATE: IProposalConsortiumState = {
  data: null,
  errorMsg: '',
  isLoading: false,
  hasError: false,
}

export default function(state = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case Types.GET_PROPOSAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_PROPOSAL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }
    case Types.GET_PROPOSAL_FAILURE:
      return {
        ...state,
        errorMsg: action.payload,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}
