import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing(6)}px;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: ${({ theme }) => theme.spacing(9)}px;
  background-color: white;
  border-top: 1px solid ${props => props.theme.colors.gray3};

  @media ${({ theme }) => theme.mediaQueries.xs} {
    padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
  }
`
