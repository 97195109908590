import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import {
  CloseButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerTitle,
  DrawerWrapper,
  DrawerContent,
} from './styles'

interface IProps {
  drawerTitle?: string
  footer?: React.ReactNode
  footerHeight?: string
  handleClose?: () => any
  header?: React.ReactNode
  isLoading?: boolean
  isOpen: boolean
  positionDrawer?: string
  forceHideOverflowY?: boolean
  size?: 'sm' | 'md' | 'lg'
}

const Drawer: React.FC<IProps> = ({
  children,
  footer,
  footerHeight,
  handleClose,
  header,
  isOpen,
  drawerTitle,
  forceHideOverflowY = false,
  positionDrawer = 'right',
  ...rest
}) => {
  useEffect(() => {
    const body = document.querySelector('body')
    const html = document.querySelector('html')

    if (isOpen) {
      body!.style.overflow = 'hidden'
      html!.style.overflowY = 'hidden'
    }

    function handleOverflow() {
      if (!forceHideOverflowY) {
        body!.style.overflow = 'visible'
        html!.style.overflowY = 'visible'
      }
    }
    return () => handleOverflow()
  }, [isOpen, forceHideOverflowY])

  return ReactDOM.createPortal(
    <DrawerWrapper>
      <DrawerOverlay isOpen={isOpen} onClick={handleClose} />
      <DrawerContent positionDrawer={positionDrawer} isOpen={isOpen} {...rest}>
        <DrawerHeader>
          {header || (
            <>
              <DrawerTitle>{drawerTitle}</DrawerTitle>
              <CloseButton
                data-gtm-subname={`menu ${drawerTitle}`}
                data-gtm-name="botao fechar"
                data-gtm-type="click"
                data-gtm-clicktype="button"
                name="close"
                onClick={handleClose}
              />
            </>
          )}
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
        {footer && (
          <DrawerFooter footerHeight={footerHeight}>{footer}</DrawerFooter>
        )}
      </DrawerContent>
    </DrawerWrapper>,
    document.querySelector('#root') as HTMLDivElement
  )
}

export default Drawer
