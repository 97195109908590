import styled from 'styled-components'
import Text from 'components/Text'
import ButtonComp from 'components/Button'

export const Description = styled(Text).attrs(() => ({
  type: 'headline',
  color: 'gray6',
}))`
  width: 362px;
`

export const ModalFooterWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)}px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
`

export const GoBackButton = styled(ButtonComp)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const ContinueButton = styled(ButtonComp)``
