import React from 'react'

import * as s from './styles'

interface EmptyStateProps {
  handleRedirect: () => void
}
const EmptyState = ({ handleRedirect }: EmptyStateProps) => (
  <s.ErrorContainer>
    <s.MessageText>
      Desculpe, não há oferta disponível no momento.
      <br />
      <br />
      Em caso de dúvida entre em contato com nossa Central de Atendimento pelos
      telefones:
      <br />
      11 3366 3377 Grande São Paulo ou
      <br />
      0800 727 9393 demais localidades.
    </s.MessageText>
    <s.ButtonsWrapper>
      <s.HomeButton onClick={handleRedirect}>início</s.HomeButton>
    </s.ButtonsWrapper>
  </s.ErrorContainer>
)

export default EmptyState
