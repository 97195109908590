import { Col, Container, Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import TextLiteral from 'components/Text'
import DivisorCmp from 'components/Divisor'
import TextArea from 'components/TextArea'
import Checkbox from 'components/Inputs/Checkbox'
import Button from 'components/Button'

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(11)}`}px;
  align-items: center;
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    flex-direction: row;
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) =>
      `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(9)}`}px;
  }
`
export const Column = styled(Col)`
  padding-bottom: 0;
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    &:first-child {
      padding: 0
        ${({ theme }) => `${theme.spacing(18)}px ${theme.spacing(3)}px`};
    }
  }
  @media ${({ theme }) => theme.mediaQueries.lg} {
    padding-bottom: 0;
  }
`
export const ColumnSide = styled(Col)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    position: sticky;
    top: ${({ theme }) => theme.spacing(3)}px;
  }
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    padding: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: ${({ theme }) => theme.spacing(0)}px;
  }
`

export const RowContent = styled(Row)``

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    max-width: ${({ theme }) => theme.spacing(36)}px;
  }
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    margin-right: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 0;
  }
`
export const SideTitle = styled(TextLiteral).attrs(() => ({
  type: 'heading4',
  color: 'gray6',
  bold: true,
}))`
  padding-left: 0;
`
export const TitleText = styled(TextLiteral).attrs(() => ({
  type: 'headline',
  color: 'gray6',
}))`
  padding-left: 0;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    padding-left: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`
export const Text = styled(TextLiteral).attrs(() => ({
  type: 'body',
  color: 'gray5',
  bold: true,
}))`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-left: 0;
  @media ${({ theme }) => theme.mediaQueries.lg} {
    padding-right: ${({ theme }) => theme.spacing(1)}px;
    padding-top: 0;
  }
`
export const TextDeclaration = styled(TextLiteral).attrs(() => ({
  type: 'body',
  color: 'gray5',
  bold: true,
}))`
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding-left: 0;
  @media ${({ theme }) => theme.mediaQueries.lg} {
    padding-right: ${({ theme }) => theme.spacing(1)}px;
    padding-bottom: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`
export const IndexText = styled(TextLiteral).attrs(() => ({
  type: 'body',
  color: 'gray5',
}))`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-left: 0;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  @media ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 0;
  }
`

export const TextSide = styled(TextLiteral).attrs(() => ({
  type: 'headline',
  color: 'gray6',
}))`
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-left: 0;
`
export const Title = styled(TextLiteral)`
  color: ${({ theme }) => theme.colors.gray6};
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding-left: 0;
`
export const Div = styled.div`
  display: flex;
  padding: 0;
  color: ${({ theme }) => theme.colors.gray6};
  padding-right: ${({ theme }) => theme.spacing(24)}px;
  padding-left: ${({ theme }) => theme.spacing(5)}px;
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    padding-right: ${({ theme }) => theme.spacing(13)}px;
    padding-left: ${({ theme }) => theme.spacing(3)}px;
  }
  @media ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 ${({ theme }) => theme.spacing(15)}px
      ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(3)}px;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 0 0 ${({ theme }) => theme.spacing(3)}px;
  }
`
export const DivOrderer = styled.div`
  display: flex;
  padding: 0;
  color: ${({ theme }) => theme.colors.gray6};
  padding-right: ${({ theme }) => theme.spacing(25)}px;
  padding-left: ${({ theme }) => theme.spacing(5)}px;
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    padding-right: ${({ theme }) => theme.spacing(14)}px;
    padding-left: ${({ theme }) => theme.spacing(3)}px;
  }
  @media ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 ${({ theme }) => theme.spacing(15)}px 0
      ${({ theme }) => theme.spacing(3)}px;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 0 0 ${({ theme }) => theme.spacing(3)}px;
  }
`
export const DivWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const DivLastComp = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: ${({ theme }) => theme.spacing(25)}px;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  color: ${({ theme }) => theme.colors.gray6};
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    padding-right: ${({ theme }) => theme.spacing(14)}px;
    padding-left: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding-right: 0;
    padding-left: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
  }
`
export const DivWithoutSpace = styled.div`
  display: flex;
  padding-right: ${({ theme }) => theme.spacing(25)}px;
  padding-left: 0;
  color: ${({ theme }) => theme.colors.gray6};
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    padding-right: ${({ theme }) => theme.spacing(25)}px;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding-right: 0;
    padding-left: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const OrderedList = styled.ol`
  color: ${({ theme }) => theme.colors.gray6};
  padding: 0 ${({ theme }) => theme.spacing(2)}px;
  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 0 0 ${({ theme }) => theme.spacing(2)}px;
  }
`
export const ItemsList = styled.li.attrs(() => ({
  type: 'body',
  bold: true,
}))`
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray5};
  font-size: ${props => props.theme.fontTypes.body};
  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${({ theme }) => theme.spacing(1)}px
      ${({ theme }) => theme.spacing(1)}px;
  }
  &::marker {
    color: ${({ theme }) => theme.colors.gray5};
  }
`
export const Divider = styled(DivisorCmp)`
  margin: ${({ theme }) => theme.spacing(4)}px 0;
`

export const DivTextArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-right: ${({ theme }) => theme.spacing(25)}px;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  color: ${({ theme }) => theme.colors.gray6};
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    padding-right: ${({ theme }) => theme.spacing(14)}px;
    padding-left: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding-right: 0;
    padding-left: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 ${({ theme }) => theme.spacing(2)}px;
  }
`
export const CompTextArea = styled(TextArea)`
  width: 100%;
`

export const CompCheckBox = styled(Checkbox)``

export const CheckboxContainer = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    padding-right: ${({ theme }) => theme.spacing(25)}px;
    padding-left: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding-left: 0;
  }
`

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue4};
  text-decoration: underline;
`
export const ContentWrapperFooter = styled(Container)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.grid.breakpoints.xl}px;
  @media ${props => props.theme.mediaQueries.lgUp} {
    padding-right: ${({ theme }) => theme.spacing(19)}px;
  }
  @media ${props => props.theme.mediaQueries.lg} {
    padding-right: ${({ theme }) => theme.spacing(4)}px;
  }
  @media ${props => props.theme.mediaQueries.sm} {
    height: 100%;
    padding: 0 ${({ theme }) => theme.spacing(3)}px;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: ${({ theme }) => theme.spacing(2)}px;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
`

export const NextButton = styled(Button)`
  width: 100%;
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 133px;
  }
`
