import React from 'react'

import Modal from 'components/Modal/ModalNew'

import T from './texts'
import * as S from './styles'

interface ModalExitFlowProps {
  isOpen: boolean
  onHandleClose: () => void
  onHandleSubmit: () => void
}
const ModalExitFlow = ({ isOpen, onHandleClose, onHandleSubmit }: ModalExitFlowProps) => {

  return (
    <Modal
      modalTitle={T.title}
      isOpen={isOpen}
      handleClose={onHandleClose}
    >
      <S.ContentText>{T.content}</S.ContentText>
      <S.ButtonWrapper>
        <S.Button
          data-gtm-type="click"
          data-gtm-clicktype="button"
          data-gtm-name="sair"
          outline
          onClick={onHandleSubmit}
        >
          {T.button.exit}
        </S.Button>
        <S.Button
          data-gtm-type="click"
          data-gtm-clicktype="button"
          data-gtm-name="continuar solicitação"
          onClick={onHandleClose}
        >
          {T.button.continue}
        </S.Button>
      </S.ButtonWrapper>
    </Modal>
  )
}

export default ModalExitFlow