import React from 'react'

import * as s from './styles'

interface EmptyStateProps {
  handleSimulateAgain: () => void
  handleRedirect: () => void
}
const EmptyState = ({ handleRedirect, handleSimulateAgain }: EmptyStateProps) => (
  <s.ErrorContainer>
    <s.MessageText>
      Desculpe, não foi possível encontrar grupos com valores próximos ao selecionado.
    </s.MessageText>
    <s.ButtonsWrapper>
      <s.HomeButton onClick={handleRedirect}>início</s.HomeButton>
      <s.SimulateAgainButton onClick={handleSimulateAgain}>simular novamente</s.SimulateAgainButton>
    </s.ButtonsWrapper>
  </s.ErrorContainer>
)

export default EmptyState
