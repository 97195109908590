import React, { useCallback, useState } from 'react'

import Loading from 'components/Loading'
import Icon from 'components/IconExporter'
import Text from 'components/Text'

import * as s from './styles'
import * as t from './types'

const offset = (el: HTMLElement | null) => {
  if (el) {
    const rect = el.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return rect.top + scrollTop
  }
  return 0
}

export default function Select({
  selected,
  options,
  label,
  testId,
  onClick,
  disabled = false,
  isLoading = false,
  isOpen = false,
  optionBoxSize = 400,
  optionBoxDirection = 'auto',
  emptyText = 'Não há opções disponíveis',
  hasLockIcon = false,
  suffix = undefined,
  error,
  autoComplete = true,
  newSelect = false,
  handlerSuffix,
  ...rest
}: t.IProps) {
  const [isOptionsOpen, setOptionsOpen] = useState<boolean>(isOpen)
  const [filterText, setFilterText] = useState<string | undefined>(
    selected.name
  )

  React.useEffect(() => {
    setOptionsOpen(isOpen)
  }, [isOpen])

  const renderOptions = useCallback(() => {
    if (!options.length) {
      return (
        <s.OptionsItem data-testid="option-empty" key={Math.random()}>
          <Text type="headline" color="gray6" bold>
            {emptyText}
          </Text>
        </s.OptionsItem>
      )
    }

    return options.map(option => {
      const optionText = option && option.text.toLocaleLowerCase()
      const searchText = (filterText || '').toLocaleLowerCase()

      if (autoComplete) {
        if (
          optionText.indexOf(searchText) === -1 &&
          selected.name !== filterText
        ) {
          return null
        }
      }

      return (
        <s.OptionsItem
          selected={option.id === selected.id}
          onClick={() => {
            if (option.onClick) {
              option.onClick()
            }
            setFilterText(option.text)
            setOptionsOpen(false)
          }}
          data-testid={`option-${option.text.replace(/\s/gs, '-')}`}
          key={Math.random()}
        >
          {option.icon && (
            <Icon name={option.icon} color="gray6" width={21} height={21} />
          )}
          <Text type="headline" color="gray6" bold>
            {option.text}
          </Text>
        </s.OptionsItem>
      )
    })
  }, [options, selected, emptyText, filterText, autoComplete])

  const suffixRender = () => {
    if (isLoading) {
      return <Loading type="spinner" color="blue4" />
    }

    if (hasLockIcon) {
      return (
        <Icon
          name="lock"
          color={disabled ? 'gray4' : 'gray6'}
          width={21}
          height={21}
        />
      )
    }

    if (suffix) {
      return suffix
    }

    return (
      <Icon
        name={isOptionsOpen ? 'arrowup' : 'arrowdown'}
        color={disabled ? 'gray4' : 'gray6'}
        width={21}
        height={21}
      />
    )
  }

  const filterOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value)
  }

  const autoOptionBoxDirection = (): 'top' | 'bottom' => {
    if (optionBoxDirection && optionBoxDirection !== 'auto') {
      return optionBoxDirection
    }

    const el = document.getElementById(label)

    return offset(el) > 340 ? 'top' : 'bottom'
  }

  return (
    <s.Container id={label} {...rest}>
      <s.InputContainer
        onClick={() => {
          if (!isLoading && !disabled && !error) {
            setOptionsOpen(currentState => !currentState)
            onClick && onClick(label || '')
          }
          if (suffix && handlerSuffix) {
            handlerSuffix()
            onClick && onClick(label || '')
          }
        }}
        data-testid={`select-${label}`}
        autoComplete={
          autoComplete && options?.length > 20 ? 'relative' : 'absolute'
        }
        isOptionsOpen={isOptionsOpen}
      >
        {
          // TODO, Refatorar para tirar o name como value do input
        }
        {newSelect ? (
          <s.Input
            label={label}
            placeholder="Selecione"
            value={isOptionsOpen ? filterText : selected.value}
            type="text"
            suffix={suffixRender()}
            error={error}
            onChange={filterOptions}
            data-testid={`select-option-input-${testId}`}
          />
        ) : (
          <s.Input
            label={label}
            placeholder="Selecione"
            value={isOptionsOpen ? filterText : selected.name}
            type="text"
            suffix={suffixRender()}
            error={error}
            onChange={filterOptions}
            data-testid={`select-option-input-${testId}`}
          />
        )}
      </s.InputContainer>

      {!suffix && isOptionsOpen && (
        <>
          <s.OptionsWrapper
            optionBoxDirection={autoOptionBoxDirection()}
            optionBoxSize={optionBoxSize}
            optionPadding={label === '' ? 8 : 11}
          >
            {renderOptions()}
          </s.OptionsWrapper>
          <s.Overlay
            data-testid="select-overlay"
            onClick={() => {
              setOptionsOpen(false)
            }}
          />
        </>
      )}
    </s.Container>
  )
}
