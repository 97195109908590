import React from 'react'
import { useSelector } from 'react-redux'

import { maskRG } from 'helpers'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { ApplicationState } from 'store/ApplicationState'

import DocumentsDrawer from '../../drawers/DocumentsDrawer'

import { formatOptionText } from '../../drawers/DocumentsDrawer/utils'

import { CardWithFormType } from '../../types'
import * as s from '../../styles'

const DocumentData: React.FC<CardWithFormType> = ({
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
}) => {
  const { hire } = TextsConsortium()
  const [isDocumentsDrawerOpen, setIsDocumentsDrawerOpen] = React.useState<
    boolean
  >(false)
  const containerFourth = hire.steps.summary.pro.containerFourth

  const { data } = useSelector(
    (state: ApplicationState) => state.consortiumPassportCountries
  )

  const fatcaData = {
    pais_passaporte: proposalData.proponente.fatca.pais_passaporte,
    pais_residencia_fiscal:
      proposalData.proponente.fatca.pais_residencia_fiscal,
    numero_identificacao_fiscal:
      proposalData.proponente.fatca.numero_identificacao_fiscal,
    numero_passaporte: proposalData.proponente.fatca.numero_passaporte,
    tipo_passaporte: proposalData.proponente.fatca.fatca,
    fatca: proposalData.proponente.fatca.fatca,
  }

  const documentData = {
    numero: proposalData.proponente.documento.numero,
    dt_expedicao: proposalData.proponente.documento.dt_expedicao,
    orgao_emissor: proposalData.proponente.documento.orgao_emissor,
  }

  const passportCountry = data
    ? data.find(item => item.codigo === fatcaData.pais_passaporte)
    : null

  const isForeignResidency =
    proposalData.proponente.fatca.possui_residencia_fiscal

  const documentNumber =
    proposalData.proponente.documento.codigo === 'RNE'
      ? documentData.numero
      : maskRG(documentData.numero)

  const expeditedBody =
    proposalData.proponente.documento.codigo === 'RNE'
      ? 'CGPI/DIREX/DPF'
      : documentData.orgao_emissor

  return (
    <>
      <DocumentsDrawer
        isOpen={isDocumentsDrawerOpen}
        proposalData={[proposalData, setProposalData]}
        handleClose={() => setIsDocumentsDrawerOpen(false)}
        isValidForm={[isValidForm, setIsValidForm]}
      />

      <s.cardStructure>
        <s.cardHeader>
          <s.cardHeaderTitle>{containerFourth.header.title}</s.cardHeaderTitle>
          <s.ButtonLink onClick={() => setIsDocumentsDrawerOpen(true)} noIcon>
            {containerFourth.header.button}
            {!isValidForm.documentForm && (
              <s.Notify name="ball" color="magenta2" />
            )}
            <s.Icon name="arrowright" color="blue4" />
          </s.ButtonLink>
        </s.cardHeader>
        {documentData ? (
          <>
            <s.cardContent>
              <s.DataRow
                label={containerFourth.content.doc}
                value={documentNumber}
                error={!documentData.numero}
              />
            </s.cardContent>
            <s.Divider />
            <s.cardContent>
              <s.DataRow
                label={containerFourth.content.dateDispatch}
                value={documentData.dt_expedicao}
                error={!documentData.dt_expedicao}
              />
            </s.cardContent>
            <s.Divider />
            <s.cardContent>
              <s.DataRow
                label={containerFourth.content.expeditedBody}
                value={expeditedBody}
                error={
                  !documentData.orgao_emissor &&
                  proposalData.proponente.documento.codigo !== 'RNE'
                }
              />
            </s.cardContent>
          </>
        ) : (
          <s.cardContent>
            <s.DataRow label={containerFourth.content.doc} value="" error />
          </s.cardContent>
        )}
        {isForeignResidency ? (
          <>
            <s.Divider />
            <s.cardContent>
              <s.DataRow
                label={containerFourth.content.foreignResidence}
                value={formatOptionText(fatcaData.pais_residencia_fiscal)}
                error={!fatcaData.pais_residencia_fiscal}
              />
            </s.cardContent>
            <s.Divider />
            <s.cardContent>
              <s.DataRow
                label={containerFourth.content.NIF}
                value={fatcaData.numero_identificacao_fiscal}
                error={!fatcaData.numero_identificacao_fiscal}
              />
            </s.cardContent>
            <s.Divider />
            <s.cardContent>
              <s.DataRow
                label={containerFourth.content.passportNumber}
                value={fatcaData.numero_passaporte}
                error={!fatcaData.numero_passaporte}
              />
            </s.cardContent>
            <s.Divider />
            <s.cardContent>
              <s.DataRow
                label={containerFourth.content.passportIssuingCountry}
                value={
                  passportCountry
                    ? formatOptionText(passportCountry.descricao)
                    : ''
                }
                error={!passportCountry}
              />
            </s.cardContent>
          </>
        ) : null}
      </s.cardStructure>
    </>
  )
}

export default DocumentData
