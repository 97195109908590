import { Types, ApplicationState, ApplicationAction } from './types'

const INITIAL_STATE: ApplicationState = {
  stack: [],
  form: {},
}

export default function(
  state = INITIAL_STATE,
  action: ApplicationAction
): ApplicationState {
  const newState = state.stack
  const { updatedKeys } = action

  switch (action.type) {
    case Types.FLUX_MANAGEMENT_ADD_TO_STACK:
      return {
        ...state,
        stack: [...state.stack, action.element],
      }

    case Types.FLUX_MANAGEMENT_POP_FROM_STACK:
      newState.pop()

      return {
        ...state,
        stack: newState,
      }

    case Types.FLUX_MANAGEMENT_UPDATE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...updatedKeys,
        },
      }
    case Types.FLUX_MANAGEMENT_RESET_FORM:
      return {
        ...state,
        form: {},
      }

    case Types.FLUX_MANAGEMENT_RESET_FLUX:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    default:
      return state
  }
}
