import { Types, IAction, ITagState } from './types'

const INITIAL_STATE: ITagState = {
  data: {
    customerInfo: null,
    tagData: null,
  },
  isLoading: false,
  hasError: false,
}

export default function(state = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case Types.GET_TAG_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_TAG_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          customerInfo: action.payload,
        },
        isLoading: false,
      }
    case Types.GET_TAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case Types.UPDATE_USER_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          tagData: action.payload,
        },
      }
    default:
      return state
  }
}
