const ExitFlowTexts = {
  title: 'Deseja sair da página?',
  content:
    'As informações serão perdidas e a sua solicitação não será enviada. ',
  button: {
    exit: 'SAIR',
    continue: 'Continuar solicitação',
  },
}

export default ExitFlowTexts
