import React, { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'
import 'styled-components/macro'

import * as s from './styles'

const ProposalSkeleton = () => {
  const themeContext = useContext(ThemeContext)
  return (
    <s.CardComponentSkeleton>
      <SkeletonTheme
        color={themeContext.colors.gray1}
        highlightColor={themeContext.colors.gray3}
      >
        <s.Container>
          <s.Row>
            <s.InfoWrapper>
              <Skeleton
                width={111}
                height={14}
                count={1}
                css={`
                  margin: ${themeContext.spacing(1)}px 0;
                `}
              />
              <Skeleton
                width={219}
                height={39}
                count={1}
                css={`
                  margin: ${themeContext.spacing(1)}px 0;
                `}
              />
              <Skeleton
                width={155}
                height={21}
                count={1}
                css={`
                  margin: ${themeContext.spacing(1)}px 0;
                `}
              />
            </s.InfoWrapper>
          </s.Row>
          <s.Row>
            <s.Col sm={6} xs={6}>
              <s.VerticalList
                elements={[
                  <Skeleton
                    width={118}
                    height={14}
                    count={1}
                    css={`
                      margin: ${themeContext.spacing(1)}px 0;
                    `}
                  />,
                  <Skeleton
                    width={70}
                    height={14}
                    count={1}
                    css={`
                      margin: ${themeContext.spacing(1)}px 0;
                    `}
                  />,
                ]}
              />
            </s.Col>
            <s.Col sm={6} xs={6}>
              <s.VerticalList
                elements={[
                  <Skeleton
                    width={118}
                    height={14}
                    count={1}
                    css={`
                      margin: ${themeContext.spacing(1)}px 0;
                    `}
                  />,
                  <Skeleton
                    width={70}
                    height={14}
                    count={1}
                    css={`
                      margin: ${themeContext.spacing(1)}px 0;
                    `}
                  />,
                ]}
              />
            </s.Col>
          </s.Row>
          <s.Row>
            <s.Col sm={6} xs={6}>
              <s.VerticalList
                elements={[
                  <Skeleton
                    width={118}
                    height={14}
                    count={1}
                    css={`
                      margin: ${themeContext.spacing(1)}px 0;
                    `}
                  />,
                  <Skeleton
                    width={70}
                    height={14}
                    count={1}
                    css={`
                      margin: ${themeContext.spacing(1)}px 0;
                    `}
                  />,
                ]}
              />
            </s.Col>
            <s.Col sm={6} xs={6}>
              <s.VerticalList
                elements={[
                  <Skeleton
                    width={118}
                    height={14}
                    count={1}
                    css={`
                      margin: ${themeContext.spacing(1)}px 0;
                    `}
                  />,
                  <Skeleton
                    width={70}
                    height={14}
                    count={1}
                    css={`
                      margin: ${themeContext.spacing(1)}px 0;
                    `}
                  />,
                ]}
              />
            </s.Col>
          </s.Row>

          <s.Row>
            <Skeleton
              width={379}
              height={35}
              count={1}
              css={`
                margin: ${themeContext.spacing(1)}px 0;
                margin-left: ${themeContext.spacing(2)}px;
              `}
            />
          </s.Row>
          <s.Row>
            <s.SummaryWrapper>
              <Skeleton
                width={111}
                height={14}
                count={1}
                css={`
                  margin: ${themeContext.spacing(1)}px 0;
                `}
              />
            </s.SummaryWrapper>
          </s.Row>
          <s.Row>
            <s.HorizontalList
              startItems={[
                <Skeleton
                  width={118}
                  height={14}
                  count={1}
                  css={`
                    margin: ${themeContext.spacing(1)}px 0;
                  `}
                />,
              ]}
              endItems={[
                <Skeleton
                  width={63}
                  height={14}
                  count={1}
                  css={`
                    margin: ${themeContext.spacing(1)}px 0;
                  `}
                />,
              ]}
            />
          </s.Row>
          <s.Row>
            <s.HorizontalList
              startItems={[
                <Skeleton
                  width={139}
                  height={20}
                  count={1}
                  css={`
                    margin: ${themeContext.spacing(1)}px 0;
                  `}
                />,
              ]}
              endItems={[
                <Skeleton
                  width={63}
                  height={14}
                  count={1}
                  css={`
                    margin: ${themeContext.spacing(1)}px 0;
                  `}
                />,
              ]}
            />
          </s.Row>
          <s.Row>
            <s.HorizontalList
              startItems={[
                <Skeleton
                  width={173}
                  height={14}
                  count={1}
                  css={`
                    margin: ${themeContext.spacing(1)}px 0;
                  `}
                />,
              ]}
              endItems={[
                <Skeleton
                  width={63}
                  height={14}
                  count={1}
                  css={`
                    margin: ${themeContext.spacing(1)}px 0;
                  `}
                />,
              ]}
            />
          </s.Row>
          <s.Row>
            <s.HorizontalList
              startItems={[
                <Skeleton
                  width={118}
                  height={14}
                  count={1}
                  css={`
                    margin: ${themeContext.spacing(1)}px 0;
                  `}
                />,
              ]}
              endItems={[
                <Skeleton
                  width={63}
                  height={14}
                  count={1}
                  css={`
                    margin: ${themeContext.spacing(1)}px 0;
                  `}
                />,
              ]}
            />
          </s.Row>
        </s.Container>
      </SkeletonTheme>
    </s.CardComponentSkeleton>
  )
}

export default ProposalSkeleton
