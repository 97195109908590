import styled, { css } from 'styled-components'

import SelectComponent from 'components/Inputs/AutoCompleteSelect'
import { Col, Row } from 'styled-bootstrap-grid'
import NewInput from 'components/NewInput'
import {
  InputGroupWrapper,
  ErrorMessage,
  TextError,
} from 'components/NewInput/styles'
import Text from 'components/Text'
import IconCmp from 'components/IconExporter'
import PopoverCmp from 'components/Popover'
import Radio from 'components/Inputs/Radio'

export const RowDocument = styled(Row)`
  display: flex;
  margin: 0;
  padding-left: ${({ theme }) => theme.spacing(2)}px;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const ColDocument = styled(Col)`
  padding: 0;

  &:last-of-type {
    flex: 1;
    max-width: 100%;
  }
`

export const WrapperDocument = styled.div`
  position: relative;
`

export const DelimiterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 234px;
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: 0;
  }
`

export const FormDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  max-width: 368px;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0;
  }
`

export const Select = styled(SelectComponent)`
  margin: ${({ theme }) => `${theme.spacing(2)}px 0 ${theme.spacing(4)}px`};
`

interface DocumentCardProps {
  errorLines: number
}

export const InputDocument = styled(NewInput)<DocumentCardProps>`
  margin: ${({ theme }) => `0 ${theme.spacing(2)}px ${theme.spacing(4)}px`};
  height: unset;
  min-height: ${({ theme }) => theme.spacing(10)}px;

  ${InputGroupWrapper} {
    height: ${({ theme }) => theme.spacing(10)}px;
  }

  ${ErrorMessage} {
    display: flex;
    align-items: flex-start;

    ${TextError} {
      height: fit-content;
    }
  }

  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    margin-top: 0;
  }

  span {
    ${({ theme }) => theme.fontTypes.body};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin: ${({ theme }) => `0 0 ${theme.spacing(4)}px`};

    &:first-child {
      margin-top: 0;
    }
  }
`

export const TextDocument = styled(Text)`
  position: absolute;
  top: 14px;
  right: -18px;
  ${({ theme }) => theme.fontTypes.caption};
  color: ${({ theme }) => theme.colors.gray5};
  width: 140px;
  height: 80px;
`

export const RadioDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`

export const LabelRadioDocument = styled(Text).attrs(() => ({
  type: 'headline',
  bold: true,
}))``

export const RadioOptionsDocumentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: 0;
  height: 30px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: initial;
    align-items: center;
    justify-content: flex-start;
  }

  & label {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const RadioDocument = styled(Radio)``

export const WrapperForeignResidenceForm = styled.div`
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    padding-right: ${({ theme }) => theme.spacing(4)}px;
    width: 630px;
  }
`

export const RowForeignResidenceDocument = styled(Row)`
  margin: ${({ theme }) => `${theme.spacing(2)}px 0 0 0`};

  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-right: ${({ theme }) => `${theme.spacing(3)}px`};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 0;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const ColForeignResidenceDocument = styled(Col)`
  padding: 0;
`

export const InputForeignResidenceDocument = styled(NewInput)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  ${({ error }) =>
    error &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(7)}px;
    `}

  span {
    ${({ theme }) => theme.fontTypes.body};
  }
`

export const SelectForeignResidenceDocument = styled(SelectComponent)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 0;
  }
`

export const SelectCountryIssuancePassportDocument = styled(SelectComponent)``

interface IPopover {
  noMargin?: boolean
}

export const Popover = styled(PopoverCmp)<IPopover>`
  align-items: center;
  display: flex;
`

export const TextContainer = styled.div`
  width: 213px;
`

export const TextDescription = styled(Text)``
export const Icon = styled(IconCmp)``
