import React from 'react'
import { fitToMask } from 'react-masked'
import { useSelector } from 'react-redux'

import { maskRG } from 'helpers'

import { TSelected, TOptions } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { ApplicationState } from 'store/ApplicationState'
import { IDocument } from 'store/modules/Consortium/Documents/types'

import {
  placeholderExpediteBody,
  validationErrorDocument,
  validationErrorDateDispatch,
  validationErrorExpeditedBody,
} from '../utils'

import { IFormDocumentData, DocumentFormType } from '../types'
import * as s from '../styles'

const DocumentForm: React.FC<DocumentFormType> = ({
  formData: [formData, setFormData],
  documentTypeSelect: [documentTypeSelect, setDocumentTypeSelect],
}) => {
  const {
    hire: {
      steps: {
        informationConfirmation: {
          containerThird: { label, placeholder, messageRNE },
        },
      },
    },
  } = TextsConsortium()

  /**
   * USESELECTOR
   */
  const { documentsConsortium } = useSelector((state: ApplicationState) => {
    if (state.consortiumDocuments.data) {
      return {
        documentsConsortium: state.consortiumDocuments.data,
      }
    }

    return {
      documentsConsortium: [],
    }
  })

  /**
   * USESTATE
   */
  const [fieldsTouched, setfieldsTouched] = React.useState<IFormDocumentData>({
    documentNumber: false,
    dateDispatch: false,
    expeditedBody: false,
  })

  const [documentTypeOption, setDocumentTypeOption] = React.useState<
    TOptions[]
  >([])

  /**
   * USECALLBACK
   */
  const updateDocumentForm = React.useCallback(
    (codigo: string) => {
      setfieldsTouched({
        documentNumber: false,
        expeditedBody: false,
        dateDispatch: false,
      })

      setFormData({
        ...formData,
        documentType: codigo,
        documentNumber: '',
        dateDispatch: '',
        expeditedBody: '',
      })
    },
    [formData, setFormData, setfieldsTouched]
  )

  const setListOptionDocumentType = React.useCallback(
    (
      data: IDocument[],
      setMethod: React.Dispatch<React.SetStateAction<TOptions[]>>,
      setSelect: React.Dispatch<React.SetStateAction<TSelected>>,
      updateDocumentForm: (documentType: string) => void
    ) => {
      setMethod(
        data.map((value: IDocument) => ({
          id: value.codigo,
          text: value.descricao,
          onClick: () => {
            setSelect({ id: value.codigo, name: value.descricao })
            updateDocumentForm(value.codigo)
          },
        }))
      )
    },
    []
  )

  /**
   * USEFFECT
   */
  React.useEffect(() => {
    if (documentsConsortium && documentsConsortium.length > 0) {
      setListOptionDocumentType(
        documentsConsortium,
        setDocumentTypeOption,
        setDocumentTypeSelect,
        updateDocumentForm
      )

      documentsConsortium.forEach((item: IDocument) => {
        if (item.codigo === formData.documentType) {
          setDocumentTypeSelect({
            id: item.codigo,
            name: item.descricao,
          })
        }
      })
    }
  }, [
    formData,
    documentsConsortium,
    setListOptionDocumentType,
    updateDocumentForm,
    setDocumentTypeSelect,
  ])

  const updateStateForm = React.useCallback(
    (key: string, value: string) => {
      setFormData({
        ...formData,
        [key]: value,
      })
    },
    [setFormData, formData]
  )

  const updatefieldTouched = React.useCallback(
    (key: string, value: boolean) => {
      setfieldsTouched({
        ...fieldsTouched,
        [key]: value,
      })
    },
    [fieldsTouched, setfieldsTouched]
  )

  const RNEPopover = () => (
    <s.Popover
      noMargin
      zIndex={14}
      placement="left"
      content={
        <s.TextContainer>
          <s.TextDescription color="gray5" bold>
            {messageRNE}
          </s.TextDescription>
        </s.TextContainer>
      }
    >
      <s.Icon name="information" color="blue4" width={21} />
    </s.Popover>
  )

  const formatExpediteBody = (TypedValue: string) => {
    const value = TypedValue.replace('SSP', '').replace('/', '')

    if (value !== '') {
      return 'SSP/' + value
    }

    return ''
  }

  return (
    <s.RowDocument>
      <s.ColDocument>
        <s.Select
          data-testid="select-document-type-id"
          label={label.documentType}
          options={documentTypeOption}
          selected={documentTypeSelect}
        />
        {documentTypeSelect.name ? (
          <>
            <s.InputDocument
              errorLines={2}
              label={label.documentNumber}
              data-testid="input-document-number"
              suffix={documentTypeSelect.name === 'RNE' ? RNEPopover() : null}
              placeholder={placeholder.documentNumber}
              value={formData.documentNumber}
              maxLength={12}
              onBlur={() =>
                updatefieldTouched(
                  'documentNumber',
                  validationErrorDocument(
                    formData.documentNumber,
                    fieldsTouched.documentNumber,
                    documentTypeSelect.name || ''
                  )?.message !== ''
                )
              }
              error={validationErrorDocument(
                formData.documentNumber,
                fieldsTouched.documentNumber,
                documentTypeSelect.name
              )}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateStateForm(
                  'documentNumber',
                  documentTypeSelect.id === 'RG'
                    ? maskRG(e.currentTarget.value)
                    : e.currentTarget.value.replace(/\D/g, '')
                )
              }}
            />
            <s.InputDocument
              errorLines={1}
              label={label.dateDispatch}
              value={fitToMask(formData.dateDispatch, '99/99/9999')}
              data-testid="input-date-dispatch"
              onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                updatefieldTouched('dateDispatch', e.currentTarget.value !== '')
              }
              placeholder={placeholder.dateDispatch}
              error={validationErrorDateDispatch(
                formData.dateDispatch,
                fieldsTouched.dateDispatch
              )}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateStateForm('dateDispatch', e.currentTarget.value)
              }}
            />
            <s.InputDocument
              errorLines={2}
              label={label.expeditedBody}
              data-testid="input-spouse-name-id"
              maxLength={documentTypeSelect.name === 'RNE' ? 14 : 6}
              onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                updatefieldTouched(
                  'expeditedBody',
                  e.currentTarget.value !== ''
                )
              }
              value={
                documentTypeSelect.name === 'RNE'
                  ? 'CGPI/DIREX/DPF'
                  : formatExpediteBody(formData.expeditedBody)
              }
              disabled={documentTypeSelect.name === 'RNE'}
              placeholder={placeholderExpediteBody(
                documentTypeSelect,
                placeholder
              )}
              error={validationErrorExpeditedBody(
                formData.expeditedBody,
                fieldsTouched.expeditedBody
              )}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateStateForm(
                  'expeditedBody',
                  e.currentTarget.value.toUpperCase()
                )
              }}
            />
          </>
        ) : null}
      </s.ColDocument>
    </s.RowDocument>
  )
}

export default DocumentForm
