import React, { memo } from 'react'
import { HeaderProps } from './types'
import * as S from './styles'

const PEPHeader: React.FC<HeaderProps> = ({ title, onClose }: HeaderProps) => (
  <S.Container data-testid="PEP-drawer-header">
    <S.Title>{title}</S.Title>
    <S.ContainerCloseButton>
      <S.CloseButton
        name="close"
        onClick={onClose}
        data-testid="close-button"
      />
    </S.ContainerCloseButton>
  </S.Container>
)

export default memo(PEPHeader)
