import React from 'react'

import { Divisor } from 'components'
import CardStructure from 'pages/Consortium/SolicitationProcess/Components/CardStructure'
import { TextsConsortium } from 'pages/Consortium/commons'
import { IPaymentDataForm } from '../MonthlyInstallments/types'

import * as c from 'pages/Consortium/SolicitationProcess/PRO/PartnerOffer/ContractData/MonthlyInstallments/cards'
import * as s from './styles'
import { CardDataType } from '../../TypesPartnerOffer/Form'

const PaymentOptionsCard: React.FC<CardDataType> = ({
  proposalData: [formData, setFormData],
}) => {
  const texts = TextsConsortium()

  const [paymentMethod, setPaymentMethod] = React.useState<string>('')
  const [paymentData, setPaymentData] = React.useState<IPaymentDataForm>({
    code: '',
    bank: '',
    agency: '',
    currentAccount: '',
    digit: '',
  })

  const updateForm = React.useCallback(
    (key: string, value: string) => {
      setFormData({
        ...formData,
        dados_bancarios: {
          ...formData.dados_bancarios,
          [key]: value,
        },
      })
    },
    [formData, setFormData]
  )

  return (
    <CardStructure>
      <s.Wrapper>
        <s.WrapperForm>
          <s.Title>{texts.pro.paymentOption}</s.Title>
          <s.TitleCardForm>
            {texts.hire.steps.payment.containerFirst.title}
          </s.TitleCardForm>
          <c.InstallmentDataCard
            label={texts.hire.steps.payment.containerFirst.label}
          />
        </s.WrapperForm>
        <Divisor />
        <s.WrapperForm>
          <s.TitleCardForm>
            {texts.hire.steps.payment.containerSecond.title}
          </s.TitleCardForm>
          <c.FirstInstallmentPayment
            paymentMethod={[paymentMethod, setPaymentMethod]}
            updateForm={updateForm}
            content={texts.hire.steps.payment.containerSecond}
          />
        </s.WrapperForm>
        <Divisor />
        <s.WrapperForm>
          <s.TitleCardForm>
            {texts.hire.steps.payment.containerThird.title}
          </s.TitleCardForm>
          <c.PaymentData
            paymentData={[paymentData, setPaymentData]}
            updateForm={updateForm}
            content={texts.hire.steps.payment.containerThird}
          />
        </s.WrapperForm>
      </s.Wrapper>
    </CardStructure>
  )
}

export default PaymentOptionsCard
