import React from 'react'
import { useSelector } from 'react-redux'

import { formatMoney, capitalize } from 'helpers'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { IJobs } from 'store/modules/Consortium/Jobs/types'
import { ApplicationState } from 'store/ApplicationState'

import { CardWithFormType } from '../../types'
import FinancialDataDrawer from '../../drawers/FinancialDataDrawer'

import * as s from '../../styles'

const FinancialCard: React.FC<CardWithFormType> = ({
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
}) => {
  const { hire } = TextsConsortium()

  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [job, setJob] = React.useState<IJobs>({ descricao: '', codigo: '' })

  const { data } = useSelector(
    (state: ApplicationState) => state.consortiumJobs
  )

  React.useEffect(() => {
    if (data) {
      data.forEach(item => {
        if (
          item.codigo === proposalData.proponente.dados_profissionais.codigo
        ) {
          setJob(item)
        }
      })
    }
  }, [data, proposalData, setJob])

  const monthlyIncome = formatMoney(
    proposalData.proponente.dados_profissionais.valor_renda_mensal || 0
  )

  return (
    <>
      <FinancialDataDrawer
        isOpen={isOpen}
        proposalData={[proposalData, setProposalData]}
        handleClose={() => setIsOpen(false)}
        isValidForm={[isValidForm, setIsValidForm]}
      />
      <s.cardStructure>
        <s.cardHeader>
          <s.cardHeaderTitle>
            {hire.steps.summary.pro.containerSixth.header.title}
          </s.cardHeaderTitle>
          <s.ButtonLink noIcon onClick={() => setIsOpen(true)}>
            {hire.steps.summary.pro.containerSixth.header.button}
            {!isValidForm.financialForm && (
              <s.Notify name="ball" color="magenta2" />
            )}
            <s.Icon name="arrowright" color="blue4" />
          </s.ButtonLink>
        </s.cardHeader>
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerSixth.content.occupation}
            value={job.descricao
              .split(' ')
              .map(character =>
                character.length > 2
                  ? capitalize(character)
                  : character.toLowerCase()
              )
              .join(' ')}
            error={!job}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerSixth.content.monthlyIncome}
            value={monthlyIncome}
            error={
              !proposalData.proponente.dados_profissionais.valor_renda_mensal
            }
          />
        </s.cardContent>
      </s.cardStructure>
    </>
  )
}

export default FinancialCard
