import styled, { css } from 'styled-components'
import {
  Container as ContainerCmp,
  Col as ColComp,
} from 'styled-bootstrap-grid'
import TextCmp from 'components/Text'
import DivisorCmp from 'components/Divisor'
import ButtonCmp from 'components/Button'

export const Content = styled.div<{ autoHeight: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 733px;
  height: ${({ autoHeight }) => (autoHeight ? 'auto' : '100%')};
  justify-content: flex-start;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    height: auto;
  }
`

export const Footer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  z-index: 1;
  width: 100%;
  bottom: 0;
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: ${({ theme }) => theme.spacing(2)}px;

  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    justify-content: flex-start;

    button {
      width: 100%;

      &:first-of-type {
        margin-bottom: ${({ theme }) => theme.spacing(2)}px;
      }
    }
  }
`

export const ContextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 56px;

  @media ${({ theme }) => theme.mediaQueries.md} {
    align-items: center;
  }
`

export const SimulationText = styled(TextCmp)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

export const Text = styled(TextCmp)`
  margin: 0;
`

export const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const TitleWrapper = styled.div`
  margin: 21px 7px;
`

export const HeaderWrapper = styled.div`
  margin: 14px 0;
  @media ${({ theme }) => theme.mediaQueries.xs} {
    padding: 0 7px;
  }
`

export const Title = styled(TextCmp).attrs(() => ({
  color: 'white',
}))`
  @media ${({ theme }) => theme.mediaQueries.xs} {
    padding: 0 7px;
  }
`

export const BackgroundWrapper = styled.div`
  position: relative;
  background-color: #e5e5e5;
  display: flex;
  height: inherit;
  flex-direction: column;
  align-items: center;
`

export const DarkBackground = styled.div`
  position: absolute;
  top: 0;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.grayblue1};
  height: 278px;
  width: 100%;
  display: flex;
`

export const SmallDarkBackground = styled.div`
  position: absolute;
  top: 0;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.grayblue1};
  height: 56px;
  width: 100%;
  display: flex;
`

export const ContentContainer = styled(ContainerCmp)`
  height: calc(100vh - 135px);
`

export const ContentSchemeContainer = styled(ContainerCmp)`
  z-index: 1;
`

export const Divider = styled(DivisorCmp)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5};
  margin: auto;
  margin-bottom: 7px;
`

const fullWidthStyle = css`
  width: 100%;
`

const tabletStyle = css`
  width: 850px;
  margin: 0 auto;
`
export const ContentWrapper = styled.div<{ fullWidth: boolean }>`
  ${({ fullWidth }) => (fullWidth ? fullWidthStyle : tabletStyle)};
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    padding-left: 7px;
  }
`

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue4};
`

export const Line = styled.hr`
  position: relative;
  bottom: 0;
  margin-bottom: 25px;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
`

export const CmpButton = styled(ButtonCmp)`
  &:first-of-type {
    margin-right: ${({ theme }) => `${theme.spacing(2)}px`};

    @media ${({ theme }) => theme.mediaQueries.md} {
      &&& {
        margin: 0;
      }
    }
  }
`

export const TryAgainButton = styled(ButtonCmp)`
  width: 192px;
  margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
`

export const ErrorState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 136px);
  width: 434px;
  align-self: center;
  text-align: center;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: 318px;
  }
`

export const Button = styled(ButtonCmp)`
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: column;
    width: 100%;
  }
`

export const ExitButton = styled(ButtonCmp)`
  width: 98px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: 100%;
    margin: 0 0 ${({ theme }) => theme.spacing(2)}px 0;
  }
`

export const SimulateAgainButton = styled(ButtonCmp)`
  width: 205px;

  @media ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
  }
`

export const PlanCardWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const PlanCardsFlexCol = styled(ColComp).attrs(() => ({
  noGutter: true,
  lg: 12,
  lgOffset: 0,
  md: 10,
  mdOffset: 1,
  sm: 12,
  xs: 12,
}))`
  display: flex;
  flex-wrap: wrap;
`
