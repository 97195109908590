import { IClientInformationConsortium } from 'store/modules/Consortium/ClientInformation/types'

export const Types = {
  POST_CLIENT_INFORMATION_REQUEST: 'POST_CLIENT_INFORMATION_REQUEST',
  POST_CLIENT_INFORMATION_SUCCESS: 'POST_CLIENT_INFORMATION_SUCCESS',
  POST_CLIENT_INFORMATION_FAILURE: 'POST_CLIENT_INFORMATION_FAILURE',
  POST_CLIENT_INFORMATION_INVALID: 'POST_CLIENT_INFORMATION_INVALID',
}

export interface IAction {
  type: string
  payload: IClientInformationConsortium
}

export interface IPayload {
  type: string
  payload: string
}

export interface IClientInformationPRORequest {
  cpf: string
}

export interface IClientInformationProConsortiumState {
  data: IClientInformationConsortium | null
  isLoading: boolean
  hasError: boolean
  isInvalid: boolean
}
