import styled from 'styled-components'
import Text from 'components/Text'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import Button from 'components/Button'

export const MonthlyInstallmentsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(11)}`}px;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    flex-direction: row;
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${({ theme }) =>
      `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(11)}px`};
  }
`

export const Column = styled(Col)`
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    position: sticky;
    top: ${({ theme }) => theme.spacing(2)}px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(4)}px;
  margin-left: ${({ theme }) => theme.spacing(3)}px;

  & > * {
    &:first-child {
      padding-bottom: ${({ theme }) => theme.spacing(3)}px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    padding-top: ${({ theme }) => theme.spacing(2)}px;
    margin-left: 0;

    & > * {
      &:first-child {
        padding-bottom: ${({ theme }) => theme.spacing(3)}px;
      }

      &:last-child {
        padding-bottom: ${({ theme }) => theme.spacing(4)}px;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    padding-top: ${({ theme }) => theme.spacing(3)}px;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;

    & > * {
      &:first-child {
        padding-bottom: ${({ theme }) => theme.spacing(2)}px;
      }

      &:nth-child(2) {
        padding-bottom: ${({ theme }) => theme.spacing(2)}px;
      }

      &:last-child {
        padding-bottom: ${({ theme }) => theme.spacing(1)}px;
      }
    }
  }
`

export const TitleText = styled(Text)`
  padding: ${({ theme }) =>
    `0 ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`};

  @media ${({ theme }) => theme.mediaQueries.md} {
    padding-left: 0;
  }
`

export const FooterContent = styled(Container)``

export const RowContent = styled(Row)``

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing(2)}px;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: 100vw;
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
`

export const ButtonAlign = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.spacing(3)}px;

  @media ${({ theme }) => theme.mediaQueries.lg} {
    padding-right: ${({ theme }) => theme.spacing(2)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: 0;
  }
`

export const NextButton = styled(Button)`
  width: 100%;
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 133px;
  }
`
