import React from 'react'

import { formatMoney } from 'helpers'

import StepBar from 'components/StepBar'

import useMedia from 'hooks/useMedia'
import { StepComponentProps } from 'hooks/FluxManagement/types'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'

import { ITexts } from 'pages/Consortium/commons/Texts/types'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import CardStructure from 'pages/Consortium/SolicitationProcess/Components/CardStructure'

import { IFormConsortium, Steps } from '../../Types/index'

import { IPaymentDataForm } from './types'
import * as c from './cards'
import * as s from './styles'

const StepTwo: React.FC = () => {
  const isSmUp = useMedia('smUp')
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()

  const [texts, setTexts] = React.useState<ITexts>(TextsConsortium())

  const [paymentMethod, setPaymentMethod] = React.useState<string>(
    form.proposal.forma_pagamento_adesao
  )

  const [paymentData, setPaymentData] = React.useState<IPaymentDataForm>({
    code: form.proposal.proponente.dados_bancarios.codigo_banco,
    bank: form.proposal.proponente.dados_bancarios.nome_banco,
    agency: form.proposal.proponente.dados_bancarios.numero_agencia,
    currentAccount: form.proposal.proponente.dados_bancarios.numero_conta,
    digit: form.proposal.proponente.dados_bancarios.digito_conta,
    agencyDigit: form.proposal.proponente.dados_bancarios.digito_agencia,
  })

  React.useLayoutEffect(() => {
    const layerChildrenElement = document.querySelector('#layer-children')
    if (layerChildrenElement) {
      layerChildrenElement.scrollTo({
        top: 0,
      })
    }
  }, [])

  React.useEffect(() => {
    if (form && form.proposal.valor_primeira_parcela) {
      setTexts(
        TextsConsortium(
          '',
          `${formatMoney(form.proposal.valor_primeira_parcela)}*`
        )
      )
    }
  }, [form])

  const handlerSubmit = () => {
    updateForm({
      ...form,
      proposal: {
        ...form.proposal,
        forma_pagamento_adesao: paymentMethod,
        forma_pagamento_adesao_boleto: paymentMethod === 'BOLETO_BANCARIO',
        proponente: {
          ...form.proposal.proponente,
          dados_bancarios: {
            codigo_banco: paymentData.code,
            nome_banco: paymentData.bank,
            numero_agencia: paymentData.agency,
            numero_conta: paymentData.currentAccount,
            digito_conta: paymentData.digit,
            digito_agencia: paymentData.agencyDigit,
          },
        }
      },
    })

    instantiate(Steps.HIRE_STEP_THREE)
  }

  const isDisabled: boolean =
    !!paymentData &&
    !!paymentMethod &&
    !!paymentData.code &&
    !!paymentData.bank &&
    !!paymentData.agency &&
    !!paymentData.currentAccount &&
    !!paymentData.digit

  return (
    <>
      <StepBar
        title={texts.hire.title}
        type={texts.page}
        actualStep={texts.hire.steps.payment.actualStep}
        totalNumberOfSteps={texts.hire.stepMax}
      />
      <s.MonthlyInstallmentsContainer>
        <s.Column lg={3}>
          <s.TitleWrapper>
            <s.TitleText type="heading4" bold>
              {texts.hire.steps.payment.title}
            </s.TitleText>
            <s.TitleText color="gray6" type={isSmUp ? 'headline' : 'body'}>
              {texts.hire.steps.payment.subtitleFirst}
            </s.TitleText>
            <s.TitleText bold color="gray6" type={isSmUp ? 'headline' : 'body'}>
              {texts.hire.steps.payment.subtitleSecond}
            </s.TitleText>
          </s.TitleWrapper>
        </s.Column>
        <s.Column lg={8} md={10}>
          <CardStructure title={texts.hire.steps.payment.containerFirst.title}>
            <c.InstallmentDataCard
              label={texts.hire.steps.payment.containerFirst.label}
            />
          </CardStructure>
          <CardStructure title={texts.hire.steps.payment.containerSecond.title}>
            <c.FirstInstallmentPayment
              paymentMethod={[paymentMethod, setPaymentMethod]}
              content={texts.hire.steps.payment.containerSecond}
            />
          </CardStructure>
          <CardStructure title={texts.hire.steps.payment.containerThird.title}>
            <c.PaymentData
              paymentData={[paymentData, setPaymentData]}
              content={texts.hire.steps.payment.containerThird}
            />
          </CardStructure>
        </s.Column>
      </s.MonthlyInstallmentsContainer>
      <s.FooterContainer>
        <s.FooterContent>
          <s.RowContent>
            <s.Column lg={3} />
            <s.Column lg={8} md={11}>
              <s.ButtonAlign>
                <s.NextButton
                  data-testid="button-next-id"
                  color="secondary"
                  onClick={handlerSubmit}
                  disabled={!isDisabled}
                >
                  {texts.buttons.next}
                </s.NextButton>
              </s.ButtonAlign>
            </s.Column>
          </s.RowContent>
        </s.FooterContent>
      </s.FooterContainer>
    </>
  )
}

export default StepTwo
