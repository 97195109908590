import React from 'react'
import moment from 'moment'
import Divisor from 'components/Divisor'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import { formatMoney } from 'helpers'
import { IInstallmentDataCard } from '../types'
import { IFormConsortium } from '../../../TypesPartnerOffer'

import * as s from './styles'

const InstallmentDataCard: React.FC<IInstallmentDataCard> = ({
  label,
}: IInstallmentDataCard) => {
  const { form } = useFormFluxManagement<IFormConsortium>()

  return (
    <s.WrapperInstallmentCard>
      <s.DataDisplay
        label={label.monthlyInstallment}
        value={formatMoney(form.proposal.valor_demais_parcelas || 0)}
      />
      <Divisor />
      <s.DataDisplay
        label={label.numberInstallment}
        value={form.proposal.prazo || 0}
      />
      <Divisor />
      <s.DataDisplay
        label={label.dateForeseenFirstInstallment}
        value={form.proposal.valor_primeira_parcela || 0}
      />
      <Divisor />
      <s.DataDisplay
        label={label.dateOtherPlots}
        value={moment(form.proposal.dt_venc_assembl).format('DD/MM/YYYY')}
      />
    </s.WrapperInstallmentCard>
  )
}

export default InstallmentDataCard
