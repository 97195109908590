import React from 'react'

import PaymentDrawer from '../../drawers/PaymentDrawer'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { CardWithFormType } from '../../types'
import * as s from '../../styles'

const PaymentData: React.FC<CardWithFormType> = ({
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
}) => {
  const { hire } = TextsConsortium()

  const [isPaymentDrawer, setIsPaymentDrawer] = React.useState<boolean>(false)

  const bank: string = `${proposalData.proponente.dados_bancarios.codigo_banco} - ${proposalData.proponente.dados_bancarios.nome_banco}`
  const account: string = `${proposalData.proponente.dados_bancarios.numero_conta}-${proposalData.proponente.dados_bancarios.digito_conta}`

  const paymentMethod: string =
    proposalData.forma_pagamento_adesao === 'BOLETO_BANCARIO'
      ? 'Boleto'
      : 'Débito automático'

  return (
    <>
      <PaymentDrawer
        isOpen={isPaymentDrawer}
        handleClose={() => setIsPaymentDrawer(false)}
        proposalData={[proposalData, setProposalData]}
        isValidForm={[isValidForm, setIsValidForm]}
      />
      <s.cardStructure>
        <s.cardHeader>
          <s.cardHeaderTitle>
            {hire.steps.summary.pro.containerNinth.header.title}
          </s.cardHeaderTitle>
          <s.ButtonLink onClick={() => setIsPaymentDrawer(true)} noIcon>
            {hire.steps.summary.pro.containerNinth.header.button}
            {!isValidForm.paymentForm && (
              <s.Notify name="ball" color="magenta2" />
            )}
            <s.Icon name="arrowright" color="blue4" />
          </s.ButtonLink>
        </s.cardHeader>
        <s.cardContent>
          <s.DataRow
            label={
              hire.steps.summary.pro.containerNinth.content
                .firstInstallmentePayment
            }
            value={paymentMethod}
            error={!proposalData.forma_pagamento_adesao}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={
              hire.steps.summary.pro.containerNinth.content
                .othersInstallmentePayment
            }
            value="Débito automático em conta corrente"
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerNinth.content.bank}
            value={bank}
            error={!proposalData.proponente.dados_bancarios.codigo_banco}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerNinth.content.agency}
            value={proposalData.proponente.dados_bancarios.numero_agencia}
            error={!proposalData.proponente.dados_bancarios.numero_agencia}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerNinth.content.account}
            value={account}
            error={!proposalData.proponente.dados_bancarios.numero_conta}
          />
        </s.cardContent>
      </s.cardStructure>
    </>
  )
}

export default PaymentData
