import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import Icon from 'components/IconExporter'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'
import { StepComponentProps } from 'hooks/FluxManagement/types'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'

import { ClearQuotes } from 'store/modules/Consortium/QuoteReservation/action'
import { ProposalSimulationsClearData } from 'store/modules/Consortium/ProposalSimulations/action'

import { ModalExitFlow } from './Components'
import { Steps, IFormConsortium } from './Types'

import * as s from './styles'

// Steps:
// 1 - ChooseState
// 2 - ContemplatedValue
// 3 - ChoosePlan
// 4 - InformationConfirmation
// 5 - MonthlyInstallments
// 6 - Declarations
// 7 - Summary

interface SolicitationProcessProps {
  isOpen: boolean
  onCloseHandler: () => void
}
const SolicitationProcess = ({
  isOpen,
  onCloseHandler,
}: SolicitationProcessProps) => {
  const { resetForm, form, updateForm } = useFormFluxManagement<
    IFormConsortium
  >()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    pop,
    getLast,
    getLength,
    instantiate,
    reset,
  } = useStackFluxManagement<StepComponentProps>()

  useEffect(() => {
    if (isOpen && getLength() === 0) instantiate(Steps.CHOOSE_STATE)
  }, [isOpen, instantiate, getLength])

  const submitModalButton = useCallback(() => {
    setIsModalOpen(false)
    reset()
    resetForm()
    onCloseHandler()
    dispatch(ClearQuotes())
    dispatch(ProposalSimulationsClearData())
  }, [onCloseHandler, reset, resetForm, dispatch])

  const returnStep = React.useCallback(() => {
    if (getLength() === 5 || getLength() === 4) {
      updateForm({
        ...form,
        proposal: {
          ...form.proposal,
          numero_sequencia_proposta: 0,
        },
        quoteReservation: undefined,
      })

      dispatch(ClearQuotes())
      dispatch(ProposalSimulationsClearData())

      if (getLength() === 4) {
        pop()
      }
    }

    pop()
  }, [form, updateForm, getLength, pop, dispatch])

  return (
    <>
      <ModalExitFlow
        isOpen={isModalOpen}
        onHandleClose={() => setIsModalOpen(false)}
        onHandleSubmit={submitModalButton}
      />
      <s.Layer
        isOpen={isOpen}
        fullHeight={getLength() < 4}
        handleClose={() => setIsModalOpen(true)}
        data-testid="consortium-vehicle-layer"
        headerContent={
          <Container>
            <Row>
              <Col lg={12}>
                <s.HeaderContent>
                  {getLength() > 1 && getLength() < 9 && (
                    <s.PreviousIcon
                      data-gtm-subname="layer seguro viagem"
                      data-gtm-name="botao voltar step"
                      data-gtm-type="click"
                      data-gtm-clicktype="button"
                      onClick={() => returnStep()}
                    />
                  )}

                  <s.LogoWrapper>
                    <Icon name="logoConquista" width={144} />
                  </s.LogoWrapper>

                  <s.CloseIcon
                    className="layer-close-button"
                    data-gtm-subname="botao fechar layer"
                    data-gtm-name="botao fechar"
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    onClick={() => setIsModalOpen(true)}
                  />
                </s.HeaderContent>
              </Col>
            </Row>
          </Container>
        }
      >
        {getLast()}
      </s.Layer>
    </>
  )
}

export default SolicitationProcess
