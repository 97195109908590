import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { ICivilStatus, ICivilStatusConsortium, Types } from './types'
import { GetCivilStatusSuccess, GetCivilStatusFailure } from './action'

export function* getCivilStatus() {
  try {
    const { data }: AxiosResponse<ICivilStatusConsortium> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/estados-civis`
    )

    const civilStates = data.estados_civis.sort(
      (a: ICivilStatus, b: ICivilStatus) => {
        if (a.descricao > b.descricao) {
          return 1
        }
        if (a.descricao < b.descricao) {
          return -1
        }
        return 0
      }
    )

    yield put(GetCivilStatusSuccess(civilStates))
  } catch (err) {
    yield put(GetCivilStatusFailure())
  }
}

export default all([takeLatest(Types.GET_CIVIL_STATUS_REQUEST, getCivilStatus)])
