import { Types, IClientInformationConsortium } from './types'

export function GetClientInformationRequest() {
  return {
    type: Types.GET_CLIENT_INFORMATION_REQUEST,
  }
}

export function GetClientInformationSuccess(
  payload: IClientInformationConsortium
) {
  return {
    type: Types.GET_CLIENT_INFORMATION_SUCCESS,
    payload,
  }
}

export function GetClientInformationFailure() {
  return {
    type: Types.GET_CLIENT_INFORMATION_FAILURE,
  }
}

export function GetClientInformationInvalid() {
  return {
    type: Types.GET_CLIENT_INFORMATION_INVALID,
  }
}
