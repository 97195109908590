import { Types, IJobs } from './types'

export function GetJobsRequest() {
  return {
    type: Types.GET_JOBS_REQUEST,
  }
}

export function GetJobsSuccess(payload: IJobs[]) {
  return {
    type: Types.GET_JOBS_SUCCESS,
    payload,
  }
}

export function GetJobsFailure() {
  return {
    type: Types.GET_JOBS_FAILURE,
  }
}
