import { combineReducers } from 'redux'

import consortium from './Consortium/reducer'
import contemplated from './Consortium/Contemplated/reducer'
import consortiumJobs from './Consortium/Jobs/reducer'
import consortiumCountries from './Consortium/Countries/reducer'
import consortiumCivilStatus from './Consortium/CivilStatus/reducer'
import consortiumDocuments from './Consortium/Documents/reducer'
import consortiumFatca from './Consortium/Fatca/reducer'
import consortiumClientInformation from './Consortium/ClientInformation/reducer'
import consortiumClientPROInformation from './Consortium/ClientPROInformation/reducer'
import consortiumBank from './Consortium/Bank/reducer'
import consortiumProposal from './Consortium/Proposal/reducer'
import consortiumProposalSimulations from './Consortium/ProposalSimulations/reducer'
import consortiumStates from './Consortium/States/reducer'
import consortiumPpeType from './Consortium/PpeTypes/reducer'
import consortiumPpeRelations from './Consortium/PpeRelations/reducer'
import consortiumIncomeValidate from './Consortium/IncomeValidate/reducer'
import consortiumQuoteReservation from './Consortium/QuoteReservation/reducer'
import consortiumSendProposal from './Consortium/SendProposal/reducer'
import consortiumPassportCountries from './Consortium/PassportCountries/reducer'
import consortiumButtonPlanCard from './Consortium/PlanCard/reducer'
import consortiumFormPro from './Consortium/FormPro/reducer'
import customerProfile from './CustomerProfile/reducer'
import fluxManagement from './FluxManagement/reducer'
import points from './Points/reducer'
import auth from './Auth/reducer'
import tagManager from './TagManager/reducer'
import zipCode from './ZipCode/reducer'

export default combineReducers({
  auth,
  consortium,
  consortiumBank,
  consortiumButtonPlanCard,
  consortiumCivilStatus,
  consortiumClientInformation,
  consortiumClientPROInformation,
  consortiumCountries,
  consortiumDocuments,
  consortiumFatca,
  consortiumFormPro,
  consortiumIncomeValidate,
  consortiumJobs,
  consortiumPassportCountries,
  consortiumPpeRelations,
  consortiumPpeType,
  consortiumProposal,
  consortiumProposalSimulations,
  consortiumQuoteReservation,
  consortiumSendProposal,
  consortiumStates,
  contemplated,
  customerProfile,
  fluxManagement,
  points,
  tagManager,
  zipCode,
})
