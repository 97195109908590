export const start = 'start'
export const success = {
  title: 'Proposta enviada com sucesso',
  subtitle:
    'Seu cliente receberá uma notificação sobre o envio, e tomará a decisão pela contratação. Você poderá acompanhar a decisão do cliente no funil de vendas.',
}
export const fail = {
  title: 'Não foi possível enviar a proposta.',
  subtitle: 'Tente novamente mais tarde, por favor.',
}

export const icon = {
  name: {
    success: 'check',
    fail: 'closerounded',
  },
  color: {
    success: 'green3',
    fail: 'magenta2',
  },
}

export const message = {
  success,
  fail,
}

export const button = {
  title: start,
}
