import { Types, IQuoteReservationRequest } from './types'

export function PostQuotesRequest(request: IQuoteReservationRequest) {
  return {
    type: Types.POST_QUOTES_REQUEST,
    request,
  }
}

export function PostQuotesSuccess(payload: number) {
  return {
    type: Types.POST_QUOTES_SUCCESS,
    payload,
  }
}

export function PostQuotesFailure() {
  return {
    type: Types.POST_QUOTES_FAILURE,
  }
}

export function ClearQuotes() {
  return {
    type: Types.CLEAR_QUOTES,
  }
}
