import { AxiosResponse } from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'

import Conflict from 'services/exceptions/ConflictExcpetion'

import { Types, IClientInformationConsortium } from './types'
import {
  GetClientInformationSuccess,
  GetClientInformationFailure,
  GetClientInformationInvalid,
} from './action'
import { INITIAL_DATA } from './initialData'

export function* getClientInformation() {
  try {
    const { data }: AxiosResponse<IClientInformationConsortium> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/clientes/dados`
    )

    yield put(GetClientInformationSuccess(data ? data : INITIAL_DATA))
  } catch (err) {
    if (err instanceof Conflict) {
      yield put(GetClientInformationInvalid())
    }
    yield put(GetClientInformationFailure())
  }
}

export default all([
  takeLatest(Types.GET_CLIENT_INFORMATION_REQUEST, getClientInformation),
])
