import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { Types, IAction } from './types'
import { GetProposalSuccess, GetProposalFailure } from './action'

import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'

export function* getProposal({ payload }: IAction) {
  try {
    const { data }: AxiosResponse<FormProposalConsortium> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/propostas/sugestao/${payload}`
    )

    yield put(GetProposalSuccess(data))
  } catch (err) {
    const errMsg = JSON.parse(err.message)
    yield put(GetProposalFailure(errMsg.msg))
  }
}

export default all([takeLatest(Types.GET_PROPOSAL_REQUEST, getProposal)])
