import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'

import DrawerCmp from 'components/Drawer'
import NewInput from 'components/NewInput'
import SelectComponent from 'components/Inputs/AutoCompleteSelect'
import {
  InputGroupWrapper,
  ErrorMessage,
  TextError,
} from 'components/NewInput/styles'
import Text from 'components/Text'
import ButtonCmp from 'components/Button'
import PopoverCmp from 'components/Popover'
import Radio from 'components/Inputs/Radio'
import IconCmp from 'components/IconExporter'

import { DrawerBody } from 'pages/Consortium/SolicitationProcess/PRO/commons/Drawer/styles'

export const RowDocument = styled(Row)`
  display: flex;
  margin: 0;
`

export const ColDocument = styled(Col)`
  padding: 0;

  &:last-of-type {
    flex: 1;
    max-width: 100%;
  }
`

export const WrapperDocument = styled.div`
  position: relative;
`

export const Select = styled(SelectComponent)`
  margin: ${({ theme }) => `${theme.spacing(2)}px 0 ${theme.spacing(4)}px`};
`

export const LastSelect = styled(SelectComponent)`
  margin: ${({ theme }) => `${theme.spacing(2)}px 0 0px`};
`

interface DocumentCardProps {
  errorLines: number
}

export const InputDocument = styled(NewInput)<DocumentCardProps>`
  margin: ${({ theme }) => `0 0 ${theme.spacing(4)}px`};
  height: unset;
  min-height: ${({ theme }) => theme.spacing(10)}px;

  ${InputGroupWrapper} {
    height: ${({ theme }) => theme.spacing(10)}px;
  }

  ${ErrorMessage} {
    display: flex;
    align-items: flex-start;

    ${TextError} {
      height: fit-content;
    }
  }

  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    margin-top: 0;
  }

  span {
    ${({ theme }) => theme.fontTypes.body};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin: ${({ theme }) => `0 0 ${theme.spacing(4)}px`};

    &:first-child {
      margin-top: 0;
    }
  }
`

export const TextDocument = styled(Text)`
  position: absolute;
  top: 14px;
  right: -18px;
  ${({ theme }) => theme.fontTypes.caption};
  color: ${({ theme }) => theme.colors.gray5};
  width: 140px;
  height: 80px;
`

export const RadioDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`

export const LabelRadioDocument = styled(Text).attrs(() => ({
  type: 'headline',
  bold: true,
}))``

export const RadioOptionsDocumentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  height: 30px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: initial;
    align-items: center;
    justify-content: flex-start;
  }

  & label {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const RadioDocument = styled(Radio)``

interface IPopover {
  noMargin?: boolean
}

export const Popover = styled(PopoverCmp)<IPopover>`
  align-items: center;
  display: flex;
`

export const TextContainer = styled.div`
  width: 213px;
`

export const TextDescription = styled(Text)``
export const Icon = styled(IconCmp)``

export const Button = styled(ButtonCmp)`
  width: 100%;
`

export const Drawer = styled(DrawerCmp)`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  max-width: 392px;
  ${DrawerBody} {
    padding: ${({ theme }) => theme.spacing(3)}px
      ${({ theme }) => theme.spacing(2)}px;
  }
`
