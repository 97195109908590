import React from 'react'

import { TOptions, TSelected } from 'components/Inputs/Select/types'
import { IErrorResponse } from 'pages/Consortium/commons/ErrorForm/types'
import { TextsConsortium } from 'pages/Consortium/commons'

import * as s from './styles'

interface ChangeSimulationDataMobileProps {
  isOpen: boolean
  isValid: boolean
  isLoading: boolean
  statesOptions: TOptions[]
  selectedState: TSelected
  contemplatedValueInput: { value: string; touched: boolean }
  setContemplatedValueInput: React.Dispatch<
    React.SetStateAction<{
      value: string
      touched: boolean
    }>
  >
  handleClose: () => void
  handleMoneyChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  validateMoneyChange: () => false | IErrorResponse
  handleSimulationSubmit: () => void
}
const ChangeSimulationDataMobile = ({
  isOpen,
  handleClose,
  statesOptions,
  selectedState,
  handleSimulationSubmit,
  contemplatedValueInput,
  handleMoneyChange,
  isLoading,
  isValid,
  setContemplatedValueInput,
  validateMoneyChange,
}: ChangeSimulationDataMobileProps) => {
  const { creation } = TextsConsortium()

  return (
    <s.Modal
      modalTitle="Alterar dados"
      isOpen={isOpen}
      handleClose={handleClose}
      footer={
        <s.Button onClick={handleSimulationSubmit} disabled={!isValid}>
          Atualizar simulação
        </s.Button>
      }
    >
      <s.Select
        testId="update-simulation-select"
        options={statesOptions}
        selected={selectedState}
        label="estado onde reside"
        optionBoxSize={220}
      />
      <s.UpdateSimulationText type="headline">
        Qual valor você planeja receber ao ser contemplado?
      </s.UpdateSimulationText>
      <s.UpdateSimulationText type="body">
        Valores disponíveis entre 50 mil e 500 mil reais
      </s.UpdateSimulationText>

      <s.InputMoney
        data-testid="financial-application-money-input"
        data-gtm-form="input"
        data-gtm-name="quanto investir"
        maxLength={14}
        value={contemplatedValueInput.value}
        error={contemplatedValueInput.touched && validateMoneyChange()}
        placeholder={creation.steps.value.inputLabel}
        disabled={isLoading}
        onChange={handleMoneyChange}
        onBlur={() => {
          setContemplatedValueInput(prevState => ({
            ...prevState,
            touched: true,
          }))
        }}
      />
    </s.Modal>
  )
}

export default ChangeSimulationDataMobile
