import React, { memo } from 'react'
import useMedia from 'hooks/useMedia'
import SummaryItem from './SummaryItem'
import { ISummary } from './types'
import * as S from './styles'

const Summary: React.FC<ISummary> = ({ title, items }: ISummary) => {
  const isMdUp = useMedia('mdUp')
  return (
    <S.Card>
      <S.AccordionNew
        titleNode={
          <S.ContainerTitle>
            <S.Title data-testid="symmary_title" type="heading4">
              {title}
            </S.Title>
          </S.ContainerTitle>
        }
        openByDefault={isMdUp}
        triggerCloseOnArrow
        bodyPadding="0"
        hasCloseButton={!isMdUp}
      >
        <S.List data-testid="summary_list">
          {items.map(({ label, value }, index) => (
            <S.ListItem key={index}>
              <SummaryItem label={label} value={value} />
            </S.ListItem>
          ))}
        </S.List>
      </S.AccordionNew>
    </S.Card>
  )
}

export default memo(Summary)
