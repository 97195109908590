import styled, { css } from 'styled-components'

import DrawerCmp from 'pages/Consortium/SolicitationProcess/PRO/commons/Drawer'
import { DrawerBody } from 'pages/Consortium/SolicitationProcess/PRO/commons/Drawer/styles'

import ButtonCmp from 'components/Button'
import MoneyInput from 'components/Inputs/Money'
import SelectComponent from 'components/Inputs/AutoCompleteSelect'

const placeholderColor = css`
  & #prefix {
    color: ${({ theme }) => theme.colors.gray6};
  }
`

export const Select = styled(SelectComponent)`
  margin-bottom: ${({ theme }) => `${theme.spacing(4)}px`};

  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
    margin-left: ${({ theme }) => `${theme.spacing(2)}px`};
    margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
    margin-left: 0;
    margin-right: 0;
  }
`

export const InputMoney = styled(MoneyInput)`
  margin-bottom: ${({ theme }) => theme.spacing(14)}px;
  ${({ value }) => !!value && placeholderColor}


  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-left: ${({ theme }) => `${theme.spacing(2)}px`};
    margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 0;
    margin-left: 0;
  }
`

export const Button = styled(ButtonCmp)`
  width: 100%;
`

export const Drawer = styled(DrawerCmp)`
  ${DrawerBody} {
    padding: 0;
  }
`
