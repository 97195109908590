import React from 'react'

import Modal from 'components/Modal/ModalNew'
import useMedia from 'hooks/useMedia'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { ITexts } from 'pages/Consortium/commons/Texts/types'
import { IFormConsortium } from '../../TypesPartnerOffer/Form'

import * as s from './styles'

interface ILifeInsuranceDrawer {
  isOpen: boolean
  handleClose: () => void
}
const LifeInsuranceDrawer: React.FC<ILifeInsuranceDrawer> = ({
  isOpen,
  handleClose,
}: ILifeInsuranceDrawer) => {
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()
  const [texts] = React.useState<ITexts>(TextsConsortium())
  const [
    lifeInsuranceObservations,
    setLifeInsuranceObservations,
  ] = React.useState('')
  const [isChecked, setIsChecked] = React.useState(false)
  const isMobile = useMedia('sm')

  const listItem =
    texts.hire.steps.lifeInsurance.containerFirst.content &&
    Object.values(texts.hire.steps.lifeInsurance.containerFirst.content)

  const linkTextRender = (value: string[]) => {
    return (
      <s.ContentText>
        {value.map((v: string, i: number) =>
          i === 1 || i === 5 ? (
            <s.LinkLifeInsuranceDrawer key={v}>{v}</s.LinkLifeInsuranceDrawer>
          ) : (
            <s.TextLifeInsuranceDrawer key={v}>{v}</s.TextLifeInsuranceDrawer>
          )
        )}
      </s.ContentText>
    )
  }

  const handleClick = React.useCallback(() => {
    updateForm({
      ...form,
      proposal: {
        ...form.proposal,
        obs_seguro_vida: lifeInsuranceObservations,
      },
    })
    handleClose()
  }, [updateForm, form, lifeInsuranceObservations, handleClose])

  const renderContent = () => (
    <s.Wrapper>
      <s.TitleLifeInsuranceDrawer>
        {texts.pro.lifeInsuranceDrawer.title}
      </s.TitleLifeInsuranceDrawer>
      <s.ContainerText>
        {listItem &&
          listItem.map(value => (
            <s.ContentText key={value.item}>
              <s.TextLifeInsuranceDrawer>
                {value.item}
              </s.TextLifeInsuranceDrawer>
              {Array.isArray(value.text) ? (
                linkTextRender(value.text)
              ) : (
                <s.TextLifeInsuranceDrawer>
                  {value.text}
                </s.TextLifeInsuranceDrawer>
              )}
            </s.ContentText>
          ))}
      </s.ContainerText>
      <s.DivisorCmp />
      <s.TitleLifeInsuranceDrawer>
        {texts.hire.steps.lifeInsurance.containerFirst.title}
      </s.TitleLifeInsuranceDrawer>
      <s.UlLifeInsuranceDrawer>
        {texts.hire.steps.lifeInsurance.containerSecond.itens &&
          texts.hire.steps.lifeInsurance.containerSecond.itens.map(value => (
            <s.LiLifeInsuranceDrawer key={Math.random()}>
              {value}
            </s.LiLifeInsuranceDrawer>
          ))}
      </s.UlLifeInsuranceDrawer>
      <s.TitleLifeInsuranceDrawer>
        {texts.hire.steps.lifeInsurance.containerSecond.titleTextArea}
      </s.TitleLifeInsuranceDrawer>
      <s.WrapperTextArea>
        <s.CompTextArea
          disabled={false}
          rows={6}
          placeholder="Ex:&#x0a;2. Passei por cirurgia de ortondotia de implante dentário&#x0a;..."
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
            setLifeInsuranceObservations(e.currentTarget.value)
          }
        />
      </s.WrapperTextArea>
      <s.DivisorCmp />
      <s.WrapperTextEnd>
        {texts.hire.steps.lifeInsurance.containerThird.texts &&
          texts.hire.steps.lifeInsurance.containerThird.texts.map(value => (
            <s.TextLifeInsuranceDrawer key={Math.random()}>
              {value}
            </s.TextLifeInsuranceDrawer>
          ))}
      </s.WrapperTextEnd>
      <s.DivisorCmp />
      <s.WrappperCheckbox>
        <s.CheckBoxCmp
          label={
            <s.TextLifeInsuranceDrawer>
              {texts.hire.steps.lifeInsurance.containerThird.checkbox}
            </s.TextLifeInsuranceDrawer>
          }
          onChange={() => setIsChecked(prevState => !prevState)}
          checked={isChecked}
          id="checkbox-accept-alignment-term"
        />
      </s.WrappperCheckbox>
    </s.Wrapper>
  )

  const footerContent = () => (
    <s.Button
      data-gtm-name={texts.buttons.saveChanges}
      data-gtm-type="click"
      data-gtm-clicktype="button"
      disabled={!isChecked}
      onClick={handleClick}
    >
      {texts.buttons.saveChanges}
    </s.Button>
  )

  return isMobile ? (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      modalTitle={texts.menuPro.lifeInsurance}
      footer={footerContent()}
    >
      {renderContent()}
    </Modal>
  ) : (
    <s.WrapperDrawer
      isOpen={isOpen}
      handleClose={handleClose}
      drawerTitle={texts.menuPro.lifeInsurance}
      footer={footerContent()}
      size="sm"
    >
      {renderContent()}
    </s.WrapperDrawer>
  )
}

export default LifeInsuranceDrawer
