import { IClientInformationConsortium } from 'store/modules/Consortium/ClientInformation/types'
import { IClientInformationPRORequest, Types } from './types'

export function ClientInformationPRORequest(
  payload: IClientInformationPRORequest
) {
  return {
    type: Types.POST_CLIENT_INFORMATION_REQUEST,
    payload,
  }
}

export function ClientInformationPROSuccess(
  payload: IClientInformationConsortium
) {
  return {
    type: Types.POST_CLIENT_INFORMATION_SUCCESS,
    payload,
  }
}

export function ClientInformationPROFailure() {
  return {
    type: Types.POST_CLIENT_INFORMATION_FAILURE,
  }
}

export function ClientInformationPROInvalid() {
  return {
    type: Types.POST_CLIENT_INFORMATION_INVALID,
  }
}
