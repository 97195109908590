import { Types } from './types'

const INITIAL_STATE: { form: {} } = {
  form: {},
}

export default function(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Types.BUILDING_FORM_PRO_UPDATE_FORM:
      const { updatedKeys } = action

      return {
        ...state,
        form: {
          ...state.form,
          ...updatedKeys,
        },
      }
    case Types.BUILDING_FORM_PRO_FORM_RESET:
      return {
        ...state,
        form: {},
      }

    default:
      return state
  }
}
