export const Types = {
  GET_PEP_TYPES_REQUEST: 'GET_PEP_TYPES_REQUEST',
  GET_PEP_TYPES_SUCCESS: 'GET_PEP_TYPES_SUCCESS',
  GET_PEP_TYPES_FAILURE: 'GET_PEP_TYPES_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IPepTypesResponse
}

export interface IPepType {
  codigo: string
  descricao: string
}

export interface IPepTypesResponse {
  tipos_pep: IPepType[]
}

export interface IPayload {
  type: keyof typeof Types
}

export interface IConsortiumPepTypes {
  data: IPepType[] | null
  isLoading: boolean
  hasError: boolean
}
