import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'

export const Types = {
  POST_SEND_PROPOSAL_REQUEST: 'POST_SEND_PROPOSAL_REQUEST',
  POST_SEND_PROPOSAL_SUCCESS: 'POST_SEND_PROPOSAL_SUCCESS',
  POST_SEND_PROPOSAL_FAILURE: 'POST_SEND_PROPOSAL_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: FormProposalConsortium
}

export interface IReturnRequestType {
  numero_reserva: number
}

export interface IPayload {
  type: keyof typeof Types
  payload: FormProposalConsortium
}

export interface ISendProposalConsortiumState {
  readonly data: FormProposalConsortium | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
