import { Types, IPepType } from './types'

export function GetTypesRequest() {
  return {
    type: Types.GET_PEP_TYPES_REQUEST,
  }
}

export function GetPepTypesSuccess(payload: IPepType[]) {
  return {
    type: Types.GET_PEP_TYPES_SUCCESS,
    payload,
  }
}

export function GetPepTypesFailure() {
  return {
    type: Types.GET_PEP_TYPES_FAILURE,
  }
}
