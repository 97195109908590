import styled, { css } from 'styled-components'

import MoneyInputCmp from 'components/Inputs/Money'

const placeholderColor = css`
  & #prefix {
    color: ${({ theme }) => theme.colors.gray6};
  }
`

export const InputMoney = styled(MoneyInputCmp)`
  ${({ value }) => !!value && placeholderColor}
`
