import React, { useMemo } from 'react'
import { themeColors } from 'styles/theme'
import IconExporter, { dict } from 'components/IconExporter'
import { icon, message } from './static'
import { IProps } from './types'
import * as S from './styles'

const Status: React.FC<IProps> = ({ status }: IProps) =>
  useMemo(
    () => (
      <S.Container>
        <S.ContainerIcon>
          <IconExporter
            name={icon.name[status] as keyof typeof dict}
            color={icon.color[status] as keyof typeof themeColors}
            width="28px"
          />
        </S.ContainerIcon>
        <S.Message data-testid="status-message">
          <S.Title type="headline" bold color="gray6">
            {message[status].title}
          </S.Title>
          <S.Subtitle type="body" color="gray5">
            {message[status].subtitle}
          </S.Subtitle>
        </S.Message>
      </S.Container>
    ),
    [status]
  )

export default Status
