import styled from 'styled-components'
import Card from 'components/Card'
import { Text } from 'components'

export const Container = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    margin: ${({ theme }) => `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`};
  }
`

export const ContainerContent = styled.div`
  padding: ${({ theme }) => theme.spacing(3)}px;

  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) => theme.spacing(2)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    padding: ${({ theme }) => `${theme.spacing(2)}px 0`};
  }
`

export const ContainerTitle = styled.div`
  margin: ${({ theme }) => `0 0 ${theme.spacing(4)}px ${theme.spacing(2)}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin: ${({ theme }) => `0 0 ${theme.spacing(4)}px`};
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    margin: ${({ theme }) => `0 ${theme.spacing(2)}px ${theme.spacing(4)}px`};
  }
`

export const Title = styled(Text).attrs(() => ({
  type: 'subheading',
  bold: true,
  color: 'gray5',
  textTransform: 'uppercase',
}))``
