import styled from 'styled-components'
import TextCmp, { IProps } from 'components/Text'
import ButtonCmp from 'components/Button'

export const Container = styled.div`
  --step-bar-height: ${({ theme }) => theme.spacing(8)}px;
  --footer-height: ${({ theme }) => theme.spacing(9)}px;
  --container-height: calc(
    100vh - var(--step-bar-height) - var(--footer-height)
  );

  width: 100vw;
  height: var(--container-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray1};
`

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 400px;
`

export const FailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--container-height) / 1.7);
`

export const ContainerUser = styled.div``

export const TextProps: IProps = {
  textAlign: 'center',
  type: 'subheading',
  color: 'gray5',
  textTransform: 'uppercase',
}

export const Text = styled(TextCmp)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Button = styled(ButtonCmp)`
  &:first-child {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
`
