import React, { useMemo } from 'react'
import Avatar from './Avatar'
import { IUserCard } from './types'
import * as S from './styles'

const UserCard: React.FC<IUserCard> = ({ name, type }: IUserCard) =>
  useMemo(
    () => (
      <S.Container data-testid="user-card">
        <Avatar name={name} />
        <S.ContainerText>
          <S.Name {...S.NameProps}>{name}</S.Name>
          <S.Type {...S.TypeProps}>{type}</S.Type>
        </S.ContainerText>
      </S.Container>
    ),
    [name, type]
  )

export default UserCard
