import React from 'react'

import { formatMoney } from 'helpers'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import * as s from '../../styles'
import { CardDataType } from '../../types'

const ConsortiumData: React.FC<CardDataType> = ({
  proposalData: [proposalData, setProposalData],
}) => {
  const { hire } = TextsConsortium()
  const reservedFund = Number(proposalData.fundo_reserva)
  const SEGURO_VIDA =
    Number(proposalData.seguro_vida) *
    (Number(proposalData.valor_credito) / 100)

  return (
    <s.cardStructure>
      <s.cardHeader>
        <s.cardHeaderTitle>
          {hire.steps.summary.pro.containerSeventh.header.title}
        </s.cardHeaderTitle>
      </s.cardHeader>
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerSeventh.content.creditValue}
          value={formatMoney(proposalData.valor_credito || 0)}
          error={!proposalData.valor_credito}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerSeventh.content.reserveFund}
          value={`${reservedFund}%`}
          error={!proposalData.fundo_reserva}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerSeventh.content.lifeInsurance}
          value={formatMoney(SEGURO_VIDA, {
            fractionDigits: 3,
          })}
          error={!proposalData.seguro_vida}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerSeventh.content.admTax}
          value={`${proposalData.taxa_administrativa}%`}
          error={!proposalData.taxa_administrativa}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerSeventh.content.advAdmTax}
          value="2%"
        />
      </s.cardContent>
    </s.cardStructure>
  )
}

export default ConsortiumData
