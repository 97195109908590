import React, { useState, useLayoutEffect } from 'react'

import { StepComponentProps } from 'hooks/FluxManagement/types'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'

import Text from 'components/Text'
import Checkbox from 'components/Inputs/Checkbox'

import { PageTip } from 'pages/Consortium/commons'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import CardStructure from 'pages/Consortium/SolicitationProcess/Components/CardStructure'

import { IFormConsortium, Steps } from '../../../Types'

import * as s from './styles'

const LifeInsurance = () => {
  const { hire } = TextsConsortium()
  const { instantiate } = useStackFluxManagement<StepComponentProps>()

  const firstItems: string[] | undefined =
    hire.steps.lifeInsurance.containerFirst.content?.textFirst.text
  const secondItems: string[] | undefined =
    hire.steps.lifeInsurance.containerSecond.itens
  const thirdItems: string[] | undefined =
    hire.steps.lifeInsurance.containerThird.texts

  const [isChecked, setIsChecked] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()

  React.useEffect(() => {
    if (form) {
      setTextAreaValue(form.proposal.obs_seguro_vida)
    }
  }, [form, setTextAreaValue])

  useLayoutEffect(() => {
    const layerChildrenElement = document.querySelector('#layer-children')
    if (layerChildrenElement) {
      layerChildrenElement.scrollTo({
        top: 0,
      })
    }
  }, [])

  const handleSubmit = React.useCallback(() => {
    updateForm({
      ...form,
      proposal: {
        ...form.proposal,
        codigo_canal: '7',
        obs_seguro_vida: textAreaValue,
      },
    })
    instantiate(Steps.SUMMARY_STEP)
  }, [textAreaValue, form, updateForm, instantiate])

  return (
    <>
      <s.ContentContainer>
        <s.ColumnSide lg={3} md={10}>
          <PageTip
            title={hire.steps.lifeInsurance.title}
            subtitle={hire.steps.lifeInsurance.content}
          />
        </s.ColumnSide>
        <s.Column lg={8} sm={12}>
          <CardStructure title={hire.steps.lifeInsurance.title}>
            <s.DivWrapper>
              <s.Div>
                <s.IndexText>
                  {
                    hire.steps.lifeInsurance.containerFirst.content?.textFirst
                      .item
                  }
                </s.IndexText>
                <s.Text>
                  {firstItems &&
                    firstItems.map((item: string, index: number) =>
                      index === 1 || index === 5 ? (
                        index === 1 ? (
                          <s.Link
                            href="https://www.portoseguro.com.br/NovoInstitucional/static_files/documents/para-imoveis/consorcio-imoveis/regulamento_consorcio_de_veiculo.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            key={Math.random()}
                          >
                            {item}
                          </s.Link>
                        ) : (
                          <s.Link
                            href="https://www.portoseguro.com.br/NovoInstitucional/static_files/documents/para-imoveis/consorcio-imoveis/regulamento_consorcio_de_veiculo.pdf#page=9"
                            target="_blank"
                            rel="noopener noreferrer"
                            key={Math.random()}
                          >
                            {item}
                          </s.Link>
                        )
                      ) : (
                        item
                      )
                    )}
                </s.Text>
              </s.Div>
              <s.Div>
                <s.IndexText>
                  {
                    hire.steps.lifeInsurance.containerFirst.content?.textSecond
                      .item
                  }
                </s.IndexText>
                <s.Text>
                  {
                    hire.steps.lifeInsurance.containerFirst.content?.textSecond
                      .text
                  }
                </s.Text>
              </s.Div>
              <s.Div>
                <s.IndexText>
                  {
                    hire.steps.lifeInsurance.containerFirst.content?.textThird
                      .item
                  }
                </s.IndexText>
                <s.Text>
                  {
                    hire.steps.lifeInsurance.containerFirst.content?.textThird
                      .text
                  }
                </s.Text>
              </s.Div>
            </s.DivWrapper>
          </CardStructure>
          <CardStructure>
            <s.DivWrapper>
              <s.TitleText type="headline" bold>
                {hire.steps.lifeInsurance.containerSecond?.title}
              </s.TitleText>
              <s.DivOrderer>
                <s.OrderedList>
                  {secondItems &&
                    secondItems.map((item: string) => (
                      <s.ItemsList key={Math.random()}>{item}</s.ItemsList>
                    ))}
                </s.OrderedList>
              </s.DivOrderer>
              <s.Divider></s.Divider>
              <s.TitleText type="headline" bold>
                {hire.steps.lifeInsurance.containerSecond?.titleTextArea}
              </s.TitleText>
              <s.DivTextArea>
                <s.CompTextArea
                  disabled={false}
                  rows={6}
                  value={textAreaValue}
                  placeholder="Ex:&#x0a;2. Passei por cirurgia de ortondotia de implante dentário&#x0a;..."
                  onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                    setTextAreaValue(e.currentTarget.value)
                  }
                ></s.CompTextArea>
              </s.DivTextArea>
            </s.DivWrapper>
          </CardStructure>
          <CardStructure>
            <s.DivWrapper>
              <s.DivLastComp>
                {thirdItems &&
                  thirdItems.map((item: string) => (
                    <s.TextDeclaration key={Math.random()}>
                      {item}
                    </s.TextDeclaration>
                  ))}
              </s.DivLastComp>
              <s.Divider></s.Divider>
              <s.DivWithoutSpace>
                <s.CheckboxContainer>
                  <Checkbox
                    label={
                      <Text type="body" color="gray5" bold>
                        Confirmo que li e concordo com todas as informações.
                      </Text>
                    }
                    onClick={() => setIsChecked(!isChecked)}
                    checked={isChecked}
                    id="checkbox-accept-alignment-term"
                  />
                </s.CheckboxContainer>
              </s.DivWithoutSpace>
            </s.DivWrapper>
          </CardStructure>
        </s.Column>
      </s.ContentContainer>
      <s.FooterContainer>
        <s.ContentWrapperFooter>
          <s.NextButton
            disabled={!isChecked}
            color="secondary"
            onClick={handleSubmit}
          >
            continuar
          </s.NextButton>
        </s.ContentWrapperFooter>
      </s.FooterContainer>
    </>
  )
}
export default LifeInsurance
