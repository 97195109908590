import styled, { css } from 'styled-components'
import Text from 'components/Text'
import { Col, media } from 'styled-bootstrap-grid'
import Select from 'components/Inputs/AutoCompleteSelect'
import NewInput from 'components/NewInput'
import Divisor from 'components/Divisor'
import DataShower from 'pages/Consortium/SolicitationProcess/Components/DataShower'

interface IIsDrawer {
  isDrawer?: boolean
  isModal?: boolean
}

const isDrawerStyle = css`
  padding: 0;

  & > h5,
  & > p {
    padding-left: 0;
    padding-right: 0;
  }

  & > div {
    &[data-name='row'] {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;

      & > div {
        & > div {
          margin-bottom: ${({ theme }) => theme.spacing(4)}px;
        }
      }
    }

    &:last-child {
      & > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`

const spaceRigthMargin = css`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  ${({ theme }) => media.sm`
    padding-right: ${theme.spacing(4)}px;
    margin: 0;
  `}
`

export const WrapperPaymentData = styled.div<IIsDrawer>`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  max-width: 658px;
  ${({ isDrawer }) => isDrawer && isDrawerStyle}
`

export const TitleText = styled(Text)`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px ${theme.spacing(2)}`}px;
  ${({ type, theme }) => type === 'body' && `color: ${theme.colors.gray5}`};
`

export const RowPaymentData = styled.div<IIsDrawer>`
  margin: ${({ theme }) => `${theme.spacing(3)}px 0 0`}px;
  padding: ${({ theme, isDrawer }) =>
    isDrawer ? 0 : `0 ${theme.spacing(2)}px`};

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 630px;
  }
`

export const ColPaymentData = styled(Col)`
  margin: 0;
  padding: 0;

  ${({ sm }) => sm === 5 && spaceRigthMargin}
`
export const SelectPaymentData = styled(Select)`
  margin-bottom: ${({ theme }) => theme.spacing(3.5)}px;
`

export const SelectRatePaymentData = styled(Select)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const InputPaymentData = styled(NewInput)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const DivisorPaymentData = styled(Divisor)`
  margin: 0;
`

export const WrapperRateSelect = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}`}px;
`

export const WrapperInfoRate = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;

  & > div {
    &:first-child {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }
`

export const ContentInfoRate = styled.div`
  & > div {
    padding-top: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }
`

export const DataDisplay = styled(DataShower)`
  padding-left: ${({ theme }) => theme.spacing(2)}px;

  span,
  h6 {
    ${({ theme }) => theme.fontTypes.title}
  }

  div {
    overflow-wrap: anywhere;
  }
`
export const InputsContainer = styled.div<IIsDrawer>`
  display: flex;
  flex-direction: ${({ isDrawer }) => (isDrawer ? 'column' : 'row')};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
  }
`
