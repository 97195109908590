import styled from 'styled-components'

import Text from 'components/Text'
import Button from 'components/Button'

export const ErrorText = styled(Text).attrs(() => ({
  color: 'magenta2',
  type: 'headline',
  textAlign: 'center',
}))`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 136px);

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    padding: 0 14px;
  }
`

export const RetryButton = styled(Button)`
  max-width: 192px;
`
