import { all, call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { setCookieValue, removeCookieValue } from 'helpers'
import { AuthRequestLoginSuccess, AuthRequestLoginFailure } from './action'
import { Types, TOKEN_NAME } from './types'

const axiosInstance = axios.create({
  headers: {
    Accept: '*/*',
    'content-type': 'application/x-www-form-urlencoded',
    Hint: 'login_cliente',
  },
})

function saveAuthCookie({ cpf, accessToken }) {
  setCookieValue('cpf', cpf)
  setCookieValue(TOKEN_NAME, accessToken)
}

function removeAuthCookie() {
  removeCookieValue('cpf')
  removeCookieValue(TOKEN_NAME)
}

export function* postLogin({ credentials: { username, password } }) {
  try {
    const loginUrl = process.env.REACT_APP_BASE_URL_AUTH2
    const clientId = process.env.REACT_APP_CLIENT_ID
    const clientSecret = process.env.REACT_APP_SECRET_ID

    const data = `username=${username
      .toString()
      .replace(
        /\D/g,
        ''
      )}&password=${password}&grant_type=password&client_id=${clientId}&client_secret=${clientSecret}&social=13213`
    const {
      data: { access_token: accessToken, erro },
    } = yield call(axiosInstance.post, `${loginUrl}/clientes/login`, data)

    if (erro) throw erro.mensagemErro

    const response = {
      accessToken,
      cpf: username.toString().replace(/\D/g, ''),
    }

    yield put(AuthRequestLoginSuccess(response))
    saveAuthCookie(response)
    removeCookieValue('hideConquistaRecommendationBanner')
    removeCookieValue('positionConquistaRecommendationBanner')
  } catch (error) {
    yield put(AuthRequestLoginFailure(error.toString()))
  }
}

export function* postLogout() {
  const logoutUrl = process.env.REACT_APP_BASE_URL_LOGOUT

  removeAuthCookie()
  removeCookieValue('hideConquistaRecommendationBanner')
  removeCookieValue('positionConquistaRecommendationBanner')

  if (
    process.env.REACT_APP_ENV !== 'development' &&
    process.env.REACT_APP_ENV !== 'local'
  ) {
    window.location.href = logoutUrl
  }
  yield true
}

export default all([
  takeLatest(Types.AUTH_REQUEST_LOGIN, postLogin),
  takeLatest(Types.AUTH_REQUEST_LOGOUT, postLogout),
])
