import React from 'react'
import moment from 'moment'

import { formatMoney } from 'helpers'

import Divisor from 'components/Divisor'

import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'

import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'

import { IInstallmentDataCard } from '../types'
import * as s from './styles'

const InstallmentDataCard: React.FC<IInstallmentDataCard> = ({
  label,
}: IInstallmentDataCard) => {
  const { form } = useFormFluxManagement<IFormConsortium>()

  return (
    <s.WrapperInstallmentCard>
      <s.DataDisplay
        label={label.monthlyInstallment}
        value={formatMoney(form.proposal.valor_demais_parcelas)}
      />
      <Divisor />
      <s.DataDisplay
        label={label.numberInstallment}
        value={String(form.proposal.prazo)}
      />
      <Divisor />
      <s.DataDisplay
        label={label.dateForeseenFirstInstallment}
        value={String(
          moment(form.proposal.dt_venc_assembl, 'DD/MM/YYYY').format(
            'DD/MM/YYYY'
          )
        )}
      />
    </s.WrapperInstallmentCard>
  )
}

export default InstallmentDataCard
