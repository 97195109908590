import styled from 'styled-components'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import Text from 'components/Text'
import { Divisor as compDivisor } from 'components'
import Button from 'components/Button'

export const ContentContainer = styled(Container)``

export const Column = styled(Col)``

export const RowContent = styled(Row)``

export const FooterContent = styled(Container)``

export const Wrapper = styled.div`
  & > div {
    &:first-child {
      padding-top: ${({ theme }) => theme.spacing(7)}px;
    }
    &:nth-child(3) {
      padding-bottom: ${({ theme }) => theme.spacing(3)}px;

      & > div {
        h5 {
          display: none;
        }
      }
    }

    &:nth-child(5) {
      & > div {
        max-width: 100%;

        & > h5,
        & > p {
          display: none;
        }

        & > div {
          &[data-name='row'] {
            max-width: 630px;
          }

          &[data-name='row'] + div {
            display: none;
          }

          &:last-child {
            max-width: 630px;
          }
        }
      }
    }

    &:last-child {
      padding-bottom: ${({ theme }) => theme.spacing(4)}px;
    }
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing(7)}px;
  margin-top: ${({ theme }) => theme.spacing(7)}px;
  margin-right: ${({ theme }) => theme.spacing(7)}px;
  font-size: 18px;

  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }
`

export const WrapperForm = styled.div`
  padding: ${({ theme }) => theme.spacing(5)}px;
`

export const TitleCardForm = styled(Text).attrs(() => ({
  type: 'subheading',
  color: 'gray5',
  bold: true,
}))`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  text-transform: uppercase;
`

export const Title = styled(Text).attrs(() => ({
  type: 'headline',
  color: 'gray6',
  bold: true,
}))`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const TitleText = styled(Text)`
  padding: ${({ theme }) => theme.spacing(2)}px;
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    text-align: center;
    &:last-child {
      margin-bottom: ${({ theme }) => theme.spacing(4)}px;
    }
  }
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    text-align: left;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing(2)}px;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: 100vw;
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
`

export const ButtonAlign = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.spacing(3)}px;

  @media ${({ theme }) => theme.mediaQueries.lg} {
    padding-right: ${({ theme }) => theme.spacing(2)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: 0;
  }
`

export const NextButton = styled(Button)`
  width: 100%;
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 133px;
  }
`

export const Divisor = styled(compDivisor)``
