export const Types = {
  GET_STATES_REQUEST: 'GET_STATES_REQUEST',
  GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
  GET_STATES_FAILURE: 'GET_STATES_FAILURE',
}

export interface Action {
  type: keyof typeof Types
  payload: StatesResponseBody
}

export interface State {
  codigo: string
  descricao: string
}

export interface StatesResponseBody {
  ufs: State[]
}

export interface StatesReducer {
  data: StatesResponseBody | null
  isLoading: boolean
  hasError: boolean
}
