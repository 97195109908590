import React from 'react'
import IconExporter from 'components/IconExporter'
import theme from 'styles/theme'
import { ICardRadioOptionPayment, IFirstInstallmentPayment } from '../types'
import * as s from './styles'

const FirstInstallmentPayment: React.FC<IFirstInstallmentPayment> = ({
  content,
  paymentMethod: [paymentMethod, setPaymentMethod],
  isDrawer = false,
}: IFirstInstallmentPayment) => {
  const [cardRadioPayment] = React.useState<ICardRadioOptionPayment[]>([
    {
      ...content.checkboxDebit,
      name: 'debit',
      value: 'DEBITO_AUTOMATICO_EM_CONTA_CORRENTE',
      icon: 'calendar',
    },
    {
      ...content.checkboxBillet,
      name: 'billet',
      value: 'BOLETO_BANCARIO',
      icon: undefined,
    },
  ])

  const renderCardRadio = (
    card: ICardRadioOptionPayment,
    index: number,
    key: string
  ) => (
    <s.WrapperCardRadio
      onClick={() => setPaymentMethod(card.value)}
      key={key}
      active={paymentMethod === card.value && index === 0}
    >
      <s.RadioFirstInstallmentPayment
        id={`radio-first-installment-payment-${index}-id`}
        name={card.name}
        value=""
        checked={paymentMethod === card.value}
        handleChange={() => setPaymentMethod(card.value)}
      />
      {card.icon ? (
        <IconExporter
          name={card.icon}
          width={28}
          fill={
            paymentMethod === card.value
              ? theme.colors.blue4
              : theme.colors.gray4
          }
        />
      ) : null}
      <s.WrapperTitleTextRadio>
        <s.TitleTextRadio type="title" bold>
          {card.title}
        </s.TitleTextRadio>
        <s.TitleTextRadio type="caption">{card.subtitle}</s.TitleTextRadio>
      </s.WrapperTitleTextRadio>
    </s.WrapperCardRadio>
  )

  return (
    <s.WrapperFirstInstallmentPayment>
      <s.TitleText bold type="headline" isDrawer={isDrawer}>
        {content.subtitle}
      </s.TitleText>
      <s.ContainerRadioFirstInstallmentPayment isDrawer={isDrawer}>
        {cardRadioPayment.map((value: ICardRadioOptionPayment, index: number) =>
          renderCardRadio(value, index, index.toString())
        )}
      </s.ContainerRadioFirstInstallmentPayment>
    </s.WrapperFirstInstallmentPayment>
  )
}

export default FirstInstallmentPayment
