import styled from 'styled-components'

import {
  Container as ContainerCmp,
  Col as ColCmp,
  Row as RowCmp,
} from 'styled-bootstrap-grid'

import CardCmp from 'components/Card'
import TextCmp from 'components/Text'
import VerticalListCmp from 'components/VerticalList'
import ButtonCmp from 'components/Button'
import DividerCmp from 'components/Divisor'
import HorizontalListCmp from 'components/HorizontalList'
import ButtonTag from 'components/Button/Tag'

export const CardComponent = styled(CardCmp)`
  height: auto;
  padding: 0;
`

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
`

export const Title = styled(TextCmp).attrs(() => ({
  type: 'subheading',
  color: 'gray5',
  bold: true,
  textTransform: 'uppercase',
  textAlign: 'center',
}))`
  padding: ${({ theme }) => theme.spacing(2)}px;
`

export const ValueText = styled(TextCmp).attrs(() => ({
  type: 'heading3',
  color: 'gray6',
  textAlign: 'center',
}))`
  padding: ${({ theme }) => theme.spacing(2)}px;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const Text = styled(TextCmp)``

export const TaxSummaryText = styled(TextCmp).attrs(() => ({
  type: 'subheading',
  textTransform: 'uppercase',
  bold: true,
  color: 'gray5',
  textAlign: 'center',
}))`
  padding: 14px;
`



export const TagText = styled(TextCmp)``

export const Tag = styled(ButtonTag)`
  margin: 0 auto 14px;
`

export const Container = styled(ContainerCmp)``
export const Col = styled(ColCmp)``
export const Row = styled(RowCmp)`
  margin: 0;
`

export const VerticalList = styled(VerticalListCmp)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`

export const Button = styled(ButtonCmp)`
  margin: ${({ theme }) => theme.spacing(2)}px 0px;
  width: 100%;
`

export const Divider = styled(DividerCmp)`
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`

export const HorizontalList = styled(HorizontalListCmp)`
  height: ${props => `${props.theme.spacing(5)}px`};
`

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
`





export const SimulationInfoContainer = styled.div`
  display: flex;
`

export const SimulationInfoLabel = styled(TextCmp).attrs(() => ({
  type: 'captionUppercase',
  bold: true,
  color: 'gray5',
}))``

export const SimulationInfoValue = styled(TextCmp).attrs(() => ({
  type: 'headline',
  bold: true,
}))``

export const SimulationTaxLabel = styled(TextCmp).attrs(() => ({
  type: 'captionUppercase',
  textTransform: 'uppercase',
  color: 'gray5',
}))``

export const SimulationTaxValue = styled(TextCmp).attrs(() => ({
  type: 'body',
  color: 'gray6',
  bold: true,
}))``
