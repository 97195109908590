import React from 'react'
import { useSelector } from 'react-redux'

import { TSelected, TOptions } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { CardType } from 'pages/Consortium/SolicitationProcess/Types/CardType'

import { IFatca } from 'store/modules/Consortium/Fatca/types'
import { ApplicationState } from 'store/ApplicationState'
import { ICountries } from 'store/modules/Consortium/Countries/types'

import {
  validationErrorNIF,
  validationErrorPassportNumber,
  formatOptionText,
} from '../utils'
import { IFormFatcaData } from '../types'

import * as s from '../styles'

const FatcaForm: React.FC<CardType> = ({
  formData: [formData, setFormData],
}) => {
  const {
    formDefault,
    hire: {
      steps: {
        informationConfirmation: {
          containerThird: { label },
        },
      },
    },
  } = TextsConsortium()

  /**
   * USESELECTOR
   */
  const { fatcaConsortium } = useSelector((state: ApplicationState) => ({
    fatcaConsortium: state.consortiumFatca.data,
  }))

  const { passportCountriesConsortium } = useSelector(
    (state: ApplicationState) => ({
      passportCountriesConsortium: state.consortiumPassportCountries.data,
    })
  )

  const { countriesConsortium } = useSelector((state: ApplicationState) => ({
    countriesConsortium: state.consortiumCountries.data,
  }))

  /**
   * USESTATE
   */
  const [fieldsTouched, setfieldsTouched] = React.useState<IFormFatcaData>({
    countryResidence: false,
    documentNumberNIF: false,
    fatca: false,
    passportNumber: false,
    passportType: false,
    passportIssuingCountry: false,
  })

  const [isForeignResidency, setIsForeignResidency] = React.useState<boolean>(
    formData.fatca?.possui_residencia_fiscal || false
  )

  const [fatca, setFatca] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [fatcaOption, setFatcaOption] = React.useState<TOptions[]>([])

  const [countryResidence, setCountryResidence] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [countryResidenceOption, setCountryResidenceOption] = React.useState<
    TOptions[]
  >([])

  const [passportIssuingCountry, setCountryIssuancePassport] = React.useState<
    TSelected
  >({
    id: 0,
    name: '',
  })

  const [
    passportIssuingCountryOption,
    setCountryIssuancePassportOption,
  ] = React.useState<TOptions[]>([])

  const clearFatcaForm = () => {
    setfieldsTouched({
      ...fieldsTouched,
      fatca: false,
      passportNumber: false,
      passportIssuingCountry: false,
      countryResidence: false,
    })

    setFormData({
      ...formData,
      fatca: {
        fatca: '',
        numero_identificacao_fiscal: '',
        numero_passaporte: '',
        pais_passaporte: '',
        pais_residencia_fiscal: '',
        possui_residencia_fiscal: false,
      },
    })

    setCountryIssuancePassport({ id: 0, name: '' })
    setFatca({ id: 0, name: '' })
    setCountryResidence({ id: 0, name: '' })
  }

  /**
   * USECALLBACK
   */
  const updateStateForm = React.useCallback(
    (key: string, value: string | boolean) => {
      setFormData({
        ...formData,
        fatca: {
          ...formData.fatca,
          [key]: value,
        },
      })
    },
    [formData, setFormData]
  )

  const updatefieldTouched = React.useCallback(
    (key: string, value: boolean) => {
      setfieldsTouched({
        ...fieldsTouched,
        [key]: value,
      })
    },
    [fieldsTouched, setfieldsTouched]
  )

  const setSelectOptions = React.useCallback(
    (
      setCountry: React.Dispatch<React.SetStateAction<TOptions[]>>,
      key: string,
      setCountrySelect: React.Dispatch<React.SetStateAction<TSelected>>,
      data: ICountries[] | IFatca[]
    ) => {
      setCountry(
        data.map(value => ({
          id: value.codigo,
          text: formatOptionText(value.descricao),
          onClick: () => {
            setCountrySelect({
              id: value.codigo,
              name: formatOptionText(value.descricao),
            })
            updateStateForm(key, value.codigo)
          },
        }))
      )
    },
    [updateStateForm]
  )

  /**
   * USEFFECT
   */
  React.useEffect(() => {
    if (countriesConsortium && countriesConsortium.length > 0) {
      setSelectOptions(
        setCountryResidenceOption,
        'pais_residencia_fiscal',
        setCountryResidence,
        countriesConsortium
      )
    }
  }, [countriesConsortium, passportCountriesConsortium, setSelectOptions])

  React.useEffect(() => {
    if (passportCountriesConsortium && passportCountriesConsortium.length > 0) {
      setSelectOptions(
        setCountryIssuancePassportOption,
        'pais_passaporte',
        setCountryIssuancePassport,
        passportCountriesConsortium
      )
    }
  }, [countriesConsortium, passportCountriesConsortium, setSelectOptions])

  React.useEffect(() => {
    if (fatcaConsortium && fatcaConsortium.length > 0) {
      setSelectOptions(setFatcaOption, 'fatca', setFatca, fatcaConsortium)
    }
  }, [fatcaConsortium, setSelectOptions, setFatcaOption, setFatca])

  return (
    <s.RadioDocumentContainer>
      <s.LabelRadioDocument>{label.foreignResidence}</s.LabelRadioDocument>
      <s.RadioOptionsDocumentWrapper data-testid="radio-brazilian-resident">
        <s.RadioDocument
          options={[
            {
              value: '1',
              name: 'Sim',
            },
            {
              value: '0',
              name: 'Não',
            },
          ]}
          checkedItem={isForeignResidency ? '1' : '0'}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setIsForeignResidency(e.currentTarget.value === '1')

            updateStateForm(
              'possui_residencia_fiscal',
              e.currentTarget.value === '1'
            )

            if (e.currentTarget.value === '0') {
              clearFatcaForm()
            }
          }}
        />
      </s.RadioOptionsDocumentWrapper>
      {isForeignResidency ? (
        <s.WrapperForeignResidenceForm>
          <s.RowForeignResidenceDocument>
            <s.ColForeignResidenceDocument sm={12}>
              <s.Select
                data-testid="select-country-id"
                label={label.countryResidence}
                options={countryResidenceOption}
                selected={countryResidence}
                optionBoxSize={220}
              />
            </s.ColForeignResidenceDocument>
            <s.ColForeignResidenceDocument sm={7}>
              <s.InputForeignResidenceDocument
                label={label.documentNumberNIF}
                data-testid="input-document-number-nif-id"
                placeholder={formDefault.placeholderInput}
                value={formData.fatca?.numero_identificacao_fiscal}
                onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                  updatefieldTouched(
                    'documentNumberNIF',
                    e.currentTarget.value !== ''
                  )
                }
                error={validationErrorNIF(
                  formData.fatca?.numero_identificacao_fiscal,
                  fieldsTouched.documentNumberNIF
                )}
                maxLength={25}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  updateStateForm(
                    'numero_identificacao_fiscal',
                    e.currentTarget.value.toUpperCase()
                  )
                }}
              />
            </s.ColForeignResidenceDocument>
            <s.ColForeignResidenceDocument sm={5}>
              <s.SelectForeignResidenceDocument
                data-testid="select-fatca-id"
                label={label.fatca}
                options={fatcaOption}
                selected={fatca}
                optionBoxSize={220}
              />
            </s.ColForeignResidenceDocument>
            <s.ColForeignResidenceDocument sm={12}>
              <s.InputForeignResidenceDocument
                label={label.passportNumber}
                data-testid="input-passport-number-id"
                placeholder={formDefault.placeholderInput}
                value={formData.fatca?.numero_passaporte}
                onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                  updatefieldTouched(
                    'passportNumber',
                    e.currentTarget.value !== ''
                  )
                }
                maxLength={6}
                error={validationErrorPassportNumber(
                  formData.fatca?.numero_passaporte,
                  fieldsTouched.passportNumber
                )}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  updateStateForm(
                    'numero_passaporte',
                    e.currentTarget.value.replace(/\D/g, '')
                  )
                }}
              />
            </s.ColForeignResidenceDocument>

            <s.ColForeignResidenceDocument sm={12}>
              <s.SelectCountryIssuancePassportDocument
                data-testid="select-country-issuance-passport-id"
                label={label.passportIssuingCountry}
                options={passportIssuingCountryOption}
                selected={passportIssuingCountry}
                optionBoxSize={220}
              />
            </s.ColForeignResidenceDocument>
          </s.RowForeignResidenceDocument>
        </s.WrapperForeignResidenceForm>
      ) : null}
    </s.RadioDocumentContainer>
  )
}

export default FatcaForm
