import React from 'react'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import * as s from '../../styles'
import { CardDataType } from '../../types'

const ContactData: React.FC<CardDataType> = ({
  proposalData: [proposalData, setProposalData],
}) => {
  const { hire } = TextsConsortium()

  let phoneNumber = ''

  if (proposalData.proponente?.telefone) {
    const apiPhone = proposalData.proponente?.telefone?.numero

    if (proposalData.proponente?.telefone?.tipo === 'CELULAR') {
      phoneNumber = `${apiPhone?.slice(0, 1)} ${apiPhone?.slice(
        1,
        5
      )}-${apiPhone?.slice(-4)}`
    } else {
      phoneNumber = `${apiPhone?.slice(0, 4)}-${apiPhone?.slice(-4)}`
    }
  }

  const customerPhone = `${proposalData.proponente?.telefone?.ddi} (${proposalData.proponente?.telefone?.ddd}) ${phoneNumber}`

  return (
    <s.cardStructure>
      <s.cardHeader>
        <s.cardHeaderTitle>
          {hire.steps.summary.pro.containerSecond.header.title}
        </s.cardHeaderTitle>
        <s.ButtonLink noIcon>
          {hire.steps.summary.pro.containerSecond.header.button}
          <s.Icon name="arrowright" color="blue4" />
        </s.ButtonLink>
      </s.cardHeader>
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerSecond.content.email}
          value={proposalData.proponente?.email}
          error={!proposalData.proponente?.email}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerSecond.content.cellphone}
          value={customerPhone}
          error={customerPhone === ''}
        />
      </s.cardContent>
    </s.cardStructure>
  )
}

export default ContactData
