import React from 'react'
import { useSelector } from 'react-redux'

import { TOptions, TSelected } from 'components/Inputs/Select/types'

import { ApplicationState } from 'store/ApplicationState'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { formatMoney } from 'helpers'
import { getDataBank } from './utils'
import { IPaymentData } from '../types'

import * as s from './styles'

const PaymentData: React.FC<IPaymentData> = ({
  paymentData: [paymentData, setPaymentData],
  content,
  amountOtherInstallments,
  isDrawer = false,
}: IPaymentData) => {
  const { formDefault } = TextsConsortium()

  const { data: bankConsortiumData } = useSelector(
    (state: ApplicationState) => state.consortiumBank
  )

  const [bankSelect, setBankSelect] = React.useState<TSelected>({
    id: 0,
    name: paymentData.bank || '',
  })

  const [banksOption, setBankOption] = React.useState<TOptions[]>([])

  React.useEffect(() => {
    const bankOption = getDataBank(
      bankConsortiumData,
      setBankSelect,
      (bank: string, code: string) => {
        if (bank) {
          setPaymentData({
            ...paymentData,
            bank,
            code,
          })
        }
      }
    )

    if (bankOption) {
      setBankOption(bankOption)
    }
  }, [bankConsortiumData, paymentData, setPaymentData])

  return (
    <s.WrapperPaymentData isDrawer={isDrawer}>
      <s.TitleText bold type="headline">
        {content.subtitle} {formatMoney(amountOtherInstallments)}
      </s.TitleText>
      <s.RowPaymentData isDrawer={isDrawer}>
        <s.ColPaymentData col={12}>
          <s.SelectPaymentData
            data-testid="select-bank-id"
            label={content.label.bank}
            options={banksOption}
            selected={bankSelect}
            optionBoxSize={300}
          />
        </s.ColPaymentData>
        <s.InputsContainer isDrawer={isDrawer}>
          <s.ColPaymentData sm={isDrawer ? 12 : 5}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.agency}
              value={paymentData.agency}
              placeholder={formDefault.placeholderInput}
              maxLength={6}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setPaymentData({
                  ...paymentData,
                  agency: e.currentTarget.value.replace('-', ''),
                })
              }}
            />
          </s.ColPaymentData>
          <s.ColPaymentData sm={isDrawer ? 12 : 5}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.currentAccount}
              value={paymentData.currentAccount}
              maxLength={9}
              placeholder={formDefault.placeholderInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setPaymentData({
                  ...paymentData,
                  currentAccount: e.currentTarget.value,
                })
              }}
            />
          </s.ColPaymentData>
          <s.ColPaymentData sm={isDrawer ? 12 : 2}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.digit}
              value={paymentData.digit}
              maxLength={1}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setPaymentData({
                  ...paymentData,
                  digit: e.currentTarget.value,
                })
              }}
            />
          </s.ColPaymentData>
        </s.InputsContainer>
      </s.RowPaymentData>
    </s.WrapperPaymentData>
  )
}

export default PaymentData
