import React from 'react'
import { useSelector } from 'react-redux'

import Divisor from 'components/Divisor'
import Modal from 'components/Modal/ModalNew'
import { TSelected } from 'components/Inputs/Select/types'

import useMedia from 'hooks/useMedia'

import { ApplicationState } from 'store/ApplicationState'

import DocumentForm from './DocumentForm'
import FatcaForm from './FatcaForm'
import { IDocumentForm } from './types'

import { isInvalidForm, resetForm } from './utils'
import { DrawerType } from '../../types'
import * as s from './styles'

const DocumentsDrawer: React.FC<DrawerType> = ({
  isOpen,
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
  handleClose,
}: DrawerType) => {
  const isMobile = useMedia('sm')

  const [documentForm, setDocumentForm] = React.useState<IDocumentForm>({
    documentType: proposalData.proponente.documento.codigo,
    documentNumber: proposalData.proponente.documento.numero,
    dateDispatch: proposalData.proponente.documento.dt_expedicao,
    expeditedBody: proposalData.proponente.documento.orgao_emissor,
    documentNumberNIF:
      proposalData.proponente.fatca?.numero_identificacao_fiscal || '',
    isForeignResidency:
      proposalData.proponente.fatca?.possui_residencia_fiscal || false,
    fatca: proposalData.proponente.fatca?.fatca || '',
    foreignResidence:
      proposalData.proponente.fatca?.pais_residencia_fiscal || '',
    passportNumber: proposalData.proponente.fatca?.numero_passaporte || '',
    passportIssuingCountry:
      proposalData.proponente.fatca?.pais_passaporte || '',
  })

  const [isForeignResidency, setIsForeignResidency] = React.useState<boolean>(
    proposalData.proponente.fatca?.possui_residencia_fiscal || false
  )

  const [countryResidence, setCountryResidence] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [passportIssuingCountry, setCountryIssuancePassport] = React.useState<
    TSelected
  >({
    id: 0,
    name: '',
  })

  const [fatca, setFatca] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [documentTypeSelect, setDocumentTypeSelect] = React.useState<TSelected>(
    {
      id: 0,
      name: '',
    }
  )

  /**
   * USESELECTOR
   */
  const { fatcaConsortium } = useSelector((state: ApplicationState) => ({
    fatcaConsortium: state.consortiumFatca.data,
  }))

  const { passportCountriesConsortium } = useSelector(
    (state: ApplicationState) => ({
      passportCountriesConsortium: state.consortiumPassportCountries.data,
    })
  )

  const { countriesConsortium } = useSelector((state: ApplicationState) => ({
    countriesConsortium: state.consortiumCountries.data,
  }))

  const handleClick = React.useCallback(() => {
    setProposalData({
      ...proposalData,
      proponente: {
        ...proposalData.proponente,
        documento: {
          codigo: documentForm.documentType,
          numero: documentForm.documentNumber,
          dt_expedicao: documentForm.dateDispatch,
          orgao_emissor: documentForm.expeditedBody,
        },
        fatca: {
          fatca: documentForm.fatca,
          numero_identificacao_fiscal: documentForm.documentNumberNIF,
          numero_passaporte: documentForm.passportNumber,
          pais_passaporte: documentForm.passportIssuingCountry,
          pais_residencia_fiscal: documentForm.foreignResidence,
          possui_residencia_fiscal: documentForm.isForeignResidency,
        },
      },
    })

    setIsValidForm({
      ...isValidForm,
      documentForm: true,
    })

    handleClose()
  }, [
    setIsValidForm,
    proposalData,
    documentForm,
    handleClose,
    isValidForm,
    setProposalData,
  ])

  const renderContent = () => (
    <>
      <DocumentForm
        formData={[documentForm, setDocumentForm]}
        documentTypeSelect={[documentTypeSelect, setDocumentTypeSelect]}
      />

      <Divisor />

      <FatcaForm
        formData={[documentForm, setDocumentForm]}
        isForeignResidency={[isForeignResidency, setIsForeignResidency]}
        countryResidence={[countryResidence, setCountryResidence]}
        passportIssuingCountry={[
          passportIssuingCountry,
          setCountryIssuancePassport,
        ]}
        fatca={[fatca, setFatca]}
      />
    </>
  )

  if (isMobile) {
    return (
      <Modal
        modalTitle="Alterar documentos"
        isOpen={isOpen}
        handleClose={() => {
          handleClose()
        }}
        footer={
          <s.Button
            onClick={handleClick}
            disabled={isInvalidForm(documentForm)}
          >
            Salvar alterações
          </s.Button>
        }
      >
        {renderContent()}
      </Modal>
    )
  }
  return (
    <s.Drawer
      isOpen={isOpen}
      drawerTitle="Alterar documentos"
      handleClose={() => {
        resetForm(
          proposalData,
          countriesConsortium,
          passportCountriesConsortium,
          fatcaConsortium,
          setDocumentForm,
          setIsForeignResidency,
          setCountryResidence,
          setCountryIssuancePassport,
          setFatca,
          setDocumentTypeSelect
        )
        handleClose()
      }}
      footer={
        <s.Button onClick={handleClick} disabled={isInvalidForm(documentForm)}>
          Salvar alterações
        </s.Button>
      }
    >
      {renderContent()}
    </s.Drawer>
  )
}

export default DocumentsDrawer
