/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react'
import { Divisor } from 'components'
import DataRow from 'pages/Consortium/commons/DataRow'
import { SummarySectionProps } from './models'
import * as S from './styles'

const SummarySection: React.FC<SummarySectionProps> = ({
  title,
  items,
  button,
}: SummarySectionProps) => (
  <S.Container data-testid="summary-section">
    <S.ContainerContent>
      <S.ContainerTitle>
        <S.Title>{title}</S.Title>
        {button || ''}
      </S.ContainerTitle>
      <div data-testid="container-data-rows">
        {items.map((item, i) => (
          <div key={i}>
            {i !== 0 && <Divisor />}
            <DataRow label={item.label} value={item.value} />
          </div>
        ))}
      </div>
    </S.ContainerContent>
  </S.Container>
)

export default memo(SummarySection)
