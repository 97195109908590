import styled from 'styled-components'

import SelectCmp from 'components/Inputs/AutoCompleteSelect'
import { OptionsWrapper } from 'components/Inputs/NewSelect/styles'

export const Select = styled(SelectCmp)`
  ${OptionsWrapper} {
    top: ${({ theme }) => theme.spacing(10)}px;
  }
`
