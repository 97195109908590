import React from 'react'
import { brazilianStates } from './types'
import { TSelected } from 'components/Inputs/Select/types'

export const statusOption = (
  setState: React.Dispatch<React.SetStateAction<TSelected>>
) =>
  brazilianStates.map(value => ({
    id: value,
    text: value,
    onClick: () => {
      setState({ id: value, name: value })
    },
  }))
