import React from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import Layer from 'components/Layer'
import Modal from 'components/Modal/ModalNew'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import * as s from './styles'

interface WrapperType {
  icon: any
  isLoading?: boolean
}

const Wrapper: React.FC<WrapperType> = ({ icon, children, isLoading }) => {
  const { modalFlowExit } = TextsConsortium()
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const history = useHistory()

  const modalFooterButton = () => (
    <s.ButtonWrapper>
      <s.Button
        data-gtm-type="click"
        data-gtm-clicktype="button"
        data-gtm-name="sair"
        outline
        onClick={() => history.push('/')}
      >
        {modalFlowExit.button.exit}
      </s.Button>
      <s.Button
        data-gtm-type="click"
        data-gtm-clicktype="button"
        data-gtm-name="continuar solicitação"
        onClick={() => setIsModalOpen(false)}
      >
        {modalFlowExit.button.continue}
      </s.Button>
    </s.ButtonWrapper>
  )

  return (
    <>
      <Modal
        modalTitle={modalFlowExit.title}
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <s.Text type="headline" color="gray6">
          {modalFlowExit.content}
        </s.Text>
        {modalFooterButton()}
      </Modal>
      <Layer
        isOpen
        handleClose={() => setIsModalOpen(true)}
        data-testid="consortium-layer"
        headerContent={
          <Container>
            <Row>
              <Col lg={12}>
                <s.HeaderContent>
                  <s.LogoWrapper>{icon}</s.LogoWrapper>

                  {!isLoading && (
                    <s.CloseIcon
                      className="layer-close-button"
                      data-gtm-subname="botao fechar layer"
                      data-gtm-name="botao fechar"
                      data-gtm-type="click"
                      data-gtm-clicktype="button"
                      onClick={() => setIsModalOpen(true)}
                    />
                  )}
                </s.HeaderContent>
              </Col>
            </Row>
          </Container>
        }
      >
        {children}
      </Layer>
    </>
  )
}

export default Wrapper
