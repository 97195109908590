import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { themeColors } from 'styles/theme'
import IconExporter, { dict } from 'components/IconExporter'
import { icon, message, button } from './static'
import { IProps } from './types'
import * as S from './styles'

const Status: React.FC<IProps> = ({ status }: IProps) => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/')
  }

  return (
    <S.Card>
      <S.Container>
        <S.FlexibleLayerContent data-testid="proposalResolutionSuccess">
          <S.ContainerIcon>
            <IconExporter
              name={icon.name[status] as keyof typeof dict}
              color={icon.color[status] as keyof typeof themeColors}
              width="28px"
            />
          </S.ContainerIcon>
          <S.Message data-testid="status-message">
            <S.Title type="headline" bold color="gray6">
              {message[status].title}
            </S.Title>
            <S.Subtitle type="body" color="gray5">
              {message[status].subtitle}
            </S.Subtitle>
          </S.Message>
        </S.FlexibleLayerContent>
      </S.Container>
      <S.Divisor />
      <S.Button onClick={handleClick}>{button.title}</S.Button>
    </S.Card>
  )
}

export default memo(Status)
