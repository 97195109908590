import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Switch,
  Route as ReactRouterDomRoute,
  Redirect,
  useHistory,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import 'styled-components/macro'

import Consortium from 'pages/Consortium'
import PartnerOfferPRO from 'pages/Consortium/SolicitationProcess/PRO/PartnerOffer'
import ConsortiumPROSummary from 'pages/Consortium/SolicitationProcess/PRO/SummaryProposal'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Loading from 'components/Loading'
import Login from 'pages/Login'
import googleTagManagerHandler from 'GoogleTagManager'
import useAuth from './useAuth'

import { LoadingContainer } from './styles'
import { GetPointsExtractRequest } from 'store/modules/Points/action'
import { GetTagRequest } from 'store/modules/TagManager/action'

const DefaultRoute = ({
  component: Component,
  computedMatch: match,
  data,
  hook,
  name,
  pageTitle,
  isFinalComponent,
  ...rest
}) => {
  let dataLength = 0

  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const points = useSelector(
    state => state.points.data && state.points.data.detalheExtratoOptemaisTypes
  )

  useEffect(() => {
    dispatch(GetPointsExtractRequest(auth.cpf))
    dispatch(GetTagRequest())

    if (isFinalComponent && points) {
      googleTagManagerHandler(
        history.location.pathname,
        pageTitle,
        auth,
        points
      )
    }
  }, [dispatch, auth, points, isFinalComponent, history, pageTitle])

  try {
    if (!data) {
      hook()

      // while the hook is being called, dont render anything (eliminates blink between screens)
      return (
        <LoadingContainer>
          <Loading type="bar" />
        </LoadingContainer>
      )
    }

    dataLength = Array.isArray(data) ? data.length : Object.entries(data).length
  } catch (error) {
    dataLength = 0
  }

  const crumbs = match.path
    .split('/')
    .slice(1)
    .map(item => {
      if (Object.keys(match.params).includes(item.replace(':', ''))) {
        return name || pageTitle
      }
      if (item === 'lista-fundos') {
        return 'lista de fundos'
      }
      return item
    })

  return (
    <>
      <Header crumbs={crumbs} />
      <main className="main-content">
        <Route
          {...rest}
          computedMatch={match}
          pageTitle={pageTitle}
          isFinalComponent={Boolean(!dataLength)}
          render={props => {
            // if dataLength !== 0, data exists therefore redirects to management screen
            if (dataLength) {
              return <Redirect to={{ pathname: `${rest.path}/visualizar` }} />
            }
            // otherwise, renders the component
            return <Component {...props} />
          }}
        />
      </main>
      <Footer />
    </>
  )
}

DefaultRoute.propTypes = {
  component: PropTypes.func.isRequired,
  computedMatch: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.object,
  }),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hook: PropTypes.func,
  name: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
}

DefaultRoute.defaultProps = {
  computedMatch: {},
  data: [],
  hook: () => {},
  name: '',
}

const Route = ({ path, component, pageTitle, isFinalComponent, ...rest }) => {
  const history = useHistory()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const points = useSelector(
    state => state.points.data && state.points.data.detalheExtratoOptemaisTypes
  )

  const [currentPage, setCurrentPage] = React.useState('')

  useEffect(() => {
    const pathName = history.location.pathname
    const splitName = pathName.split('/')
    let title = pageTitle

    if (pathName.includes('objetivos') && splitName.length > 2) {
      title = `${pageTitle} - ${splitName.slice(-1)}`
    }

    if (isFinalComponent && currentPage !== history.location.pathname) {
      setCurrentPage(history.location.pathname)
      googleTagManagerHandler(
        history.location.pathname,
        title,
        auth,
        points,
        dispatch
      )
    }
  }, [
    history.location.pathname,
    pageTitle,
    auth,
    points,
    isFinalComponent,
    currentPage,
    dispatch,
  ])

  return (
    <ReactRouterDomRoute
      {...rest}
      path={path}
      exact={rest.exact}
      component={component}
      pageTitle={pageTitle}
    />
  )
}

Route.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func,
  pageTitle: PropTypes.string.isRequired,
  isFinalComponent: PropTypes.bool,
}

Route.defaultProps = {
  component: null,
  isFinalComponent: true,
}

export default function Routes({ ...props }) {
  const { digitalSign } = useSelector(state => ({
    digitalSign: state.auth.digitalSign,
  }))

  const isAuth = useAuth()

  const digitalSignIsValid = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return digitalSign && Object.keys(digitalSign).length > 0
    }
    return true
  }

  const redirectCustomerProfile = () => {
    window.open(
      'https://cliente.portoseguro.com.br/portaldecliente/conquista/termo-aceite',
      '_self'
    )
    return <Login />
  }

  const validateDigitalSign = () =>
    digitalSignIsValid() ? (
      <Switch {...props}>
        <DefaultRoute
          path="/"
          exact
          component={Consortium}
          pageTitle="Consortium"
          isFinalComponent
        />
        <Route
          path="/consorcio-veiculo/pro/:hash"
          component={PartnerOfferPRO} // Parceiro PRO
          pageTitle="Home - Consorcio de veículo - PRO"
          isFinalComponent
        />
        <Route
          path="/consorcio-veiculo/proposta/:redisKey"
          exact
          component={ConsortiumPROSummary} // Cliente PRO
          pageTitle="Proposta - Consorcio de veículo"
          isFinalComponent
        />
      </Switch>
    ) : (
      redirectCustomerProfile()
    )

  return !isAuth ? <Login /> : validateDigitalSign()
}
