import React, { memo } from 'react'
import { ISummaryItem } from './types'
import * as S from './styles'

const SummaryItem: React.FC<ISummaryItem> = ({
  label,
  value,
}: ISummaryItem) => (
  <>
    <S.Divisor />
    <S.Container data-testid="summary-item">
      <S.Label type="captionUppercase" color="gray5">
        {label}
      </S.Label>
      <S.Value type="headline" color="gray6">
        {value}
      </S.Value>
    </S.Container>
  </>
)

export default memo(SummaryItem)
