export const Types = {
  GET_TAG_REQUEST: 'GET_TAG_REQUEST',
  GET_TAG_SUCCESS: 'GET_TAG_SUCCESS',
  GET_TAG_FAILURE: 'GET_TAG_FAILURE',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
}

export interface IAction {
  type: keyof typeof Types
  payload: IClient
}

export interface IClient {
  id_pessoa: number
  cpf: string
  nome: string
  ddd: number
  ddi: number
  numero_telefone_celular: string
  email: string
  data_nascimento: string
  cep_residencial: number
  numero_endereco_residencial: string
  perfil_investidor: string
  id_bcp: number
}

interface IPage {
  name: string
}

interface ISite {
  portal: string
  versao: string
  brand: string
}

export interface IUser {
  id: string
  user_id: string
  conquita_id: string
  cotista_id: string
  points: string
  suitability: string
  tipo_investidor: string
  products: string[]
  logged: string
  loginType: string
}

export interface ICustomData {
  page: IPage
  site: ISite
  user: IUser
}

interface ITagData {
  customerInfo: IClient | null
  tagData: ICustomData | null
}

export interface ITagState {
  readonly data: ITagData | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
