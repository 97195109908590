import styled, { css } from 'styled-components'
import { Row, Col, Container } from 'styled-bootstrap-grid'

import Text from 'components/Text'
import Button from 'components/Button'
import MoneyInput from 'components/Inputs/Money'

const placeholderColor = css`
  & #prefix {
    color: ${({ theme }) => theme.colors.gray6};
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height: calc(100% - 56px);
  height: auto;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
`

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ErrorText = styled(Text).attrs(() => ({
  color: 'magenta2',
  type: 'headline',
  textAlign: 'center',
}))`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 418px;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    margin: ${({ theme }) => theme.spacing(2)}px;
  }

  ${({ theme }) =>
    `@media ${theme.mediaQueries.smUp} and ${theme.mediaQueries.md}`} {
    margin: ${({ theme }) => theme.spacing(20)}px;
  }
`

export const QuestionText = styled(Text)`
  margin: ${({ theme }) => theme.spacing(2)}px 0
    ${({ theme }) => theme.spacing(5)}px;
  font-weight: 400;
`

export const InputMoney = styled(MoneyInput)`
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  margin-bottom: ${({ error, theme }) => !!error && theme.spacing(10)}px;

  ${({ value }) => !!value && placeholderColor}
`

export const StyledButton = styled(Button).attrs(() => ({
  color: 'secondary',
}))`
  width: 100%;
`

export const StyledRow = styled(Row)`
  height: 100%;
`

export const StyledCol = styled(Col)`
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;

  @media ${props => props.theme.mediaQueries.xs} {
    padding: ${({ theme }) => theme.spacing(3)}px;
  }
`

export const StyledContainer = styled(Container)`
  height: 100%;
`
