import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Icon, { dict } from 'components/IconExporter'

import { ErrorForm } from 'pages/Consortium/commons/ErrorForm'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { ApplicationState } from 'store/ApplicationState'
import { GetStatesRequest } from 'store/modules/Consortium/States/action'

import { capitalize } from 'helpers'

import * as s from './styles'
import * as t from './types'

const ChooseState: React.FC<t.ChooseStateInterface> = ({
  statesState,
  selectedStateState,
  ufState,
}) => {
  const dispatch = useDispatch()
  const { pro } = TextsConsortium()
  const [states, setStates] = statesState
  const [, setUf] = ufState
  const [selectedState, setSelectedState] = selectedStateState

  const { data, isLoading, hasError } = useSelector(
    (state: ApplicationState) => state.consortiumStates
  )

  React.useEffect(() => {
    if (!data) {
      dispatch(GetStatesRequest())
    } else {
      const statesOptions = data.ufs.map((state: t.IState, index: number) => ({
        id: index + 1,
        text: state.descricao
          .split(' ')
          .map(value =>
            value.length > 2 ? capitalize(value) : value.toLowerCase()
          )
          .join(' '),
        onClick: () => {
          setSelectedState({
            id: index + 1,
            name: state.descricao
              .split(' ')
              .map(value =>
                value.length > 2 ? capitalize(value) : value.toLowerCase()
              )
              .join(' '),
          })
          setUf(state.codigo)
        },
      }))
      setStates(statesOptions)
    }
  }, [data, dispatch, setSelectedState, setStates, setUf])

  const selectHasError = ():
    | boolean
    | { icon: keyof typeof dict; message: string } => {
    if (states?.length === 0 && hasError && !isLoading) {
      const { errorReloadData } = ErrorForm()
      return errorReloadData
    }

    return false
  }

  return (
    <s.Select
      testId="update-simulation-select"
      options={states}
      selected={selectedState}
      label={pro.simulation.stateOption}
      isLoading={isLoading}
      optionBoxSize={220}
      error={selectHasError()}
      suffix={
        hasError &&
        !isLoading && (
          <Icon name="reload" color="blue4" width={21} height={21} />
        )
      }
    />
  )
}

export default ChooseState
