import { IToast } from 'hooks/toast'
import { Types, IIncomeValidateRequest, IIncomeValidateResponse } from './types'

export function IncomeValidateRequest({
  payload,
  onErrorToast,
  onSuccess,
}: {
  payload: IIncomeValidateRequest
  onErrorToast: (toast: Omit<IToast, 'id'>) => void
  onSuccess: () => void
}) {
  return {
    type: Types.POST_INCOME_VALIDATE_REQUEST,
    payload,
    onErrorToast,
    onSuccess,
  }
}

export function PostIncomeValidatesuccess(payload: IIncomeValidateResponse) {
  return {
    type: Types.POST_INCOME_VALIDATE_SUCCESS,
    payload,
  }
}

export function PostIncomeValidateFailure() {
  return {
    type: Types.POST_INCOME_VALIDATE_FAILURE,
  }
}
