import * as t from './types'

export const document: t.IDocumentInformationClientConsortium = {
  codigo: '',
  numero: '',
  orgao_emissor: '',
  dt_expedicao: '',
}

export const pep: t.IPepInformationClientConsortium = {
  tipo: '',
  grau_pep: '',
  nome: '',
  cpf: '',
}

export const address: t.IAddressInformationClientConsortium = {
  cep: '',
  logradouro: '',
  numero: '',
  bairro: '',
  cidade: '',
  complemento: '',
  uf: '',
  endereco_principal: false,
}

export const fatca: t.IFatcaInformationClientConsortium = {
  possui_residencia_fiscal: false,
  fatca: '',
  pais_residencia_fiscal: '',
  numero_identificacao_fiscal: '',
  numero_passaporte: '',
  pais_passaporte: '',
}

export const profession: t.IProfessionDataInformationClientConsortium = {
  codigo_profissao: '',
  nome_empresa: '',
  codigo_ramo_atividade: '',
  enderecoComercial: address,
  autonomo: '',
  codigo_ccm: '',
  numero_inscricao_estadual: '',
  dt_inicio_atividade: '',
  valor_renda_mensal: 0,
  valor_patrimonio: 0,
  possui_outras_rendas: true,
  outra_profissao: '',
  outro_ramo_atividade: '',
  descricao_complemento_renda: '',
  valor_mensal_outras_rendas: 0,
  codigo_procedencia: 0,
  codigo_tipo_aposentadoria: '',
  descricao_tipo_aposentadoria: '',
}

export const bank: t.IBankDataConsortium = {
  codigo_banco: '',
  nome_banco: '',
  numero_agencia: '',
  numero_conta: '',
  digito_conta: '',
  digito_agencia: '',
}

export const INITIAL_DATA: t.IClientInformationConsortium = {
  nome: '',
  cpf: '',
  dt_nascimento: '',
  codigo_nacionalidade: '',
  codigo_pais_origem: '',
  flag_estrangeiro: false,
  nome_mae: '',
  cidade_local_nascimento: '',
  codigo_estado_civil: '',
  nome_conjuge: '',
  cpf_conjuge: '',
  email: '',
  observacao_declaracao_seguro_vida: '',
  documento: document,
  pep: pep,
  endereco_residencial: address,
  telefone: '',
  fatca: fatca,
  dados_profissionais: profession,
  dados_bancarios: bank,
}
