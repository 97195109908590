import styled from 'styled-components'
import Text, { IProps as TextProps } from 'components/Text'

export const Container = styled.div`
  --card-width: 297px;
  --card-height: 75px;
  --card-padding: ${({ theme }) => theme.spacing(2)}px;
  --card-border-width: 1px;

  width: var(--card-width);
  height: var(--card-height);
  display: flex;
  border: var(--card-border-width) solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
  padding: var(--card-padding);
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
`

export const ContainerText = styled.div`
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`

export const Name = styled(Text)`
  --avatar-width: calc(
    var(--card-height) - 2 * var(--card-border-width) - 2 * var(--card-padding)
  );
  --name-width: calc(
    var(--card-width) - 2 * var(--card-border-width) - 2 * var(--card-padding) -
      var(--avatar-width) - ${({ theme }) => theme.spacing(2)}px
  );

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: var(--name-width);
  font-weight: 400;
`

export const NameProps: TextProps = {
  type: 'headline',
  bold: true,
  color: 'gray6',
}

export const Type = styled(Text)`
  font-weight: 400;
`

export const TypeProps: TextProps = {
  type: 'caption',
  color: 'gray5',
}
