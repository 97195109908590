import { AxiosResponse } from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'

import { post, baseURLNamespaceConsortium } from 'services/api'
import { IClientInformationConsortium } from 'store/modules/Consortium/ClientInformation/types'

import Conflict from 'services/exceptions/ConflictExcpetion'

import { Types, IPayload } from './types'
import {
  ClientInformationPROSuccess,
  ClientInformationPROFailure,
  ClientInformationPROInvalid,
} from './action'

export function* postClientInformation({ payload }: IPayload) {
  try {
    const { data }: AxiosResponse<IClientInformationConsortium> = yield call(
      post,
      `${baseURLNamespaceConsortium}v2/consorcios/clientes/pro/dados`,
      payload
    )

    yield put(ClientInformationPROSuccess(data))
  } catch (err) {
    if (err instanceof Conflict) {
      yield put(ClientInformationPROInvalid())
    }
    yield put(ClientInformationPROFailure())
  }
}

export default all([
  takeLatest(Types.POST_CLIENT_INFORMATION_REQUEST, postClientInformation),
])
