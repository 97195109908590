import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Currency } from 'helpers/money'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { toggleButtonPlanCard } from 'store/modules/Consortium/PlanCard/action'
import { ApplicationState } from 'store/ApplicationState'
import * as s from './styles'

interface IPlanCardProps {
  creditValue?: number
  groupState?: 'inFormation' | 'inProgress'
  monthlyValue: number
  numberInstallment: number
  groupCode?: string
  quota?: number
  reserveFund: number
  administrativeFee: number
  lifeInsurance: number
  handlerClick: (isLoadingButtonHire: boolean) => void
  isLoading?: boolean
}

const PlanCard = ({
  creditValue,
  groupState,
  monthlyValue,
  numberInstallment,
  groupCode,
  quota,
  reserveFund,
  administrativeFee,
  lifeInsurance,
  handlerClick,
  isLoading,
  ...rest
}: IPlanCardProps) => {
  const [isLoadingButtonHire, setIsLoadingButtonHire] = React.useState<boolean>(
    false
  )
  const dispatch = useDispatch()

  const { isButtonEnabled } = useSelector(
    (state: ApplicationState) => state.consortiumButtonPlanCard
  )

  const { buttons, creation } = TextsConsortium()

  const group = useCallback(() => {
    const types: { [index: string]: string } = {
      inFormation:
        creation.steps.simulationResults.cards.buttonOptionGroup.inFormation,
      inProgress:
        creation.steps.simulationResults.cards.buttonOptionGroup.inProgress,
    }
    return types[groupState || '']
  }, [creation, groupState])

  const handleIsLoadingButtonHire = () => {
    isButtonEnabled && setIsLoadingButtonHire(true)
    isButtonEnabled && dispatch(toggleButtonPlanCard(false))
  }

  return (
    <s.CardComponent {...rest}>
      <s.Container>
        <s.Row>
          <s.InfoWrapper>
            <s.TextCardTitle
              type="subheading"
              textTransform="uppercase"
              color="gray5"
              bold
            >
              {creation.steps.simulationResults.cards.title}
            </s.TextCardTitle>
            <s.Text type="heading3">
              {Currency.parse(creditValue || 0, true)}
            </s.Text>
            <s.Tag inFormation={groupState === 'inFormation'}>
              <s.Text type="button" bold>
                {group()}
              </s.Text>
            </s.Tag>
          </s.InfoWrapper>
        </s.Row>
        <s.Row>
          <s.Col sm={6} xs={6}>
            <s.VerticalList
              elements={[
                <s.Text type="captionUppercase" bold color="gray5">
                  {
                    creation.steps.simulationResults.cards.label
                      .monthlyInstallment
                  }
                </s.Text>,
                <s.Text type="headline" bold>
                  {Currency.parse(monthlyValue, true)}
                </s.Text>,
              ]}
            />
          </s.Col>
          <s.Col sm={6} xs={6}>
            <s.VerticalList
              elements={[
                <s.Text type="captionUppercase" bold color="gray5">
                  {
                    creation.steps.simulationResults.cards.label
                      .numberInstallment
                  }
                </s.Text>,
                <s.Text type="headline" bold>
                  {numberInstallment}
                </s.Text>,
              ]}
            />
          </s.Col>
        </s.Row>
        <s.Row>
          <s.Col sm={6} xs={6}>
            <s.VerticalList
              elements={[
                <s.Text type="captionUppercase" bold color="gray5">
                  {creation.steps.simulationResults.cards.label.group}
                </s.Text>,
                <s.Text type="headline" bold>
                  {groupCode}
                </s.Text>,
              ]}
            />
          </s.Col>
          <s.Col sm={6} xs={6}>
            <s.VerticalList
              elements={[
                <s.Text type="captionUppercase" bold color="gray5">
                  {creation.steps.simulationResults.cards.label.quota}
                </s.Text>,
                <s.Text type="headline" bold>
                  {quota}
                </s.Text>,
              ]}
            />
          </s.Col>
        </s.Row>
        <s.Row>
          <s.Button
            onClick={() => {
              handleIsLoadingButtonHire()
              handlerClick(isLoadingButtonHire)
            }}
            color="secondary"
            loading={isLoadingButtonHire}
            disabled={isLoadingButtonHire}
          >
            {buttons.hire}
          </s.Button>
        </s.Row>
        <s.Divider />
        <s.Row>
          <s.SummaryWrapper>
            <s.Text
              type="subheading"
              textTransform="uppercase"
              color="gray5"
              bold
            >
              {creation.steps.simulationResults.cards.ratesSummary.title}
            </s.Text>
          </s.SummaryWrapper>
        </s.Row>
        <s.Row>
          <s.HorizontalList
            startItems={[
              <s.Text
                type="captionUppercase"
                color="gray5"
                textTransform="uppercase"
              >
                {
                  creation.steps.simulationResults.cards.ratesSummary
                    .reserveFund
                }
              </s.Text>,
            ]}
            endItems={[
              <s.Text type="body" color="gray6" bold>
                {reserveFund}%
              </s.Text>,
            ]}
          />
        </s.Row>
        <s.Row>
          <s.HorizontalList
            startItems={[
              <s.Text
                type="captionUppercase"
                color="gray5"
                textTransform="uppercase"
              >
                {
                  creation.steps.simulationResults.cards.ratesSummary
                    .administrativeFee
                }
              </s.Text>,
            ]}
            endItems={[
              <s.Text type="body" color="gray6" bold>
                {administrativeFee}%
              </s.Text>,
            ]}
          />
        </s.Row>
        <s.Row>
          <s.HorizontalList
            startItems={[
              <s.Text
                type="captionUppercase"
                color="gray5"
                textTransform="uppercase"
              >
                {
                  creation.steps.simulationResults.cards.ratesSummary
                    .lifeInsurance
                }
              </s.Text>,
            ]}
            endItems={[
              <s.Text type="body" color="gray6" bold>
                {lifeInsurance}%
              </s.Text>,
            ]}
          />
        </s.Row>
      </s.Container>
    </s.CardComponent>
  )
}

export default PlanCard
