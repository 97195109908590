import React, { memo } from 'react'
import { PageTipProps } from './models'
import * as S from './styles'

const PageTip: React.FC<PageTipProps> = ({ title, subtitle }: PageTipProps) => (
  <S.Container data-testid="page-tip">
    <S.ContainerTitle>
      <S.Title>{title}</S.Title>
    </S.ContainerTitle>
    <S.ContainerText>
      <S.Subtitle>{subtitle}</S.Subtitle>
    </S.ContainerText>
  </S.Container>
)

export default memo(PageTip)
