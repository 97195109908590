import styled from 'styled-components'

import LogoConquistaBeta from 'assets/images/logo-conquista-pro.svg'

import { Button } from 'components'
import TextLiteral from 'components/Text'

export const ErrorText = styled(TextLiteral).attrs(() => ({
  type: 'headline',
  color: 'magenta2',
  textAlign: 'center',
}))`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`
export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
`
export const ErrorState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 136px);
  width: 434px;
  align-self: center;
  text-align: center;
  margin: 0 auto;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: 318px;
  }
`
export const ExpiredText = styled(TextLiteral)`
  margin: 14px 0;
`
export const Text = styled(TextLiteral)`
  margin: 0;
`
export const BackButton = styled(Button)`
  width: 98px;
  margin-top: ${({ theme }) => `${theme.spacing(5)}px`};
`
export const TryAgainButton = styled(Button)`
  width: 192px;
  margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
`
export const Logo = styled.img.attrs(() => ({
  src: LogoConquistaBeta,
  alt: 'Logo Conquista Beta',
}))``
