import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { GetStatesSuccess, GetStatesFailure } from './action'
import { Types } from './types'

export function* getStates() {
  try {
    const { data } = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/produtos/comercializacao/uf`
    )
    yield put(
      GetStatesSuccess({
        ...data,
      })
    )
  } catch (err) {
    yield put(GetStatesFailure())
  }
}

export default all([takeLatest(Types.GET_STATES_REQUEST, getStates)])
