import styled from 'styled-components'

import {
  Container as ContainerCmp,
  Col as ColCmp,
  Row as RowCmp,
} from 'styled-bootstrap-grid'

import VerticalListCmp from 'components/VerticalList'
import HorizontalListCmp from 'components/HorizontalList'
import { CardComponent } from 'pages/Consortium/SolicitationProcess/SimulationSteps/ChosePlan/PlanCard/styles'

export const CardComponentSkeleton = styled(CardComponent)`
  width: 408px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const BodyWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(2)}px`};
  text-align: center;
`

export const Container = styled(ContainerCmp)`
  padding: 0;
`

export const Col = styled(ColCmp)``
export const Row = styled(RowCmp)`
  margin: 0;
`

export const VerticalList = styled(VerticalListCmp)`
  padding: ${({ theme }) => theme.spacing(1)}px;
`

export const HorizontalList = styled(HorizontalListCmp)``

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
`
