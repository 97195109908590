import styled from 'styled-components'
import { Text, Icon } from 'components'

export const Container = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`

export const Title = styled(Text).attrs(() => ({
  bold: true,
  type: 'heading4',
}))`
  font-weight: 400;
`

export const ContainerCloseButton = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(3)}px;
`

export const CloseButton = styled(Icon)`
  fill: ${props => props.theme.colors.gray4};
  cursor: pointer;
  height: 28px;
  transition: all ease 0.3s;
  width: 28px;

  &:hover {
    fill: ${props => props.theme.colors.blue4};
  }
`
