import styled from 'styled-components'
import Text, { IProps as TextProps } from 'components/Text'

export const Container = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.green2};
`

export const Initials = styled(Text)``

export const InitialsProps: TextProps = {
  type: 'body',
  bold: true,
}
