export const Types = {
  TOGGLE_BUTTON_PLAN_CARD: 'TOGGLE_BUTTON_PLAN_CARD',
}

export interface IAction {
  type: keyof typeof Types
  payload: boolean
}

export interface IButtonPlanCard {
  readonly isButtonEnabled: boolean
}
