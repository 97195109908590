export const Types = {
  GET_PASSPORT_COUNTRIES_REQUEST: 'GET_PASSPORT_COUNTRIES_REQUEST',
  GET_PASSPORT_COUNTRIES_SUCCESS: 'GET_PASSPORT_COUNTRIES_SUCCESS',
  GET_PASSPORT_COUNTRIES_FAILURE: 'GET_PASSPORT_COUNTRIES_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IPassportCountries[]
}

export interface IPassportCountries {
  codigo: string
  descricao: string
}

export interface IPassportCountriesConsortium {
  paises: IPassportCountries[]
}

export interface IPassportCountriesConsortiumState {
  readonly data: IPassportCountries[] | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
