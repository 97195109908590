import { AxiosResponse } from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceInvestAws } from 'services/api'

import { GetZipCodeeDataFailure, GetZipCodeeDataSuccess } from './action'
import {
  IGetZipCodeeDataRequestAction,
  ZipCodeType,
  CEPResponse,
} from './types'

export function* getData({ payload }: IGetZipCodeeDataRequestAction) {
  try {
    const [cepPrefix, cepSufix] = payload.split('-')

    const { data }: AxiosResponse<CEPResponse> = yield call(
      get,
      `${baseURLNamespaceInvestAws}/cliente/endereco`,
      {
        params: { cep: cepPrefix, cepSufixo: cepSufix },
      }
    )

    yield put(GetZipCodeeDataSuccess(data))
  } catch (err) {
    yield put(GetZipCodeeDataFailure())
  }
}

export default all([takeLatest(ZipCodeType.GET_DATA_REQUEST, getData)])
