import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'

import ConquistaBetaIcon from 'assets/images/logoConquistaBeta.png'
import ConquistaIcon from 'components/IconExporter'
import NotificationBellComponent from 'components/NotificationBell'
import AvatarComponent from 'components/Avatar'
import Tag from 'components/Tag'
import DropDownCmp from 'components/Dropdown'

interface BgWrapperProps {
  isScrolled?: boolean
}

export const BgWrapper = styled.div<BgWrapperProps>`
  background-color: ${props => props.theme.colors.gray1};
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 11;

  @media ${props => props.theme.mediaQueries.lg} {
    background-color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.gray3};
  }
`

export const FixedHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 7;
`

export const GridContainer = styled(Container)`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
`

export const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const RightBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  && > div:not(:last-child) {
    margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`

export const Icon = styled(ConquistaIcon).attrs(props => ({
  name: props.name,
}))`
  width: 21px;
  height: 21px;
  margin: ${({ theme }) => `0 ${theme.spacing(1)}px`};
  flex-shrink: 0;
`

export const ConquistaBeta = styled.img.attrs(() => ({
  src: ConquistaBetaIcon,
}))`
  width: 144px;
  height: 51px;
  margin: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(4)}px 0 0`};
  cursor: pointer;
`

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  svg {
    align-self: center;
  }
`

export const Name = styled.p`
  ${props => props.theme.fontTypes.headline};
  color: ${props => props.theme.colors.gray6};
  cursor: pointer;
`

export const Points = styled.p`
  ${props => props.theme.fontTypes.caption};
  color: ${props => props.theme.colors.blue4};
  margin-top: 4px;
`
export const ResponsiveMenu = styled.div`
  width: 28px;
  height: 28px;
  background-color: ${props => props.theme.colors.blue4};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${props => props.theme.colors.white};
  }
`

export const MenuTag = styled(Tag)`
  cursor: pointer;
`

export const NotificationBell = styled(NotificationBellComponent)`
  cursor: pointer;
`

export const Avatar = styled(AvatarComponent)`
  cursor: pointer;
`

export const DropDown = styled(DropDownCmp)``
