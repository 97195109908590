import styled from 'styled-components'
import Text from 'components/Text'

export const Container = styled.div`
  margin-left: ${({ theme }) => theme.spacing(3)}px;
  margin-right: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.lg} {
    align-items: flex-start;
    margin: ${({ theme }) => `0 0 ${theme.spacing(4)}px`};
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    align-items: flex-start;
    margin: ${({ theme }) => `0 0 ${theme.spacing(3)}px`};
  }
`

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${({ theme }) => `${theme.spacing(3)}px`};

  @media ${({ theme }) => theme.mediaQueries.lg} {
    justify-content: flex-start;
    align-items: center;
    padding-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    justify-content: flex-start;
    align-items: center;
    padding: ${({ theme }) => `0 ${theme.spacing(2)}px 0 0`};
  }
`

export const ContainerText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.lg} {
    justify-content: flex-start;
    align-items: center;
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    justify-content: flex-start;
    align-items: center;
  }
`

export const Title = styled(Text).attrs(() => ({
  type: 'heading4',
  bold: true,
  color: 'gray6',
}))``

export const Subtitle = styled(Text).attrs(() => ({
  type: 'headline',
  color: 'gray6',
}))``
