import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { IFatca, IFatcaConsortium, Types } from './types'
import { GetFatcaSuccess, GetFatcaFailure } from './action'

export function* getFatca() {
  try {
    const { data }: AxiosResponse<IFatcaConsortium> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/lista-fatca`
    )

    const fatcas = data.fatcas.sort((a: IFatca, b: IFatca) => {
      if (a.descricao > b.descricao) {
        return 1
      }
      if (a.descricao < b.descricao) {
        return -1
      }
      return 0
    })

    yield put(GetFatcaSuccess(fatcas))
  } catch (err) {
    yield put(GetFatcaFailure())
  }
}

export default all([takeLatest(Types.GET_FATCA_REQUEST, getFatca)])
