export const Types = {
  GET_JOBS_REQUEST: 'GET_JOBS_REQUEST',
  GET_JOBS_SUCCESS: 'GET_JOBS_SUCCESS',
  GET_JOBS_FAILURE: 'GET_JOBS_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IJobsConsortium
}

export interface IJobs {
  codigo: string
  descricao: string
}

export interface IJobsConsortium {
  profissoes: IJobs[]
}

export interface IJobsConsortiumState {
  readonly data: IJobs[] | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
