import { Types, IReturnRequestType } from './types'
import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'

export function PostSendProposalRequest(payload: FormProposalConsortium) {
  return {
    type: Types.POST_SEND_PROPOSAL_REQUEST,
    payload,
  }
}

export function PostSendProposalSuccess(payload: IReturnRequestType) {
  return {
    type: Types.POST_SEND_PROPOSAL_SUCCESS,
    payload,
  }
}

export function PostSendProposalFailure() {
  return {
    type: Types.POST_SEND_PROPOSAL_FAILURE,
  }
}
