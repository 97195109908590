import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { Types, IDocumentsResponse } from './types'
import { GetDocumentsSuccess, GetDocumentsFailure } from './action'

export function* getDocuments() {
  try {
    const { data }: AxiosResponse<IDocumentsResponse> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/tipos-documento`
    )

    yield put(GetDocumentsSuccess(data.tipos_documento))
  } catch (err) {
    yield put(GetDocumentsFailure())
  }
}

export default all([takeLatest(Types.GET_DOCUMENTS_REQUEST, getDocuments)])
