import styled from 'styled-components'

import LoadingCmp from 'components/Loading'

export const Loading = styled(LoadingCmp)``

export const LoadingContainer = styled.div`
  max-width: 428px;
  margin: auto;
  display: flex;
  min-height: calc(100vh - 136px);
  align-self: center;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: 304px;
  }
`

export const HeaderWrapper = styled.div`
  margin: 14px 0;
  @media ${({ theme }) => theme.mediaQueries.xs} {
    padding: 0 7px;
  }
`
