export const Types = {
  GET_DOCUMENTS_REQUEST: 'GET_DOCUMENTS_REQUEST',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAILURE: 'GET_DOCUMENTS_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IDocumentsResponse[]
}

export interface IDocument {
  codigo: string
  descricao: string
}

export interface IDocumentsResponse {
  tipos_documento: IDocument[]
}

export interface IPayload {
  type: keyof typeof Types
}

export interface IDocumentsConsortiumState {
  data: IDocument[] | null
  isLoading: boolean
  hasError: boolean
}
