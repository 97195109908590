import styled from 'styled-components'

import Text from 'components/Text'
import Button from 'components/Button'

export const MessageText = styled(Text).attrs(() => ({
  color: 'gray6',
  type: 'headline',
  textAlign: 'center',
}))``

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 136px);

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    padding: 0 14px;
  }
`

export const ButtonsWrapper = styled.div`
  padding-top: 14px;
  display: flex;
`

export const HomeButton = styled(Button)`
  margin-right: 14px;
`

export const SimulateAgainButton = styled(Button).attrs(() => ({
  outline: true,
}))``
