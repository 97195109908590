export const Types = {
  CUSTOMER_PROFILE_GET_DATA_REQUEST: 'CUSTOMER_PROFILE_GET_DATA_REQUEST',
  CUSTOMER_PROFILE_GET_DATA_SUCCESS: 'CUSTOMER_PROFILE_GET_DATA_SUCCESS',
  CUSTOMER_PROFILE_GET_DATA_FAILURE: 'CUSTOMER_PROFILE_GET_DATA_FAILURE',
}

interface Data {
  cpfCnpj: string
  customerName: string
  customerEmail: string
  customerState: string
  customerBirthdate: string
  customerPhoneNumber: string
  customerAreaCode: string
  customerGender: string
}

export interface ICustomerProfile {
  data: Data
  status: string
  hasError: boolean
  isLoading: boolean
}
