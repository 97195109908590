import { Col, Container } from 'styled-bootstrap-grid'
import styled from 'styled-components'

import Button from '../commons/Button'
import TextLiteral from 'components/Text'
import DivisorCmp from 'components/Divisor'
import LoadingCmp from 'components/Loading'
import IconExporter from 'components/IconExporter'
import ButtonLinkCmp from 'components/Button/Link'

import DataRowCmp from 'pages/Consortium/commons/DataRow'
import CardStructureCmp from 'pages/Consortium/SolicitationProcess/Components/CardStructure'

export const DataRow = styled(DataRowCmp)``
export const ButtonLink = styled(ButtonLinkCmp)`
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${props => props.theme.mediaQueries.sm} {
    flex-flow: row nowrap;
    width: 30%;
    text-align: end;
  }
`
export const cardStructure = styled(CardStructureCmp)`
  margin-bottom: 30px;
`
export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(10)}`}px;
  align-items: center;
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    flex-direction: row;
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) =>
      `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(8)}`}px;
  }
  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${({ theme }) =>
      `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(13)}`}px;
  }
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    padding: ${({ theme }) =>
      `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(6)}`}px;
  }
`
export const Column = styled(Col)`
  padding-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    &:first-child {
      padding: 0
        ${({ theme }) => `${theme.spacing(18)}px ${theme.spacing(3)}px`};
    }
  }
`
export const ColumnSide = styled(Col)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    position: sticky;
    top: ${({ theme }) => theme.spacing(3)}px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    margin-top: ${({ theme }) => theme.spacing(4)}px;
    max-width: ${({ theme }) => theme.spacing(36)}px;
    margin-right: 0;
  }
`
export const SideTitle = styled(TextLiteral).attrs(() => ({
  type: 'heading4',
  color: 'gray6',
  bold: true,
}))`
  padding-left: 0;
`

export const TextSide = styled(TextLiteral)`
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  padding-left: 0 ${({ theme }) => theme.fontTypes.headline};
  color: ${({ theme }) => theme.colors.gray6};
`
export const Divider = styled(DivisorCmp)`
  margin: 0;
  @media ${props => props.theme.mediaQueries.sm} {
    margin: 0;
  }
`
export const cardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding: 0 ${({ theme }) => theme.spacing(2)}px;
  @media ${props => props.theme.mediaQueries.md} {
    padding: 0 0 0 ${({ theme }) => theme.spacing(2)}px;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`
export const cardHeaderTitle = styled(TextLiteral).attrs(() => ({
  type: 'subheading',
  color: 'gray5',
  bold: true,
}))`
  @media ${props => props.theme.mediaQueries.sm} {
    width: 45%;
  }
`

export const cardHeaderButton = styled(TextLiteral).attrs(() => ({
  type: 'button',
  color: 'blue4',
  bold: true,
}))`
  display: flex;
  align-items: center;
  @media ${props => props.theme.mediaQueries.sm} {
    width: 30%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-align: end;
    justify-content: flex-end;
  }
`

export const cardContent = styled.div`
  display: flex;
  padding: 0 14px;

  @media ${props => props.theme.mediaQueries.sm} {
    flex-flow: column wrap;
  }
`
export const cardContentTitle = styled(TextLiteral).attrs(() => ({
  type: 'caption',
  color: 'gray5',
  bold: true,
}))`
  width: 25%;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  @media ${props => props.theme.mediaQueries.sm} {
    width: 100%;
    margin: 0 0 ${({ theme }) => theme.spacing(1)}px 0;
  }
`

export const cardContentItem = styled(TextLiteral).attrs(() => ({
  type: 'title',
  color: 'gray6',
  bold: true,
}))`
  margin-bottom: 14px;
`

export const cardContentRevise = styled(TextLiteral).attrs(() => ({
  type: 'caption',
  color: 'magenta2',
  bold: true,
}))`
  margin-bottom: 14px;
`

export const ContentWrapperFooter = styled(Container)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.grid.breakpoints.xl}px;
  @media ${props => props.theme.mediaQueries.lgUp} {
    padding-right: ${({ theme }) => theme.spacing(19)}px;
  }
  @media ${props => props.theme.mediaQueries.lg} {
    padding-right: ${({ theme }) => theme.spacing(4)}px;
  }
  @media ${props => props.theme.mediaQueries.sm} {
    height: 100%;
    padding: 0 ${({ theme }) => theme.spacing(3)}px;
    justify-content: space-between;
    flex-flow: column;
  }
  @media ${props => props.theme.mediaQueries.smUp} {
    flex-flow: row nowrap;
    justify-content: flex-end;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: ${({ theme }) => theme.spacing(2)}px;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
`

export const RejectButton = styled(Button)`
  width: 100%;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.blue4};
  color: ${({ theme }) => theme.colors.blue4};
  margin: 0 ${({ theme }) => theme.spacing(1)}px;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0 0 ${({ theme }) => theme.spacing(2)}px;
  }
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: auto;
    margin-right: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: 0;
  }
  &::before {
    background-color: transparent;
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0 0 ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 180px;
    margin-bottom: 0;
  }
`
export const Icon = styled(IconExporter)`
  min-width: ${({ theme }) => theme.spacing(3)}px;
  max-width: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(3)}px;
`
export const Notify = styled(IconExporter)`
  align-self: flex-start;
  width: ${({ theme }) => theme.spacing(1)}px;
  transform: translateY(-5px);
`
export const Loading = styled(LoadingCmp)``

export const LoadingContainer = styled.div`
  max-width: 428px;
  margin: auto;
  display: flex;
  min-height: calc(100vh - 136px);
  align-self: center;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: 304px;
  }
`
export const ErrorState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 136px);
  width: 434px;
  align-self: center;
  text-align: center;
  margin: 0 auto;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: 318px;
  }
`

export const Text = styled(TextLiteral)`
  margin: 0;
`
export const ExpiredText = styled(TextLiteral)`
  margin: 14px 0;
`
export const TryAgainButton = styled(Button)`
  width: 192px;
  margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
`
export const BackButton = styled(Button)`
  width: 98px;
  margin-top: ${({ theme }) => `${theme.spacing(5)}px`};
`
