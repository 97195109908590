import styled from 'styled-components'
import Text from 'components/Text'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContainerIcon = styled.div`
  width: ${({ theme }) => theme.spacing(4)}px;
  height: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  @media ${props => props.theme.mediaQueries.xs} {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 520px;
`

export const Title = styled(Text)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  font-weight: 400;
  @media ${props => props.theme.mediaQueries.xs} {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Subtitle = styled(Text)`
  text-align: center;
  margin-bottom: 0;
  font-weight: 400;
`
