import { Types, IBank } from './types'

export function GetBankRequest() {
  return {
    type: Types.GET_BANK_REQUEST,
  }
}

export function GetBankSuccess(payload: IBank[]) {
  return {
    type: Types.GET_BANK_SUCCESS,
    payload,
  }
}

export function GetBankFailure() {
  return {
    type: Types.GET_BANK_FAILURE,
  }
}
