import React, { useState } from 'react'

import useMedia from 'hooks/useMedia'

import { TSelected } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { PEPHeader, PEPContent } from './localComponents'
import { DrawerWithoutValidationType } from '../../types'
import * as S from './styles'

const PPEDrawer: React.FC<DrawerWithoutValidationType> = ({
  isOpen,
  handleClose,
  proposalData: [proposalData, setProposalData],
}) => {
  const {
    pro: {
      summaryProposal: {
        politicallyExposedPerson: {
          drawer: { button, title },
        },
      },
    },
  } = TextsConsortium()

  const isMobile = useMedia('sm')

  const [ppeSelect, setPpeSelect] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [ppeData, setPpeData] = useState({
    tipo: '',
    grau_pep: 'NAO_PEP',
  })

  const submit = () => {
    setProposalData({
      ...proposalData,
      proponente: {
        ...proposalData.proponente,
        pep: ppeData,
      },
    })

    handleClose()
  }

  const resetForm = () => {
    setPpeData({
      tipo: '',
      grau_pep: 'NAO_PEP',
    })

    setPpeSelect({
      id: 0,
      name: '',
    })

    handleClose()
  }

  const renderFooter = () => {
    return <S.Button onClick={submit}>{button.title}</S.Button>
  }

  if (isMobile) {
    return (
      <S.Modal
        isOpen={isOpen}
        handleClose={resetForm}
        modalTitle={title}
        footer={renderFooter()}
      >
        <PEPContent
          proposalData={proposalData}
          ppe={[ppeData, setPpeData]}
          ppeSelect={[ppeSelect, setPpeSelect]}
        />
      </S.Modal>
    )
  }

  return (
    <S.Drawer
      isOpen={isOpen}
      header={<PEPHeader title={title} onClose={resetForm} />}
      footer={renderFooter()}
    >
      <PEPContent
        proposalData={proposalData}
        ppe={[ppeData, setPpeData]}
        ppeSelect={[ppeSelect, setPpeSelect]}
      />
    </S.Drawer>
  )
}

export default PPEDrawer
