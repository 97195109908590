import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { ErrorForm } from 'pages/Consortium/commons/ErrorForm'
import { GetContemplatedIntervalDataRequest } from 'store/modules/Consortium/Contemplated/action'
import { ApplicationState } from 'store/ApplicationState'
import { formatMoneyDecimal } from 'helpers'

import * as t from './types'
import * as s from './styles'

const ContemplatedValue: React.FC<t.IContemplatedValue> = ({
  contemplatedValueState,
}) => {
  const dispatch = useDispatch()

  const [
    contemplatedValueInput,
    setContemplatedValueInput,
  ] = contemplatedValueState

  const { contemplatedValueData, contemplatedValueIsLoading } = useSelector(
    (state: ApplicationState) => ({
      contemplatedValueData: state.contemplated.data,
      contemplatedValueIsLoading: state.contemplated.isLoading,
    })
  )

  const { creation } = TextsConsortium(
    `${contemplatedValueData &&
      contemplatedValueData.valor_minimo_credito /
        1000} mil e ${contemplatedValueData &&
      contemplatedValueData.valor_maximo_credito / 1000} mil reais`
  )

  useEffect(() => {
    if (!contemplatedValueData) {
      dispatch(GetContemplatedIntervalDataRequest())
    }
  }, [contemplatedValueData, dispatch])

  const handleMoneyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (contemplatedValueData) {
      const maxValue = contemplatedValueData.valor_maximo_credito
      const currentValue = +e.target.value.replace(/\D/gm, '') / 100

      if (currentValue >= maxValue) {
        setContemplatedValueInput({
          value: formatMoneyDecimal((maxValue * 100).toString()),
          touched: false,
        })
        return
      }
    }
    setContemplatedValueInput({ value: e.target.value, touched: false })
  }

  const isValid = useMemo(() => {
    if (contemplatedValueData) {
      const minValue = contemplatedValueData.valor_minimo_credito
      const maxValue = contemplatedValueData.valor_maximo_credito
      const currentValue =
        +contemplatedValueInput.value.replace(/\D/gm, '') / 100

      return minValue <= currentValue && maxValue >= currentValue
    }
    return false
  }, [contemplatedValueData, contemplatedValueInput])

  const validateMoneyValue = () => {
    if (contemplatedValueData) {
      const formattedMinValue = formatMoneyDecimal(
        contemplatedValueData.valor_minimo_credito * 100
      )
      const formattedMaxValue = formatMoneyDecimal(
        contemplatedValueData.valor_maximo_credito * 100
      )
      const stepMessage = `R$ ${formattedMinValue} e R$ ${formattedMaxValue}`

      const { coveredValueValidationMessage } = ErrorForm(stepMessage)

      if (!isValid && contemplatedValueInput.touched) {
        return coveredValueValidationMessage
      }
    }
    return false
  }

  return (
    <s.InputMoney
      data-testid="financial-application-money-input"
      data-gtm-form="input"
      data-gtm-name="quanto investir"
      label="Valor do crédito"
      maxLength={14}
      value={contemplatedValueInput.value}
      placeholder={creation.steps.value.inputLabel}
      disabled={contemplatedValueIsLoading}
      onChange={handleMoneyChange}
      onBlur={() => {
        setContemplatedValueInput((prevState: any) => ({
          ...prevState,
          touched: true,
        }))
      }}
      error={contemplatedValueInput.touched && validateMoneyValue()}
    />
  )
}

export default ContemplatedValue
