import styled, { css } from 'styled-components'
import ModalCmp from 'components/Modal/ModalNew'
import { ModalContainer, ChildrenWrapper } from 'components/Modal/stylesNew'
import ButtonCmp from 'components/Button'
import InputSelect from 'components/Inputs/Select'
import TextCmp from 'components/Text'
import MoneyInput from 'components/Inputs/Money'

const placeholderColor = css`
  & #prefix {
    color: ${({ theme }) => theme.colors.gray6};
  }
`

export const Container = styled.div``

export const Modal = styled(ModalCmp)`
  ${ModalContainer} {
    height: 100%;
    ${ChildrenWrapper} {
      @media ${({ theme }) => theme.mediaQueries.xs} {
        height: 100%;
      }
    }
  }
`

export const Button = styled(ButtonCmp)`
  width: 100%;
`

export const Select = styled(InputSelect)`
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
`

export const UpdateSimulationTitle = styled(TextCmp)`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
  margin-top: ${({ type, theme }) =>
    theme.spacing(type === 'headline' ? 4 : 2)}px;
  margin-bottom: ${({ type, theme }) =>
    theme.spacing(type === 'body' ? 2 : 0)}px;
  &&& {
    font-size: 18px;
  }
`

export const UpdateSimulationText = styled(TextCmp)`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
  margin-top: ${({ type, theme }) =>
    theme.spacing(type === 'headline' ? 4 : 2)}px;
  margin-bottom: ${({ type, theme }) =>
    theme.spacing(type === 'body' ? 2 : 0)}px;
`

export const InputMoney = styled(MoneyInput)`
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  margin-bottom: ${({ error, theme }) => !!error && theme.spacing(10)}px;

  ${({ value }) => !!value && placeholderColor}
`
