import { useEffect, useState } from 'react'
import { base64decode } from 'helpers'

export default function useHashBase64Decoder<T>(hashBase64: string) {
  const [hashDecoded, setHashDecoded] = useState<T | null>(null)

  useEffect(() => {
    if (hashBase64) {
      const dataDecoded: T = base64decode(hashBase64)
      if (dataDecoded) {
        setHashDecoded(dataDecoded)
      }
    }
  }, [hashBase64])

  return {
    hashDecoded,
  }
}
