import styled, { css } from 'styled-components'
import Text from 'components/Text'
import RadioComponent from 'components/Inputs/Radio/New'

interface IIsDrawer {
  isDrawer: boolean
}

interface IWrapperCardRadio {
  active: boolean
}

const activeCardRadio = css`
  &&& {
    border: 1px solid ${({ theme }) => theme.colors.blue4};
  }
`

const isDrawerStyle = css`
  flex-direction: column;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
`

const isDrawerTextStyle = css`
  &&& {
    padding: 0;
    text-align: left;
  }

  & + div {
    padding: 0;
    margin-bottom: 0;

    div {
      max-width: 100%;
    }
  }
`

export const WrapperFirstInstallmentPayment = styled.div``

export const TitleText = styled(Text)<IIsDrawer>`
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px 0`};

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    &:last-child {
      margin-bottom: ${({ theme }) => theme.spacing(4)}px;
    }
  }

  ${({ isDrawer }) => isDrawer && isDrawerTextStyle}
`

export const WrapperCardRadio = styled.div<IWrapperCardRadio>`
  display: flex;
  align-items: center;
  max-width: 370px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(3)}px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 8px;

  & > * {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(3)}px;
    }
  }

  ${({ active }) => active && activeCardRadio}

  & > svg {
    min-width: 35px;
  }
`

export const RadioFirstInstallmentPayment = styled(RadioComponent)`
  & > input {
    margin: 0;
  }
`

export const ContainerRadioFirstInstallmentPayment = styled.div<IIsDrawer>`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => `${theme.spacing(4)}px 0`};

  & > div {
    &:first-child {
      border: 1px solid ${({ theme }) => theme.colors.gray3};
    }

    &:not(:first-child) {
      border: 1px solid ${({ theme }) => theme.colors.white};
    }
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    padding-right: ${({ theme }) => theme.spacing(1)}px;
  }

  ${({ isDrawer }) => isDrawer && isDrawerStyle}
`

export const WrapperTitleTextRadio = styled.div`
  & > span {
    font-weight: normal;
    color: ${({ theme }) => theme.colors.gray5};
  }
`

export const TitleTextRadio = styled(Text)`
  white-space: nowrap;
`
