import { TextsConsortium } from 'pages/Consortium/commons/Texts'

const {
  hire: {
    steps: {
      resultHire: {
        summary: { title },
      },
    },
  },
} = TextsConsortium()

export const summaryTitle = title
