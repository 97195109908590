import styled from 'styled-components'
import CarouselGeneric from 'components/Carousel/index'

export const Carousel = styled(CarouselGeneric)`
  margin-bottom: 2em;

  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
`
