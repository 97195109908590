export const Types = {
  GET_BANK_REQUEST: 'GET_BANK_REQUEST',
  GET_BANK_SUCCESS: 'GET_BANK_SUCCESS',
  GET_BANK_FAILURE: 'GET_BANK_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IBankConsortium
}

export interface IBank {
  codigo: string
  descricao: string
}

export interface IBankConsortium {
  bancos: IBank[]
}

export interface IBankConsortiumState {
  readonly data: IBank[] | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
