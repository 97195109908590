import { Types, IAction, IButtonPlanCard } from './types'

const INITIAL_STATE: IButtonPlanCard = {
  isButtonEnabled: true,
}

export default function(state = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case Types.TOGGLE_BUTTON_PLAN_CARD:
      return {
        ...state,
        isButtonEnabled: action.payload,
      }
  }
  return state
}
