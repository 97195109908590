import styled from 'styled-components'
import Text from 'components/Text'

export const WrapperForm = styled.div`
  padding: ${({ theme }) => theme.spacing(5)}px;
`

export const TitleCardForm = styled(Text).attrs(() => ({
  type: 'subheading',
  color: 'gray5',
  bold: true,
}))`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const Title = styled(Text).attrs(() => ({
  type: 'headline',
  color: 'gray6',
  bold: true,
}))`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const Wrapper = styled.div`
  & > div {
    &:first-child {
      padding-top: ${({ theme }) => theme.spacing(7)}px;
    }
    &:nth-child(3) {
      padding-bottom: ${({ theme }) => theme.spacing(3)}px;

      & > div {
        h5 {
          display: none;
        }
      }
    }

    &:nth-child(5) {
      & > div {
        max-width: 100%;

        & > h5,
        & > p {
          display: none;
        }

        & > div {
          &[data-name='row'] {
            max-width: 630px;
          }

          &[data-name='row'] + div {
            display: none;
          }

          &:last-child {
            max-width: 630px;
          }
        }
      }
    }

    &:last-child {
      padding-bottom: ${({ theme }) => theme.spacing(4)}px;
    }
  }
`
