import React from 'react'

import * as s from './styles'

const LoadingSimulations = () => (
  <s.LoadingContainer>
    <s.Loading
      type="bar"
      legend="Aguarde, estamos buscando as melhores opções para você..."
      textAlign="center"
    />
  </s.LoadingContainer>
)

export default LoadingSimulations
