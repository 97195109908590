import React from 'react'

import Divisor from 'components/Divisor'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { CardType } from 'pages/Consortium/SolicitationProcess/Types/CardType'

import * as s from './styles'

const ContactCard: React.FC<CardType> = ({
  formData: [formData, setFormData],
}) => {
  const {
    hire: {
      steps: {
        informationConfirmation: {
          containerSecond: { label },
        },
      },
    },
  } = TextsConsortium()

  const phoneString = (): string => {
    if (formData.telefone) {
      const phone = formData.telefone
      const ddi = phone.ddi ? `+${phone.ddi}` : ''

      return `${ddi} (${phone.ddd}) ${phone.numero.slice(
        0,
        5
      )}-${phone.numero.slice(-4)}`
    }

    return ''
  }

  return (
    <s.WrapperContact>
      <s.DataDisplay label={label.email} value={formData.email} />
      <Divisor />
      <s.DataDisplay label={label.phone} value={phoneString()} />
    </s.WrapperContact>
  )
}

export default ContactCard
