import styled from 'styled-components'
import {
  Container as ContainerComp,
  Row as RowComp,
  Col as ColComp,
} from 'styled-bootstrap-grid'

export const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => theme.spacing(9)}px;
  background: ${({ theme }) => theme.colors.white};
`

export const Content = styled(ContainerComp)`
  display: flex;
  justify-content: flex-end;
  margin: ${({ theme }) => theme.spacing(2)}px auto;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    justify-content: center;
    margin: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const Row = styled(RowComp)`
  width: 100%;
`

export const EmptyColumn = styled(ColComp).attrs(() => ({
  lg: 3,
  md: 10,
  mdOffset: 1,
  lgOffset: 0,
}))``

export const ContainerChildren = styled(ColComp).attrs(() => ({
  lg: 8,
  md: 10,
  mdOffset: 1,
  lgOffset: 0,
}))`
  display: flex;
  justify-content: flex-end;
`
