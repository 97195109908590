import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'
import { hasSpouse } from '../cards/PersonalData/types'

export const validatePersonalFom = (
  proposalData: FormProposalConsortium
): boolean => {
  if (proposalData.proponente.codigo_estado_civil === '') {
    return false
  }

  if (hasSpouse.includes(proposalData.proponente.codigo_estado_civil)) {
    if (
      proposalData.proponente.cpf_conjuge === '' ||
      proposalData.proponente.nome_conjuge
    ) {
      return false
    }
  }

  return true
}

export const validateDocumentForm = (
  proposalData: FormProposalConsortium
): boolean => {
  if (
    proposalData.proponente.documento.codigo === '' ||
    proposalData.proponente.documento.numero === '' ||
    proposalData.proponente.documento.codigo === '' ||
    proposalData.proponente.documento.dt_expedicao === '' ||
    proposalData.proponente.documento.dt_expedicao === '' ||
    proposalData.proponente.documento.orgao_emissor === ''
  ) {
    return false
  }

  return true
}

export const validateFinancialForm = (
  proposalData: FormProposalConsortium
): boolean => {
  if (
    proposalData.proponente.dados_profissionais.codigo === '' ||
    proposalData.proponente.dados_profissionais.valor_renda_mensal === 0
  ) {
    return false
  }

  return true
}

export const validatePaymentForm = (
  proposalData: FormProposalConsortium
): boolean => {
  if (
    proposalData.proponente.dados_bancarios.codigo_banco === '' ||
    proposalData.proponente.dados_bancarios.digito_conta === '' ||
    proposalData.forma_pagamento_adesao === '' ||
    proposalData.proponente.dados_bancarios.numero_agencia === '' ||
    proposalData.proponente.dados_bancarios.numero_conta === ''
  ) {
    return false
  }

  return true
}
