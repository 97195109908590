import { Types } from './types'
import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'

export function GetProposalRequest({ payload }: { payload: string }) {
  return {
    type: Types.GET_PROPOSAL_REQUEST,
    payload,
  }
}

export function GetProposalSuccess(payload: FormProposalConsortium) {
  return {
    type: Types.GET_PROPOSAL_SUCCESS,
    payload,
  }
}

export function GetProposalFailure(payload: string) {
  return {
    type: Types.GET_PROPOSAL_FAILURE,
    payload,
  }
}
