import React from 'react'
import { useSelector } from 'react-redux'

import { TSelected, TOptions } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { IFatca } from 'store/modules/Consortium/Fatca/types'
import { ApplicationState } from 'store/ApplicationState'
import { ICountries } from 'store/modules/Consortium/Countries/types'

import {
  validationErrorNIF,
  validationErrorPassportNumber,
  formatOptionText,
} from '../utils'
import { IFormFatcaData, FatcaFormType } from '../types'

import * as s from '../styles'

const FatcaForm: React.FC<FatcaFormType> = ({
  formData: [formData, setFormData],
  isForeignResidency: [isForeignResidency, setIsForeignResidency],
  countryResidence: [countryResidence, setCountryResidence],
  passportIssuingCountry: [passportIssuingCountry, setCountryIssuancePassport],
  fatca: [fatca, setFatca],
}) => {
  const {
    formDefault,
    hire: {
      steps: {
        informationConfirmation: {
          containerThird: { label },
        },
      },
    },
  } = TextsConsortium()

  /**
   * USESTATE
   */
  const [fieldsTouched, setfieldsTouched] = React.useState<IFormFatcaData>({
    countryResidence: false,
    documentNumberNIF: false,
    fatca: false,
    passportNumber: false,
    passportType: false,
    passportIssuingCountry: false,
  })

  const [fatcaOption, setFatcaOption] = React.useState<TOptions[]>([])

  const [countryResidenceOption, setCountryResidenceOption] = React.useState<
    TOptions[]
  >([])

  const [
    passportIssuingCountryOption,
    setCountryIssuancePassportOption,
  ] = React.useState<TOptions[]>([])

  const clearFatcaForm = () => {
    setfieldsTouched({
      ...fieldsTouched,
      fatca: false,
      passportNumber: false,
      passportType: false,
      passportIssuingCountry: false,
      countryResidence: false,
    })

    setFormData({
      ...formData,
      fatca: '',
      documentNumberNIF: '',
      passportNumber: '',
      passportIssuingCountry: '',
      foreignResidence: '',
      isForeignResidency: false,
    })

    setCountryIssuancePassport({ id: 0, name: '' })
    setFatca({ id: 0, name: '' })
    setCountryResidence({ id: 0, name: '' })
  }

  /**
   * USESELECT
   */
  const { passportCountriesConsortium } = useSelector(
    (state: ApplicationState) => ({
      passportCountriesConsortium: state.consortiumPassportCountries.data,
    })
  )

  const { countriesConsortium } = useSelector((state: ApplicationState) => ({
    countriesConsortium: state.consortiumCountries.data,
  }))

  const { fatcaConsortium } = useSelector((state: ApplicationState) => ({
    fatcaConsortium: state.consortiumFatca.data,
  }))

  /**
   * USECALLBACK
   */
  const updateStateForm = React.useCallback(
    (key: string, value: string | boolean) => {
      setFormData({
        ...formData,
        [key]: value,
      })
    },
    [formData, setFormData]
  )

  const updatefieldTouched = React.useCallback(
    (key: string, value: boolean) => {
      setfieldsTouched({
        ...fieldsTouched,
        [key]: value,
      })
    },
    [fieldsTouched, setfieldsTouched]
  )

  const setSelectOptions = React.useCallback(
    (
      setCountry: React.Dispatch<React.SetStateAction<TOptions[]>>,
      key: string,
      setCountrySelect: React.Dispatch<React.SetStateAction<TSelected>>,
      data: ICountries[] | IFatca[]
    ) => {
      setCountry(
        data.map(value => ({
          id: value.codigo,
          text: formatOptionText(value.descricao),
          onClick: () => {
            setCountrySelect({
              id: value.codigo,
              name: formatOptionText(value.descricao),
            })
            updateStateForm(key, value.codigo)
          },
        }))
      )
    },
    [updateStateForm]
  )

  /**
   * USEFFECT
   */
  React.useEffect(() => {
    if (countriesConsortium && countriesConsortium.length > 0) {
      setSelectOptions(
        setCountryResidenceOption,
        'foreignResidence',
        setCountryResidence,
        countriesConsortium
      )
    }
  }, [
    countriesConsortium,
    setCountryResidence,
    passportCountriesConsortium,
    setSelectOptions,
  ])

  React.useEffect(() => {
    if (passportCountriesConsortium && passportCountriesConsortium.length > 0) {
      setSelectOptions(
        setCountryIssuancePassportOption,
        'passportIssuingCountry',
        setCountryIssuancePassport,
        passportCountriesConsortium
      )
    }
  }, [
    setCountryIssuancePassport,
    countriesConsortium,
    passportCountriesConsortium,
    setSelectOptions,
  ])

  React.useEffect(() => {
    if (fatcaConsortium && fatcaConsortium.length > 0) {
      setSelectOptions(setFatcaOption, 'fatca', setFatca, fatcaConsortium)
    }
  }, [fatcaConsortium, setSelectOptions, setFatca])

  return (
    <s.RadioDocumentContainer>
      <s.LabelRadioDocument>{label.foreignResidence}</s.LabelRadioDocument>
      <s.RadioOptionsDocumentWrapper data-testid="radio-brazilian-resident">
        <s.RadioDocument
          options={[
            {
              value: '1',
              name: 'Sim',
            },
            {
              value: '0',
              name: 'Não',
            },
          ]}
          checkedItem={isForeignResidency ? '1' : '0'}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setIsForeignResidency(e.currentTarget.value === '1')

            updateStateForm('isForeignResidency', e.currentTarget.value === '1')

            if (e.currentTarget.value === '0') {
              clearFatcaForm()
            }
          }}
        />
      </s.RadioOptionsDocumentWrapper>
      {isForeignResidency ? (
        <s.RowDocument>
          <s.ColDocument>
            <s.Select
              data-testid="select-country-id"
              label={label.countryResidence}
              options={countryResidenceOption}
              selected={countryResidence}
              optionBoxSize={220}
            />
            <s.InputDocument
              errorLines={1}
              label={label.documentNumberNIF}
              data-testid="input-document-number-nif-id"
              placeholder={formDefault.placeholderInput}
              value={formData.documentNumberNIF}
              onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                updatefieldTouched(
                  'documentNumberNIF',
                  e.currentTarget.value !== ''
                )
              }
              error={validationErrorNIF(
                formData.documentNumberNIF,
                fieldsTouched.documentNumberNIF
              )}
              maxLength={25}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateStateForm(
                  'documentNumberNIF',
                  e.currentTarget.value.toUpperCase()
                )
              }}
            />
            <s.Select
              data-testid="select-fatca-id"
              label={label.fatca}
              options={fatcaOption}
              selected={fatca}
              optionBoxSize={220}
            />
            <s.InputDocument
              errorLines={1}
              label={label.passportNumber}
              data-testid="input-passport-number-id"
              placeholder={formDefault.placeholderInput}
              value={formData.passportNumber}
              onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                updatefieldTouched(
                  'passportNumber',
                  e.currentTarget.value !== ''
                )
              }
              maxLength={6}
              error={validationErrorPassportNumber(
                formData.passportNumber,
                fieldsTouched.passportNumber
              )}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateStateForm(
                  'passportNumber',
                  e.currentTarget.value.replace(/\D/g, '')
                )
              }}
            />
            <s.LastSelect
              data-testid="select-country-issuance-passport-id"
              label={label.passportIssuingCountry}
              options={passportIssuingCountryOption}
              selected={passportIssuingCountry}
              optionBoxSize={220}
            />
          </s.ColDocument>
        </s.RowDocument>
      ) : null}
    </s.RadioDocumentContainer>
  )
}

export default FatcaForm
