import { TextsConsortium } from 'pages/Consortium/commons'

const { hire, page: pageType, buttons } = TextsConsortium()

export const stepBar = {
  title: hire.title,
  type: pageType,
  currentStep: hire.steps.summary.actualStep,
  numberOfSteps: hire.stepMax,
}

export const page = {
  title: hire.steps.summary.title,
  subtitle: hire.steps.summary.subtitle,
  resetButtonLink: { title: hire.steps.summary.containerFirst.link },
  changeButtonLink: { title: 'ALTERAR' },
}

export const footer = {
  button: {
    title: buttons.hire,
    titleFail: buttons.tryAgain,
  },
}

export const modal = {
  title: hire.steps.summary.modalBackSimulation.title,
  description: hire.steps.summary.modalBackSimulation.content,
  resetButton: {
    title: hire.steps.summary.modalBackSimulation.button.goback,
  },
  continueButton: {
    title: hire.steps.summary.modalBackSimulation.button.continue,
  },
}
