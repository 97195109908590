import React, { useMemo } from 'react'
import useMedia from 'hooks/useMedia'
import HorizontalList from 'components/HorizontalList'
import Text from 'components/Text'
import { IProps } from './types'
import * as S from './styles'

const StepBar: React.FC<IProps> = ({
  actualStep = null,
  totalNumberOfSteps = null,
  showSteps = true,
  title,
}: IProps) => {
  const isMobile = useMedia('xs')

  return useMemo(
    () => (
      <S.StepBar>
        <S.GridContainer>
          <S.GridRowStepBar>
            <S.ColStepBar lg={12}>
              <HorizontalList
                size="small"
                startItems={[
                  !isMobile ? (
                    <Text
                      data-testid="step-bar-title"
                      color="gray4"
                      type="headline"
                    >
                      {title}
                    </Text>
                  ) : null,
                  <Text color="white" type="headline">
                    Consórcio de Veículo
                  </Text>,
                ]}
                endItems={
                  showSteps
                    ? [
                        <Text
                          data-testid="step-bar-steps-indicator"
                          color="gray4"
                          type="title"
                        >
                          {`ETAPA ${actualStep} de ${totalNumberOfSteps}`}
                        </Text>,
                      ]
                    : null
                }
                css={`
                  width: 100%;
                  padding: 0;
                `}
              />
            </S.ColStepBar>
          </S.GridRowStepBar>
        </S.GridContainer>
      </S.StepBar>
    ),
    [actualStep, totalNumberOfSteps, showSteps, title, isMobile]
  )
}

export default StepBar
