import React from 'react'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import StepBar from 'components/StepBar'
import LifeInsurance from './LifeInsurance'

const StepThree = () => {
  const { hire, page } = TextsConsortium()

  return (
    <>
      <StepBar
        title={hire.title}
        type={page}
        actualStep={3}
        totalNumberOfSteps={4}
      />
      {/* {value < 70 ? <LifeInsurance /> : <CreditLifeInsurance />} */}
      <LifeInsurance />
    </>
  )
}
export default StepThree
