import React from 'react'
import moment from 'moment'

import { formatMoney } from 'helpers'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import * as s from '../../styles'
import { CardDataType } from '../../types'

const ParcelData: React.FC<CardDataType> = ({
  proposalData: [proposalData, setProposalData],
}) => {
  const { hire } = TextsConsortium()

  return (
    <s.cardStructure>
      <s.cardHeader>
        <s.cardHeaderTitle>
          {hire.steps.summary.pro.containerEighth.header.title}
        </s.cardHeaderTitle>
      </s.cardHeader>
      <s.cardContent>
        <s.DataRow
          label={
            hire.steps.summary.pro.containerEighth.content.FirstInstallment
          }
          value={formatMoney(proposalData.valor_primeira_parcela || 0)}
          error={!proposalData.valor_primeira_parcela}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={
            hire.steps.summary.pro.containerEighth.content.monthlyInstallment
          }
          value={formatMoney(proposalData.valor_demais_parcelas || 0)}
          error={!proposalData.valor_demais_parcelas}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={
            hire.steps.summary.pro.containerEighth.content.numberInstallments
          }
          value={String(proposalData.prazo)}
          error={!proposalData.prazo}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={
            hire.steps.summary.pro.containerEighth.content.firstInstallmentDate
          }
          value={moment(proposalData.data_primeiro_pagamento).format(
            'DD/MM/YYYY'
          )}
          error={!proposalData.data_primeiro_pagamento}
        />
      </s.cardContent>
    </s.cardStructure>
  )
}

export default ParcelData
