import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { Types, IPepTypesResponse } from './types'
import { GetPepTypesSuccess, GetPepTypesFailure } from './action'

export function* getPepTypes() {
  try {
    const { data }: AxiosResponse<IPepTypesResponse> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/pep/tipos`
    )

    yield put(GetPepTypesSuccess(data.tipos_pep))
  } catch (err) {
    yield put(GetPepTypesFailure())
  }
}

export default all([takeLatest(Types.GET_PEP_TYPES_REQUEST, getPepTypes)])
