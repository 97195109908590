import styled from 'styled-components'

import TextComponent from 'components/Text'
import ButtonComponent from 'components/Button'

export const ContentText = styled(TextComponent).attrs(() => ({
  type: 'headline',
  color: 'gray6',
}))`
  max-width: 364px;
  padding-bottom: ${({ theme }) => theme.spacing(5)}px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: column;
  }
`

export const Button = styled(ButtonComponent)`
  & + & {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    & + & {
      margin: ${({ theme }) => theme.spacing(1)}px 0 0 0;
    }
  }
`
