import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'styled-components/macro'

import Text from 'components/Text'
import StepBar from 'components/StepBar'
import Loading from 'components/Loading'
import Button from 'components/Button'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { ErrorForm } from 'pages/Consortium/commons/ErrorForm'
import { ApplicationState } from 'store/ApplicationState'
import { GetContemplatedIntervalDataRequest } from 'store/modules/Consortium/Contemplated/action'
import { formatMoneyDecimal } from 'helpers'
import { StepComponent, StepComponentProps } from 'hooks/FluxManagement'

import { IFormConsortium, Steps } from '../../Types'
import * as s from './styles'

const ContemplatedValue: StepComponent = () => {
  const dispatch = useDispatch()
  const { updateForm, form } = useFormFluxManagement<IFormConsortium>()
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  const [contemplatedValueInput, setContemplatedValueInput] = useState({
    value: '',
    touched: false,
  })

  const {
    contemplatedValueData,
    contemplatedValueIsLoading,
    contenplatedValueHasError,
  } = useSelector((state: ApplicationState) => ({
    contemplatedValueData: state.contemplated.data,
    contemplatedValueIsLoading: state.contemplated.isLoading,
    contenplatedValueHasError: state.contemplated.hasError,
  }))

  const { creation, page, buttons } = TextsConsortium(
    `${contemplatedValueData &&
      contemplatedValueData.valor_minimo_credito /
        1000} mil e ${contemplatedValueData &&
      contemplatedValueData.valor_maximo_credito / 1000} mil reais`
  )

  const minMaxValuesText = useMemo(() => {
    if (contemplatedValueData) {
      return `${creation.steps.value.description}`
    }
    return ''
  }, [contemplatedValueData, creation.steps.value.description])

  useEffect(() => {
    if (!contemplatedValueData) {
      dispatch(GetContemplatedIntervalDataRequest())
    }
  }, [contemplatedValueData, dispatch])

  const handleMoneyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (contemplatedValueData) {
      const maxValue = contemplatedValueData.valor_maximo_credito
      const currentValue = +e.target.value.replace(/\D/gm, '') / 100

      if (currentValue >= maxValue) {
        setContemplatedValueInput({
          value: formatMoneyDecimal((maxValue * 100).toString()),
          touched: false,
        })
        return
      }
    }
    setContemplatedValueInput({ value: e.target.value, touched: false })
  }

  const handleSimulationClick = useCallback(() => {
    const payload = {
      ...form,
      simulation: {
        ...form.simulation,
        valor_credito: +contemplatedValueInput.value.replace(/\D/gm, '') / 100,
      },
    }
    updateForm({ ...payload })
    instantiate(Steps.SIMULATIONS_STATE)
  }, [form, contemplatedValueInput.value, updateForm, instantiate])

  const isValid = useMemo(() => {
    if (contemplatedValueData) {
      const minValue = contemplatedValueData.valor_minimo_credito
      const maxValue = contemplatedValueData.valor_maximo_credito
      const currentValue =
        +contemplatedValueInput.value.replace(/\D/gm, '') / 100

      return minValue <= currentValue && maxValue >= currentValue
    }
    return false
  }, [contemplatedValueData, contemplatedValueInput])

  const validateMoneyValue = () => {
    if (contemplatedValueData) {
      const formattedMinValue = formatMoneyDecimal(
        contemplatedValueData.valor_minimo_credito * 100
      )
      const formattedMaxValue = formatMoneyDecimal(
        contemplatedValueData.valor_maximo_credito * 100
      )
      const stepMessage = `R$ ${formattedMinValue} e R$ ${formattedMaxValue}`

      const { coveredValueValidationMessage } = ErrorForm(stepMessage)

      if (!isValid && contemplatedValueInput.touched) {
        return coveredValueValidationMessage
      }
    }
    return false
  }

  const renderContent = () => {
    if (contemplatedValueIsLoading) {
      return (
        <Loading
          type="bar"
          textAlign="center"
          placement="top"
          legend="Aguarde, estamos buscando as informações..."
        />
      )
    }

    if (contenplatedValueHasError) {
      return (
        <s.ErrorWrapper>
          <s.ErrorText>
            Desculpe, não foi possível carregar as informações. Aguarde alguns
            instantes e tente novamente.
          </s.ErrorText>
          <Button
            onClick={() => dispatch(GetContemplatedIntervalDataRequest())}
          >
            tentar novamente
          </Button>
        </s.ErrorWrapper>
      )
    }

    return (
      <>
        <Text type="headline" color="gray6" textAlign="center">
          {creation.steps.value.title}
        </Text>
        <s.QuestionText type="body" color="gray5" textAlign="center">
          {minMaxValuesText}
        </s.QuestionText>
        <s.InputMoney
          data-testid="financial-application-money-input"
          data-gtm-form="input"
          data-gtm-name="quanto investir"
          maxLength={14}
          value={contemplatedValueInput.value}
          error={contemplatedValueInput.touched && validateMoneyValue()}
          placeholder={creation.steps.value.inputLabel}
          disabled={contemplatedValueIsLoading}
          onChange={handleMoneyChange}
          onBlur={() => {
            setContemplatedValueInput(prevState => ({
              ...prevState,
              touched: true,
            }))
          }}
        />
        <s.StyledButton
          data-gtm-name="Simular"
          data-gtm-type="click"
          data-gtm-clicktype="button"
          color="secondary"
          onClick={handleSimulationClick}
          disabled={!isValid}
        >
          {buttons.simulate}
        </s.StyledButton>
      </>
    )
  }

  return (
    <>
      <StepBar
        title={creation.title}
        type={page}
        actualStep={2}
        totalNumberOfSteps={creation.stepMax}
      />
      <s.ContentWrapper>
        <s.StyledContainer>
          <s.StyledRow alignItems="center" justifyContent="center">
            <s.StyledCol xs={12} sm={6} md={6} lg={4}>
              {renderContent()}
            </s.StyledCol>
          </s.StyledRow>
        </s.StyledContainer>
      </s.ContentWrapper>
    </>
  )
}

export default ContemplatedValue
