import React from 'react'
import { cpf } from 'cpf-cnpj-validator'
import { fitToMask } from 'react-masked'
import { useSelector } from 'react-redux'

import { Divisor } from 'components'
import Modal from 'components/Modal/ModalNew'
import { TOptions, TSelected } from 'components/Inputs/Select/types'

import useMedia from 'hooks/useMedia'

import { ITexts } from 'pages/Consortium/commons/Texts/types'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { ApplicationState } from 'store/ApplicationState'
import { ICivilStatus } from 'store/modules/Consortium/CivilStatus/types'

import { formatCpf } from 'helpers'

import { validationDocumentSpouse, validationSpouseName } from './utils'
import { SpouseType } from './types'
import { DrawerType } from '../../types'
import * as s from './styles'

const hasSpouse = ['CASADO', 'UNIAO_ESTAVEL']

const PersonalDataDrawer: React.FC<DrawerType> = ({
  isOpen,
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
  handleClose,
  ...rest
}) => {
  const [texts] = React.useState<ITexts>(TextsConsortium())

  const [spouseData, setSpouseData] = React.useState<SpouseType>({
    spouseName: proposalData.proponente.nome_conjuge || '',
    spouseDocument: String(proposalData.proponente.cpf_conjuge),
  })

  const [civilStatusOption, setCivilStatusOption] = React.useState<TOptions[]>(
    []
  )

  const [civilStatus, setCivilStatus] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [isTouched, setIsTouched] = React.useState({
    spouseName: false,
    spouseDocument: false,
  })

  const isMobile = useMedia('sm')

  const { data } = useSelector(
    (state: ApplicationState) => state.consortiumCivilStatus
  )

  React.useEffect(() => {
    if (data) {
      setCivilStatusOption(
        data.map((value: ICivilStatus) => ({
          id: value.codigo,
          text: value.descricao,
          onClick: () => {
            setCivilStatus({ id: value.codigo, name: value.descricao })
            if (!hasSpouse.includes(value.codigo)) {
              setSpouseData({
                spouseName: '',
                spouseDocument: '',
              })

              setIsTouched({
                spouseName: false,
                spouseDocument: false,
              })
            }
          },
        }))
      )
    }
  }, [data, proposalData, civilStatus, isOpen])

  React.useEffect(() => {
    if (data && civilStatus.id === 0) {
      data.forEach((value: ICivilStatus) => {
        if (value.codigo === proposalData.proponente.codigo_estado_civil) {
          setCivilStatus({
            id: value.codigo,
            name: value.descricao,
          })
        }
      })
    }
  }, [data, civilStatus, setCivilStatus, proposalData])

  const handleClick = () => {
    setProposalData({
      ...proposalData,
      proponente: {
        ...proposalData.proponente,
        codigo_estado_civil: String(civilStatus.id),
        cpf_conjuge: spouseData.spouseDocument,
        nome_conjuge: spouseData.spouseName,
      },
    })

    setIsValidForm({
      ...isValidForm,
      personalForm: true,
    })

    handleClose()
  }

  const resetForm = () => {
    setSpouseData({
      spouseName: proposalData.proponente.nome_conjuge || '',
      spouseDocument: String(proposalData.proponente.cpf_conjuge),
    })

    setCivilStatus({
      id: 0,
      name: '',
    })

    setIsTouched({
      spouseName: false,
      spouseDocument: false,
    })

    handleClose()
  }

  const isValidPersonalForm = () => {
    if (hasSpouse.includes(String(civilStatus.id))) {
      return (
        !cpf.isValid(spouseData.spouseDocument) || spouseData.spouseName === ''
      )
    }

    return false
  }

  const renderContent = () => (
    <>
      <s.CaptionText>
        {texts.hire.steps.informationConfirmation.containerFirst.label.name}
      </s.CaptionText>
      <s.Text type="headline" bold={true}>
        {proposalData.proponente.nome}
      </s.Text>

      <Divisor />

      <s.CaptionText>
        {texts.hire.steps.informationConfirmation.containerFirst.label.document}
      </s.CaptionText>
      <s.Text type="headline" bold={true}>
        {formatCpf(String(proposalData.proponente.cpf) || '')}
      </s.Text>

      <Divisor />

      <s.CaptionText>
        {
          texts.hire.steps.informationConfirmation.containerFirst.label
            .dateOfBirth
        }
      </s.CaptionText>
      <s.Text type="headline" bold={true}>
        {proposalData.proponente.dt_nascimento}
      </s.Text>

      <Divisor />

      <s.CaptionText>
        {
          texts.hire.steps.informationConfirmation.containerFirst.label
            .nationality
        }
      </s.CaptionText>
      <s.Text type="headline" bold={true}>
        {proposalData.proponente.codigo_nacionalidade === 'BRASILEIRA'
          ? 'Brasileiro(a)'
          : ''}
      </s.Text>

      <s.BodyButtonContainer>
        <s.BodyButton outline>
          {texts.hire.steps.informationConfirmation.containerFirst.link}
        </s.BodyButton>
      </s.BodyButtonContainer>

      <s.Divisor />

      <s.Select
        data-testid="select-civil-status-id"
        label="Estado civil"
        options={civilStatusOption}
        selected={civilStatus}
        autoComplete={false}
      />
      {hasSpouse.includes(String(civilStatus.id)) ? (
        <>
          <s.InputPersonalData
            label={
              texts.hire.steps.informationConfirmation.containerFirst.label
                .spouseName
            }
            data-testid="input-spouse-name-id"
            placeholder="Digite o nome do Cônjuge"
            value={spouseData.spouseName}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setSpouseData({
                ...spouseData,
                spouseName: e.currentTarget.value,
              })
            }
            onBlur={() => setIsTouched({ ...isTouched, spouseName: true })}
            error={validationSpouseName(
              spouseData.spouseName,
              isTouched.spouseName
            )}
          />
          <s.InputPersonalData
            label="CPF do(a) cônjuge"
            placeholder="000.000.000-00"
            data-testid="input-spouse-document-id"
            error={validationDocumentSpouse(
              spouseData.spouseDocument,
              isTouched.spouseDocument
            )}
            value={fitToMask(
              String(spouseData.spouseDocument),
              '999.999.999-99'
            )}
            onBlur={() => setIsTouched({ ...isTouched, spouseDocument: true })}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setSpouseData({
                ...spouseData,
                spouseDocument: e.currentTarget.value.replace(/\D/g, ''),
              })
            }}
          />
        </>
      ) : null}
    </>
  )

  const footerContent = () => (
    <s.Button
      data-gtm-name={texts.buttons.saveChanges}
      data-gtm-type="click"
      data-gtm-clicktype="button"
      onClick={() => handleClick()}
      disabled={isValidPersonalForm()}
    >
      {texts.buttons.saveChanges}
    </s.Button>
  )

  return isMobile ? (
    <Modal
      isOpen={isOpen}
      handleClose={resetForm}
      modalTitle={texts.pro.personalDataDrawer.title}
      footer={footerContent()}
      {...rest}
    >
      {renderContent()}
    </Modal>
  ) : (
    <s.Drawer
      isOpen={isOpen}
      handleClose={resetForm}
      drawerTitle={texts.pro.personalDataDrawer.title}
      footer={footerContent()}
      size="sm"
      {...rest}
    >
      {renderContent()}
    </s.Drawer>
  )
}

export default PersonalDataDrawer
