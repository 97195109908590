import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TOptions, TSelected } from 'components/Inputs/Select/types'
import StepBar from 'components/StepBar'
import Icon from 'components/IconExporter'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { ErrorForm } from 'pages/Consortium/commons/ErrorForm'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import { StepComponentProps, StepComponent } from 'hooks/FluxManagement'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'
import { GetClientInformationRequest } from 'store/modules/Consortium/ClientInformation/action'
import { ApplicationState } from 'store/ApplicationState'
import { GetStatesRequest } from 'store/modules/Consortium/States/action'
import { State } from 'store/modules/Consortium/States/types'

import {
  Steps,
  TIPO_PESSOA,
  TIPO_BUSCA,
  CODIGO_PRODUTO,
  CODIGO_CANAL,
  IFormConsortium,
} from '../../Types'

import * as t from './types'
import * as s from './styles'

const ChooseState: StepComponent = () => {
  const dispatch = useDispatch()
  const { updateForm, form } = useFormFluxManagement<IFormConsortium>()
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  const { creation, page, buttons } = TextsConsortium()

  const [uf, setUf] = React.useState('')
  const [states, setStates] = useState<TOptions[]>([])
  const [selectedState, setSelectedState] = useState<TSelected>({
    id: 0,
    name: '',
  })

  const {
    statesData,
    isStatesLoading,
    hasStatesError,
    clientInformationData,
  } = useSelector((state: ApplicationState) => ({
    statesData: state.consortiumStates.data,
    isStatesLoading: state.consortiumStates.isLoading,
    hasStatesError: state.consortiumStates.hasError,
    clientInformationData: state.consortiumClientInformation.data,
  }))

  const isValid = useMemo(() => {
    if (!statesData) {
      return false
    }
    return !!uf && !!selectedState && !!selectedState.name
  }, [selectedState, statesData, uf])

  const handleClick = useCallback(() => {
    if (uf) {
      updateForm({
        ...form,
        simulation: {
          tipo_pessoa: TIPO_PESSOA.FISICA,
          tipo_busca: TIPO_BUSCA.CREDITO_E_PARCELA,
          codigo_produto: CODIGO_PRODUTO.AUTOMOVEL,
          codigo_canal: CODIGO_CANAL.CUSTOMER,
          valor_credito: 0,
          uf,
        },
      })
      instantiate(Steps.CONTENPLATED)
    }
  }, [instantiate, uf, updateForm, form])

  useEffect(() => {
    updateForm({ ...t.INITIAL_STATE_FORM })
  }, [updateForm])

  useEffect(() => {
    if (!clientInformationData) {
      dispatch(GetClientInformationRequest())
    }
  }, [clientInformationData, dispatch])

  useEffect(() => {
    if (!statesData) {
      dispatch(GetStatesRequest())
    }

    if (statesData) {
      const statesOptions = statesData.ufs.map((state: State) => ({
        id: state.codigo,
        text: state.descricao,
        onClick: () => {
          setUf(state.codigo)
          setSelectedState({
            id: state.codigo,
            name: state.descricao,
          })
        },
      }))
      setStates([...statesOptions])
    }
  }, [statesData, dispatch])

  const validateSelectInput = () => {
    if (states.length === 0 && hasStatesError && !isStatesLoading) {
      const { errorReloadData } = ErrorForm()
      return errorReloadData
    }
    return false
  }

  return (
    <>
      <StepBar
        title={creation.title}
        type={page}
        actualStep={creation.steps.state.actualStep}
        totalNumberOfSteps={creation.stepMax}
      />
      <s.Wrapper>
        <s.StyledContainer>
          <s.StyledRow alignItems="center" justifyContent="center">
            <s.Col xs={12} sm={6} md={6} lg={4}>
              <s.FlexContainer>
                <s.Text type="headline" color="gray6" textAlign="center">
                  {creation.steps.state.title}
                </s.Text>
              </s.FlexContainer>
              <s.Select
                options={states}
                selected={selectedState}
                label="Estado"
                testId="states"
                isLoading={isStatesLoading}
                optionBoxSize={220}
                optionBoxDirection="bottom"
                handlerSuffix={() => dispatch(GetStatesRequest())}
                error={validateSelectInput()}
                suffix={
                  hasStatesError &&
                  !isStatesLoading && (
                    <Icon name="reload" color="blue4" width={21} height={21} />
                  )
                }
              />
              <s.StyledButton
                data-gtm-name="proximo"
                data-gtm-type="click"
                data-gtm-clicktype="button"
                disabled={!isValid}
                onClick={handleClick}
              >
                {buttons.next}
              </s.StyledButton>
            </s.Col>
          </s.StyledRow>
        </s.StyledContainer>
      </s.Wrapper>
    </>
  )
}
export default ChooseState
