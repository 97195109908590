export const Types = {
  POST_QUOTES_REQUEST: 'POST_QUOTES_REQUEST',
  POST_QUOTES_SUCCESS: 'POST_QUOTES_SUCCESS',
  POST_QUOTES_FAILURE: 'POST_QUOTES_FAILURE',
  CLEAR_QUOTES: 'CLEAR_QUOTES',
}

export interface IAction {
  type: keyof typeof Types
  payload: IQuoteReservationRequest
}

export interface IQuoteReservationResponse {
  numero_reserva: number
}

export interface IQuoteReservationRequest {
  payload: IQuoteReservation
  onSuccess?: () => void
  onError?: () => void
}

export interface IQuoteReservation {
  codigo_grupo: string
  numero_cota: number
  prazo: number
  valor_credito: number
  valor_parcela: number
  fundo_reserva: number
  taxa_administrativa: number
  seguro_vida: number
  informacao_produto: string
  assemb_participacao: string
  dt_assembleia: string
  dt_avencto_assembl: string
  total_vagas: number
  taxa_adesao: number
  taxa_administracao: number
  tipo_lance: string
  fd_reserva: number
  tipo_grupo: string
  valor_primeira_parcela: number
  valor_demais_parcelas: number
  numero_participantes: number
  percentual_lance_fixo: number
  percentual_lance_livre: number
  percentual_lance_embutido: number
  codigo_produto?: number
  uf?: string
  desc_produto?: string
  codigo_bem?: string
  valor_adesao?: number
  representante?: number
  cpf_cliente: string
}

export interface PostQuotesRequest {
  type: keyof typeof Types
  request: IQuoteReservationRequest
}

export interface IQuotesConsortiumState {
  readonly data: number | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
