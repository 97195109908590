import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { IJobs, IJobsConsortium, Types } from './types'
import { GetJobsSuccess, GetJobsFailure } from './action'

export function* getJobs() {
  try {
    const { data }: AxiosResponse<IJobsConsortium> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/profissoes`
    )

    const jobs = data.profissoes.sort((a: IJobs, b: IJobs) => {
      if (a.descricao > b.descricao) {
        return 1
      }
      if (a.descricao < b.descricao) {
        return -1
      }
      return 0
    })

    yield put(GetJobsSuccess(jobs))
  } catch (err) {
    yield put(GetJobsFailure())
  }
}

export default all([takeLatest(Types.GET_JOBS_REQUEST, getJobs)])
