import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useToast } from 'hooks/toast'

import Loading from 'components/Loading'
import { TOptions, TSelected } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { CardType } from 'pages/Consortium/SolicitationProcess/Types/CardType'
import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'

import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'

import { IJobs } from 'store/modules/Consortium/Jobs/types'
import { ApplicationState } from 'store/ApplicationState'
import {
  IncomeValidateRequest,
  PostIncomeValidateFailure,
} from 'store/modules/Consortium/IncomeValidate/action'

import { hasGrossMonthlyIncomeError } from '../utils'
import { formatJobOption } from './utils'
import * as s from './styles'

const FinancialCard: React.FC<CardType> = ({
  formData: [formData, setFormData],
}) => {
  const { form } = useFormFluxManagement<IFormConsortium>()
  const dispatch = useDispatch()
  const { addToast } = useToast()
  const {
    formDefault,
    hire: {
      steps: {
        informationConfirmation: {
          containerSixth: { label },
        },
      },
    },
  } = TextsConsortium()

  let typingValue: string = ''
  const doneTypingInterval = 1000

  const [typingTimer, setTypingTimer] = useState(0)
  const [monthlyIncome, setMonthlyIncome] = useState('')

  const { data, isLoading } = useSelector(
    (state: ApplicationState) => state.consortiumJobs
  )

  const grossMonthlyIncome = useSelector(
    (state: ApplicationState) => state.consortiumIncomeValidate
  )

  const [jobsSelect, setJobsSelect] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [jobsOption, setJobsOption] = React.useState<TOptions[]>([])

  React.useEffect(() => {
    if (formData && !monthlyIncome) {
      setMonthlyIncome(
        String(formData.dados_profissionais.valor_renda_mensal).replace(
          '.',
          ','
        )
      )
    }
  }, [formData, monthlyIncome])

  React.useEffect(() => {
    if (data && data.length) {
      data.forEach((item: IJobs) => {
        if (item.codigo === formData.dados_profissionais.codigo) {
          setJobsSelect({
            id: item.codigo,
            name: formatJobOption(item.descricao),
          })
        }
      })

      setJobsOption(
        data.map((value: IJobs) => ({
          id: value.codigo,
          text: formatJobOption(value.descricao),
          onClick: () => {
            setJobsSelect({
              id: value.codigo,
              name: formatJobOption(value.descricao),
            })
            setFormData({
              ...formData,
              dados_profissionais: {
                ...formData.dados_profissionais,
                codigo: value.codigo,
              },
            })
          },
        }))
      )
    }
  }, [formData, data, setFormData])

  const suffixRender = () => {
    if (grossMonthlyIncome.isLoading) {
      return <Loading type="spinner" color="blue4" />
    }

    return ''
  }

  const validationGrossMonthlyIncome = (valueSalary: string): void => {
    const salary = Number(valueSalary.replaceAll('.', '').replace(',', '.'))

    dispatch(
      IncomeValidateRequest({
        payload: {
          valor_parcela_mensal: Number(form.proposal.parcela_escolhida),
          valor_renda_principal: salary,
          valor_outras_rendas: 0,
        },
        onErrorToast: addToast,
        onSuccess: () => {},
      })
    )
  }

  return (
    <s.WrapperFinancialCard>
      <s.DelimiterWrapper>
        <s.Select
          data-testid="select-jobs-id"
          label={label.profession}
          options={jobsOption}
          selected={jobsSelect}
          optionBoxDirection="top"
          optionBoxSize={200}
          isLoading={isLoading}
        />
      </s.DelimiterWrapper>
      <s.DelimiterWrapper>
        <s.InputMoney
          data-testid="financial-money-input"
          value={monthlyIncome === '0' ? '' : monthlyIncome}
          label={label.grossMonthlyIncome}
          placeholder={formDefault.placeholderInputMoney}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setMonthlyIncome(target.value)

            setFormData({
              ...formData,
              dados_profissionais: {
                ...formData.dados_profissionais,
                valor_renda_mensal: Number(
                  target.value.replaceAll('.', '').replace(',', '.')
                ),
              },
            })
          }}
          onKeyUp={e => {
            typingValue = e.currentTarget.value

            clearTimeout(typingTimer)

            dispatch(PostIncomeValidateFailure())

            setTypingTimer(
              Number(
                setTimeout(() => {
                  validationGrossMonthlyIncome(typingValue)
                }, doneTypingInterval)
              )
            )
          }}
          onKeyDown={() => {
            clearTimeout(typingTimer)
            setTypingTimer(0)
          }}
          error={hasGrossMonthlyIncomeError(grossMonthlyIncome)}
          suffix={suffixRender()}
        />
      </s.DelimiterWrapper>
    </s.WrapperFinancialCard>
  )
}

export default FinancialCard
