import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useSelector, useDispatch } from 'react-redux'

import StepBar from 'components/StepBar'
import { ApplicationState } from 'store/ApplicationState'
import {
  ProposalSimulationsClearData,
  PostProposalSimulationsRequest,
} from 'store/modules/Consortium/ProposalSimulations/action'
import { useToast } from 'hooks/toast'
import {
  StepComponent,
  useFormFluxManagement,
  useStackFluxManagement,
} from 'hooks/FluxManagement'

import { IFormConsortium, Steps } from '../../Types'

import Loading from './Loading'
import Error from './Error'
import EmptyState from './EmptyState'
import CustomerInvalid from './CustomerInvalid'

type stateType = 'LOADING' | 'ERROR'

const SimulationsState: StepComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToast()
  const [componentType, setComponentType] = useState<stateType>('LOADING')
  const { form, resetForm } = useFormFluxManagement<IFormConsortium>()
  const { instantiate, reset } = useStackFluxManagement<StepComponent>()

  const {
    simulationsData,
    isSimulationsDataLoading,
    hasSimulationsDataError,
    isCustomerInvalid,
  } = useSelector((state: ApplicationState) => ({
    simulationsData: state.consortiumProposalSimulations.data,
    isSimulationsDataLoading: state.consortiumProposalSimulations.isLoading,
    hasSimulationsDataError: state.consortiumProposalSimulations.hasError,
    isCustomerInvalid: state.consortiumClientInformation.isInvalid,
  }))

  const simulationsRequest = useCallback(() => {
    dispatch(
      PostProposalSimulationsRequest({
        payload: { ...form.simulation },
        onErrorToast: addToast,
        onSuccess: () => null,
      })
    )
  }, [addToast, dispatch, form])

  const resetSimulation = useCallback(() => {
    dispatch(ProposalSimulationsClearData())
    resetForm()
    reset()
  }, [dispatch, reset, resetForm])

  const redirectToHome = useCallback(() => {
    resetSimulation()
    history.push('/')
  }, [history, resetSimulation])

  useEffect(() => {
    if (!simulationsData && !isCustomerInvalid) {
      simulationsRequest()
    }
  }, [simulationsData, isCustomerInvalid, simulationsRequest])

  useEffect(() => {
    if (isSimulationsDataLoading) {
      setComponentType('LOADING')
    }

    if (hasSimulationsDataError) {
      setComponentType('ERROR')
    }

    if (simulationsData && simulationsData.length && !isCustomerInvalid) {
      instantiate(Steps.CHOOSE_PLAN)
    }
  }, [
    isSimulationsDataLoading,
    hasSimulationsDataError,
    simulationsData,
    instantiate,
    isCustomerInvalid,
  ])

  const renderContent = () => {
    if (isCustomerInvalid) {
      return <CustomerInvalid handleRedirect={redirectToHome} />
    }

    if (simulationsData && simulationsData.length === 0) {
      return (
        <EmptyState
          handleSimulateAgain={resetSimulation}
          handleRedirect={redirectToHome}
        />
      )
    }

    if (componentType === 'LOADING') {
      return <Loading />
    }

    if (componentType === 'ERROR') {
      return <Error handleRetry={simulationsRequest} />
    }

    return null
  }

  return (
    <>
      <StepBar
        title="Simulação"
        type="Consórcio de Veículo"
        showSteps={false}
      />
      <Container>
        <Row alignItems="center" justifyContent="center">
          <Col lg={4} md={4} sm={6} xs={12}>
            {renderContent()}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SimulationsState
