export const Types = {
  GET_FATCA_REQUEST: 'GET_FATCA_REQUEST',
  GET_FATCA_SUCCESS: 'GET_FATCA_SUCCESS',
  GET_FATCA_FAILURE: 'GET_FATCA_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IFatcaConsortium
}

export interface IFatca {
  codigo: string
  descricao: string
}

export interface IFatcaConsortium {
  fatcas: IFatca[]
}

export interface IFatcaConsortiumState {
  readonly data: IFatca[] | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
