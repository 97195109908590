import styled, { css } from 'styled-components'

import DrawerCmp from 'pages/Consortium/SolicitationProcess/PRO/commons/Drawer'
import TextCmp from 'components/Text'
import NewInput from 'components/NewInput'
import SelectComponent from 'components/Inputs/AutoCompleteSelect'
import { DrawerBody } from 'pages/Consortium/SolicitationProcess/PRO/commons/Drawer/styles'
import AdministrationFeeSelectCmp from 'pages/Consortium/commons/Inputs/AdministrationFeeSelect'
import BankAccountInputCmp from 'pages/Consortium/commons/Inputs/BankAccountInput'
import BankAccountDigitInputCmp from 'pages/Consortium/commons/Inputs/BankAccountDigitInput'
import BankAgencyInputCmp from 'pages/Consortium/commons/Inputs/BankAgencyInput'
import BankSelectCmp from 'pages/Consortium/commons/Inputs/BankSelect'
import ButtonCmp from 'components/Button'
import DivisorCmp from 'components/Divisor'
import RadioCmp from 'components/Inputs/Radio/New'

import * as i from './types'

const spacePlusError = css`
  &&& {
    margin-bottom: ${({ theme }) => theme.spacing(7)}px;

    div {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }

    span {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
`

export const Drawer = styled(DrawerCmp)`
  ${DrawerBody} {
    padding: 0;
  }
`

export const Select = styled(SelectComponent)`
  margin: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(2)}px`};
`

export const StyledDivisor = styled(DivisorCmp)`
  margin: ${({ theme }) => `${theme.spacing(4)}px 0`};
`

export const InputPersonalData = styled(NewInput)`
  margin: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(2)}px`};

  ${({ error }) => error && spacePlusError}
`

export const StyledRadio = styled(RadioCmp)`
  & > input {
    margin-right: ${({ theme }) => theme.spacing(3)}px;
  }
`

export const OptionWrapper = styled.div<i.IOptionWrapper>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : `81px`)};
  transition: border-color ease 0.3s;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  padding: ${({ noPadding, theme }) =>
    noPadding
      ? `0 ${theme.spacing(3)}px`
      : `${theme.spacing(2)}px ${theme.spacing(3)}px`};

  ${({ border, paymentMethodIsSelected, theme }) =>
    border &&
    css`
      border: 1px solid
        ${paymentMethodIsSelected ? theme.colors.blue4 : theme.colors.gray3};
      border-radius: 8px;
    `}
`

export const TextWrapper = styled.div<i.ITextWrapper>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${({ marginLeft, theme }) =>
    marginLeft &&
    css`
      margin-left: ${theme.spacing(4)}px;
    `}

  span {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const PanelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.gray1};
  border-radius: ${({ theme }) => theme.spacing(1)}px;

  padding: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`

export const InstallmentsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

export const InstallmentLabel = styled.div`
  width: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const HeadlineText = styled(TextCmp).attrs(() => ({
  bold: true,
  color: 'gray6',
  type: 'headline',
}))<i.IHeadlineText>`
  ${({ marginTop, theme }) =>
    marginTop &&
    css`
      margin-top: ${theme.spacing(4)}px;
    `}
`

export const BodyText = styled(TextCmp).attrs(() => ({
  bold: true,
  color: 'gray5',
}))`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

export const CaptionText = styled(TextCmp).attrs(() => ({
  color: 'gray5',
  type: 'subheading',
  textTransform: 'uppercase',
}))`
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  margin: ${({ theme }) =>
    `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`};
`

export const Text = styled(TextCmp)`
  margin: ${({ theme }) => `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`};
`

export const CaptionTextSecond = styled(CaptionText)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`

export const AlertText = styled(TextCmp).attrs(() => ({
  bold: true,
  color: 'magenta2',
  type: 'title',
}))`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

export const Button = styled(ButtonCmp)`
  width: 100%;
`

export const BodyButtonContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px 0`};
`

export const BodyButton = styled(ButtonCmp)`
  width: 100%;
`

export const Divisor = styled(DivisorCmp)`
  margin: ${({ theme }) => `${theme.spacing(4)}px 0`};
`

export const BankSelect = styled(BankSelectCmp)`
  margin-top: ${({ theme }) => theme.spacing(5)}px;
`

export const BankAgency = styled(BankAgencyInputCmp)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`

export const BankAccount = styled(BankAccountInputCmp)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`

export const BankAccountDigit = styled(BankAccountDigitInputCmp)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`

export const AdministrationFeeSelect = styled(AdministrationFeeSelectCmp)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`
