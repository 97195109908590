import React, { memo } from 'react'
import { FooterProps } from './models'
import * as S from './styles'

const Footer: React.FC<FooterProps> = ({ children }: FooterProps) => (
  <S.Container data-testid="footer">
    <S.Content>
      <S.Row>
        <S.EmptyColumn />
        <S.ContainerChildren>{children}</S.ContainerChildren>
      </S.Row>
    </S.Content>
  </S.Container>
)

export default memo(Footer)
