import React from 'react'

import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'

import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'

import LoadData from '../../Components/LoadData'

import * as t from './types'
import { fluxLoading } from './steps'
import PersonalDataForm from './PersonalDataForm'

const StepOne: React.FC = () => {
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()

  React.useEffect(() => {
    if (!form.proposal) {
      updateForm(t.INITIAL_STATE_FORM)
    }
  }, [form, updateForm])

  return (
    <LoadData fluxLoading={fluxLoading}>
      <PersonalDataForm />
    </LoadData>
  )
}

export default StepOne
