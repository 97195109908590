import { updateCustomData } from 'GoogleTagManager'
import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { GetTagSuccess, GetTagFailure, UpdateUserData } from './action'
import { Types } from './types'

export function* getTag() {
  try {
    const { data } = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/clientes/conquista/dados`
    )

    if (window.customData) {
      const customData = {
        ...window.customData.user,
        id: data.id_bcp,
        conquista_id: data.id_pessoa,
        tipo_investidor: data.perfil_investidor,
      }

      updateCustomData(customData)
      yield put(UpdateUserData(window.customData))
    }

    yield put(GetTagSuccess(data))
  } catch (err) {
    yield put(GetTagFailure())
  }
}

export default all([takeLatest(Types.GET_TAG_REQUEST, getTag)])
