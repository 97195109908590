export const Types = {
  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: ICountries[]
}

export interface ICountries {
  codigo: string
  descricao: string
}

export interface ICountriesConsortium {
  paises: ICountries[]
}

export interface ICountriesConsortiumState {
  readonly data: ICountries[] | null
  readonly isLoading: boolean
  readonly hasError: boolean
}
