import { TextsConsortium } from '../Texts'

const {
  buttons: { start },
  hire: {
    steps: {
      resultHire: {
        content: { success, fail },
      },
    },
  },
} = TextsConsortium()

export const icon = {
  name: {
    success: 'check',
    fail: 'closerounded',
  },
  color: {
    success: 'green3',
    fail: 'magenta2',
  },
}

export const message = {
  success,
  fail,
}

export const button = {
  title: start,
}
