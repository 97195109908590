import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { ICountries, ICountriesConsortium, Types } from './types'
import { GetCountriesSuccess, GetCountriesFailure } from './action'

export function* getCountries() {
  try {
    const { data }: AxiosResponse<ICountriesConsortium> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/paises`
    )

    const countries = data.paises.sort((a: ICountries, b: ICountries) => {
      if (a.descricao > b.descricao) {
        return 1
      }
      if (a.descricao < b.descricao) {
        return -1
      }
      return 0
    })

    yield put(GetCountriesSuccess(countries))
  } catch (err) {
    yield put(GetCountriesFailure())
  }
}

export default all([takeLatest(Types.GET_COUNTRIES_REQUEST, getCountries)])
