import React from 'react'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import * as s from '../../styles'
import { CardDataType } from '../../types'

const formatCep = (cep: string | number | undefined) => {
  if (cep) {
    let unFormatedCep = String(cep).replaceAll('-', '')

    return (
      String(unFormatedCep).slice(0, 5) + '-' + String(unFormatedCep).slice(5)
    )
  }

  return ''
}

const AddressData: React.FC<CardDataType> = ({
  proposalData: [proposalData, setProposalData],
}) => {
  const { hire } = TextsConsortium()

  const addressData = proposalData.proponente.endereco_residencial
  let addressComplement = ''

  if (addressData.complemento) {
    addressComplement =
      addressData.complemento.replaceAll(' ', '') !== ''
        ? `- ${addressData.complemento} -`
        : '- '
  }

  const stateName =
    addressData && addressData.uf ? addressData.uf.replace('_', ' ') : ''

  const address = `${addressData.logradouro}, ${addressData.numero} ${addressComplement}${addressData.bairro} - ${addressData.cidade} - ${stateName}`

  return (
    <s.cardStructure>
      <s.cardHeader>
        <s.cardHeaderTitle>
          {hire.steps.summary.pro.containerThird.header.title}
        </s.cardHeaderTitle>
        <s.ButtonLink noIcon>
          {hire.steps.summary.pro.containerThird.header.button}
          <s.Icon name="arrowright" color="blue4" />
        </s.ButtonLink>
      </s.cardHeader>
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerThird.content.zipCode}
          value={formatCep(proposalData.proponente.endereco_residencial.cep)}
          error={!addressData}
        />
      </s.cardContent>
      <s.Divider />
      <s.cardContent>
        <s.DataRow
          label={hire.steps.summary.pro.containerThird.content.address}
          value={address}
          error={!addressData}
        />
      </s.cardContent>
    </s.cardStructure>
  )
}

export default AddressData
