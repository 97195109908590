import { all, takeLatest, call, put } from 'redux-saga/effects'

import { post, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { Types, IPayload, IIncomeValidateResponse } from './types'
import { PostIncomeValidatesuccess, PostIncomeValidateFailure } from './action'

export function* postIncomeValidate({
  payload,
  onErrorToast,
  onSuccess,
}: IPayload) {
  try {
    const { data }: AxiosResponse<IIncomeValidateResponse> = yield call(
      post,
      `${baseURLNamespaceConsortium}v2/consorcios/pagamentos/validar-renda`,
      payload
    )

    yield put(
      PostIncomeValidatesuccess({
        ...data,
      })
    )
    if (onSuccess) onSuccess()
  } catch (err) {
    yield put(PostIncomeValidateFailure())
    onErrorToast({
      type: 'error',
      description: 'Por favor, tente novamente',
      title: 'Erro ao enviar solicitação.',
    })
  }
}

export default all([
  takeLatest(Types.POST_INCOME_VALIDATE_REQUEST, postIncomeValidate),
])
