/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects'

import auth from './Auth/sagas'
import consortium from './Consortium/sagas'
import consortiumBank from './Consortium/Bank/sagas'
import consortiumCivilStatus from './Consortium/CivilStatus/sagas'
import consortiumClientInformation from './Consortium/ClientInformation/sagas'
import clientPROInformationConsortium from './Consortium/ClientPROInformation/sagas'
import consortiumContemplated from './Consortium/Contemplated/sagas'
import consortiumCountries from './Consortium/Countries/sagas'
import consortiumDocuments from './Consortium/Documents/sagas'
import consortiumFatca from './Consortium/Fatca/sagas'
import consortiumIncomeValidate from './Consortium/IncomeValidate/sagas'
import consortiumJobs from './Consortium/Jobs/sagas'
import consortiumPassportCountries from './Consortium/PassportCountries/sagas'
import consortiumPpeRelations from './Consortium/PpeRelations/sagas'
import consortiumPpeType from './Consortium/PpeTypes/sagas'
import consortiumProposal from './Consortium/Proposal/sagas'
import consortiumProposalSimulations from './Consortium/ProposalSimulations/sagas'
import consortiumQuoteReservation from './Consortium/QuoteReservation/sagas'
import consortiumSendProposal from './Consortium/SendProposal/sagas'
import consortiumStates from './Consortium/States/sagas'
import customerProfile from './CustomerProfile/sagas'
import points from './Points/sagas'
import tagManager from './TagManager/sagas'
import zipCode from './ZipCode/sagas'

export default function* rootSaga() {
  return yield all([
    auth,
    consortium,
    consortiumBank,
    consortiumCivilStatus,
    consortiumClientInformation,
    clientPROInformationConsortium,
    consortiumContemplated,
    consortiumCountries,
    consortiumDocuments,
    consortiumFatca,
    consortiumIncomeValidate,
    consortiumJobs,
    consortiumPassportCountries,
    consortiumPpeRelations,
    consortiumPpeType,
    consortiumProposal,
    consortiumProposalSimulations,
    consortiumQuoteReservation,
    consortiumSendProposal,
    consortiumStates,
    customerProfile,
    points,
    tagManager,
    zipCode,
  ])
}
