import React from 'react'
import { themeColors } from 'styles/theme'

import { ReactComponent as add } from 'assets/icons/add.svg'
import { ReactComponent as alertrounded } from 'assets/icons/alert-rounded.svg'
import { ReactComponent as apps } from 'assets/icons/apps.svg'
import { ReactComponent as arrowdown } from 'assets/icons/arrow-down.svg'
import { ReactComponent as arrowleft } from 'assets/icons/arrow-left.svg'
import { ReactComponent as arrowright } from 'assets/icons/arrow-right.svg'
import { ReactComponent as arrowsortable } from 'assets/icons/arrow-sortable.svg'
import { ReactComponent as arrowup } from 'assets/icons/arrow-up.svg'
import { ReactComponent as awesome } from 'assets/icons/awesome.svg'
import { ReactComponent as backspace } from 'assets/icons/backspace.svg'
import { ReactComponent as backward } from 'assets/icons/backward.svg'
import { ReactComponent as baggage } from 'assets/icons/baggage.svg'
import { ReactComponent as ball } from 'assets/icons/ball.svg'
import { ReactComponent as barchart } from 'assets/icons/bar-chart.svg'
import { ReactComponent as barcode } from 'assets/icons/bar-code.svg'
import { ReactComponent as bell } from 'assets/icons/bell.svg'
import { ReactComponent as billing } from 'assets/icons/billing.svg'
import { ReactComponent as boy } from 'assets/icons/boy.svg'
import { ReactComponent as building } from 'assets/icons/building.svg'
import { ReactComponent as businesswoman } from 'assets/icons/business-woman.svg'
import { ReactComponent as calendar } from 'assets/icons/calendar.svg'
import { ReactComponent as callcenter } from 'assets/icons/call-center.svg'
import { ReactComponent as car } from 'assets/icons/car.svg'
import { ReactComponent as car2 } from 'assets/icons/car2.svg'
import { ReactComponent as cardflagmaster } from 'assets/icons/card-flag-master.svg'
import { ReactComponent as cardflagvisa } from 'assets/icons/card-flag-visa.svg'
import { ReactComponent as carnew } from 'assets/icons/car-new.svg'
import { ReactComponent as check } from 'assets/icons/check.svg'
import { ReactComponent as close } from 'assets/icons/close.svg'
import { ReactComponent as closerounded } from 'assets/icons/close-rounded.svg'
import { ReactComponent as coin } from 'assets/icons/coin.svg'
import { ReactComponent as computersecure } from 'assets/icons/computer-secure.svg'
import { ReactComponent as couch } from 'assets/icons/couch.svg'
import { ReactComponent as creditcard } from 'assets/icons/credit-card.svg'
import { ReactComponent as creditcard2 } from 'assets/icons/credit-card2.svg'
import { ReactComponent as diamond } from 'assets/icons/diamond.svg'
import { ReactComponent as dollarsign } from 'assets/icons/dollar-sign.svg'
import { ReactComponent as download } from 'assets/icons/download.svg'
import { ReactComponent as drink } from 'assets/icons/drink.svg'
import { ReactComponent as elo } from 'assets/icons/elo.svg'
import { ReactComponent as eyeclosed } from 'assets/icons/eye-closed.svg'
import { ReactComponent as eyeopen } from 'assets/icons/eye-open.svg'
import { ReactComponent as filter } from 'assets/icons/filter.svg'
import { ReactComponent as financial } from 'assets/icons/financial.svg'
import { ReactComponent as fingerprint } from 'assets/icons/finger-print.svg'
import { ReactComponent as flag } from 'assets/icons/flag.svg'
import { ReactComponent as forward } from 'assets/icons/forward.svg'
import { ReactComponent as gear } from 'assets/icons/gear.svg'
import { ReactComponent as globe } from 'assets/icons/globe.svg'
import { ReactComponent as grid } from 'assets/icons/grid.svg'
import { ReactComponent as home } from 'assets/icons/home.svg'
import { ReactComponent as homewithwindow } from 'assets/icons/home-with-window.svg'
import { ReactComponent as hospital } from 'assets/icons/hospital.svg'
import { ReactComponent as idea } from 'assets/icons/idea.svg'
import { ReactComponent as information } from 'assets/icons/information.svg'
import { ReactComponent as leave } from 'assets/icons/leave.svg'
import { ReactComponent as linechart } from 'assets/icons/line-chart.svg'
import { ReactComponent as link } from 'assets/icons/link.svg'
import { ReactComponent as list } from 'assets/icons/list.svg'
import { ReactComponent as loading } from 'assets/icons/loading.svg'
import { ReactComponent as lock } from 'assets/icons/lock.svg'
import { ReactComponent as lock2 } from 'assets/icons/lock2.svg'
import { ReactComponent as logoConquista } from 'assets/images/logoConquista.svg'
import { ReactComponent as malefemale } from 'assets/icons/male-female.svg'
import {
  ReactComponent as master,
  ReactComponent as mastercard,
} from 'assets/icons/mastercard.svg'
import { ReactComponent as menu } from 'assets/icons/menu.svg'
import { ReactComponent as minus } from 'assets/icons/minus.svg'
import { ReactComponent as money } from 'assets/icons/money.svg'
import { ReactComponent as more } from 'assets/icons/more.svg'
import { ReactComponent as monitoranalytics } from 'assets/icons/monitor-analytics.svg'
import { ReactComponent as pdf } from 'assets/icons/pdf.svg'
import { ReactComponent as personaccident } from 'assets/icons/person-accident.svg'
import { ReactComponent as persongroup } from 'assets/icons/person-group.svg'
import { ReactComponent as persongroup2 } from 'assets/icons/person-group2.svg'
import { ReactComponent as arrowround } from 'assets/icons/arrow-round.svg'
import { ReactComponent as petpaw } from 'assets/icons/pet-paw.svg'
import { ReactComponent as phone } from 'assets/icons/phone.svg'
import { ReactComponent as piechart } from 'assets/icons/pie-chart.svg'
import { ReactComponent as plane } from 'assets/icons/plane.svg'
import { ReactComponent as refresh } from 'assets/icons/refresh.svg'
import { ReactComponent as reload } from 'assets/icons/reload.svg'
import { ReactComponent as remove } from 'assets/icons/remove.svg'
import { ReactComponent as plus } from 'assets/icons/plus.svg'
import { ReactComponent as subtract } from 'assets/icons/subtract.svg'
import { ReactComponent as removeMultiselector } from 'assets/icons/removeMultiselector.svg'
import { ReactComponent as safebox } from 'assets/icons/safe-box.svg'
import { ReactComponent as scale } from 'assets/icons/scale.svg'
import { ReactComponent as search } from 'assets/icons/search.svg'
import { ReactComponent as smartphone } from 'assets/icons/smartphone.svg'
import { ReactComponent as spinner } from 'assets/icons/spinner.svg'
import { ReactComponent as star } from 'assets/icons/star.svg'
import { ReactComponent as student } from 'assets/icons/student-male.svg'
import { ReactComponent as tag } from 'assets/icons/tag.svg'
import { ReactComponent as talkman } from 'assets/icons/talk-man.svg'
import { ReactComponent as target } from 'assets/icons/target.svg'
import { ReactComponent as targetmarket } from 'assets/icons/target-market.svg'
import { ReactComponent as thumbsup } from 'assets/icons/thumbs-up.svg'
import { ReactComponent as time } from 'assets/icons/time.svg'
import { ReactComponent as tool } from 'assets/icons/tool.svg'
import { ReactComponent as tools } from 'assets/icons/tools.svg'
import { ReactComponent as trash } from 'assets/icons/trashcan.svg'
import { ReactComponent as user } from 'assets/icons/user.svg'
import { ReactComponent as usermedic } from 'assets/icons/user-medic.svg'
import { ReactComponent as visa } from 'assets/icons/visa.svg'
import { ReactComponent as wallet } from 'assets/icons/wallet.svg'

export const dict = {
  add,
  alertrounded,
  apps,
  arrowdown,
  arrowleft,
  arrowright,
  arrowround,
  arrowsortable,
  arrowup,
  awesome,
  backspace,
  backward,
  baggage,
  ball,
  barchart,
  barcode,
  bell,
  billing,
  boy,
  building,
  businesswoman,
  calendar,
  callcenter,
  car,
  car2,
  cardflagmaster,
  cardflagvisa,
  carnew,
  check,
  close,
  closerounded,
  coin,
  computersecure,
  couch,
  creditcard,
  creditcard2,
  diamond,
  dollarsign,
  download,
  drink,
  elo,
  eyeclosed,
  eyeopen,
  filter,
  financial,
  logoConquista,
  fingerprint,
  flag,
  forward,
  gear,
  globe,
  grid,
  home,
  homewithwindow,
  hospital,
  idea,
  information,
  plus,
  subtract,
  leave,
  linechart,
  link,
  list,
  loading,
  lock,
  lock2,
  malefemale,
  master,
  mastercard,
  menu,
  minus,
  money,
  more,
  monitoranalytics,
  pdf,
  personaccident,
  persongroup,
  persongroup2,
  petpaw,
  phone,
  piechart,
  plane,
  refresh,
  reload,
  remove,
  removeMultiselector,
  safebox,
  scale,
  search,
  smartphone,
  spinner,
  star,
  student,
  tag,
  talkman,
  target,
  targetmarket,
  thumbsup,
  time,
  tool,
  tools,
  trash,
  user,
  usermedic,
  visa,
  wallet,
}

interface IProps {
  name: keyof typeof dict
  color?: keyof typeof themeColors
  fill?: string
}

export default ({
  name,
  color = 'gray4',
  fill,
  width = 21,
  ...rest
}: IProps & React.SVGAttributes<SVGElement>) => {
  const Icon = dict[name]
  return Icon ? (
    <Icon
      role="img"
      aria-label={name}
      fill={fill ? fill : themeColors[color]}
      width={width}
      {...rest}
    />
  ) : null
}
