import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { Types, IPepRelationshipResponse } from './types'
import { GetPepRelationshipSuccess, GetPepRelationshipFailure } from './action'

export function* getPepRelationshipDegrees() {
  try {
    const { data }: AxiosResponse<IPepRelationshipResponse> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/pep/graus-relacionamento`
    )

    yield put(GetPepRelationshipSuccess(data.graus_relacionamento))
  } catch (err) {
    yield put(GetPepRelationshipFailure())
  }
}

export default all([
  takeLatest(Types.GET_PEP_RELATIONSHIP_REQUEST, getPepRelationshipDegrees),
])
