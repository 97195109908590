import styled from 'styled-components'
import DivisorComponent from 'components/Divisor'
import Text from 'components/Text'

export const Container = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing(2)}px;
`

export const Divisor = styled(DivisorComponent)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Label = styled(Text)``

export const Value = styled(Text)`
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`
