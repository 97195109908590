import React from 'react'

import * as s from './styles'

interface ErrorSimulationsProps {
  handleRetry: () => void
}
const ErrorSimulations = ({ handleRetry }: ErrorSimulationsProps) => (
  <s.ErrorContainer>
    <s.ErrorText>
      Desculpe, não foi possível carregar as informações. Aguarde alguns
      instantes e tente novamente.
    </s.ErrorText>
    <s.RetryButton onClick={handleRetry}>Tentar novamente</s.RetryButton>
  </s.ErrorContainer>
)

export default ErrorSimulations
