import React from 'react'
import { useSelector } from 'react-redux'

import { formatCpf } from 'helpers'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { ApplicationState } from 'store/ApplicationState'
import { ICivilStatus } from 'store/modules/Consortium/CivilStatus/types'
import PersonalDataDrawer from '../../drawers/PersonalDataDrawer'
import { hasSpouse } from './types'
import { CardWithFormType } from '../../types'

import * as s from '../../styles'

const PersonalData: React.FC<CardWithFormType> = ({
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
}) => {
  const { hire } = TextsConsortium()

  const [isPersonalDataDrawer, setIsPersonalDataDrawer] = React.useState<
    boolean
  >(false)

  const [civilStatus, setcivilStatus] = React.useState<ICivilStatus>({
    codigo: '',
    descricao: '',
  })

  const { data } = useSelector(
    (state: ApplicationState) => state.consortiumCivilStatus
  )

  React.useEffect(() => {
    if (data) {
      data.forEach((item: ICivilStatus) => {
        if (item.codigo === proposalData.proponente.codigo_estado_civil) {
          setcivilStatus(item)
        }
      })
    }
  }, [setcivilStatus, proposalData, data])

  const isMaried = hasSpouse.includes(
    proposalData.proponente.codigo_estado_civil
  )

  const maskCpf = (cpf: string) => {
    return `${cpf.substr(0, 2)}*******${cpf.substr(-2)}`
  }

  return (
    <>
      <PersonalDataDrawer
        isOpen={isPersonalDataDrawer}
        isValidForm={[isValidForm, setIsValidForm]}
        proposalData={[proposalData, setProposalData]}
        handleClose={() => setIsPersonalDataDrawer(false)}
      />
      <s.cardStructure>
        <s.cardHeader>
          <s.cardHeaderTitle>
            {hire.steps.summary.pro.containerFirst.header.title}
          </s.cardHeaderTitle>
          <s.ButtonLink onClick={() => setIsPersonalDataDrawer(true)} noIcon>
            {hire.steps.summary.pro.containerFirst.header.button}
            {!isValidForm.personalForm && (
              <s.Notify name="ball" color="magenta2" />
            )}
            <s.Icon name="arrowright" color="blue4" />
          </s.ButtonLink>
        </s.cardHeader>
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerFirst.content.name}
            value={proposalData.proponente.nome}
            error={!proposalData.proponente.nome}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerFirst.content.doc}
            value={formatCpf(String(proposalData.proponente.cpf) || '')}
            error={!proposalData.proponente.cpf}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerFirst.content.birthday}
            value={proposalData.proponente.dt_nascimento}
            error={!proposalData.proponente.dt_nascimento}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerFirst.content.nacionality}
            value={
              proposalData.proponente.codigo_nacionalidade === 'BRASILEIRA'
                ? 'Brasileiro(a)'
                : ''
            }
            error={!proposalData.proponente.codigo_nacionalidade}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerFirst.content.civilState}
            value={civilStatus.descricao}
            error={!proposalData.proponente.codigo_estado_civil}
          />
        </s.cardContent>
        {isMaried && (
          <>
            <s.Divider />
            <s.cardContent>
              <s.DataRow
                label={hire.steps.summary.pro.containerFirst.content.spouseName}
                value={proposalData.proponente.nome_conjuge}
                error={!proposalData.proponente.nome_conjuge}
              />
            </s.cardContent>
            <s.Divider />
            <s.cardContent>
              <s.DataRow
                label={
                  hire.steps.summary.pro.containerFirst.content.spouseDocument
                }
                value={maskCpf(proposalData.proponente.cpf_conjuge ?? '')}
                error={!proposalData.proponente.cpf_conjuge}
              />
            </s.cardContent>
          </>
        )}
      </s.cardStructure>
    </>
  )
}

export default PersonalData
