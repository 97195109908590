import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import {
  IPassportCountries,
  IPassportCountriesConsortium,
  Types,
} from './types'
import {
  GetPassportCountriesSuccess,
  GetPassportCountriesFailure,
} from './action'

export function* getPassportCountries() {
  try {
    const { data }: AxiosResponse<IPassportCountriesConsortium> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/paises-passaporte`
    )

    const countries = data.paises.sort(
      (a: IPassportCountries, b: IPassportCountries) => {
        if (a.descricao > b.descricao) {
          return 1
        }
        if (a.descricao < b.descricao) {
          return -1
        }
        return 0
      }
    )

    yield put(GetPassportCountriesSuccess(countries))
  } catch (err) {
    console.error(err)
    yield put(GetPassportCountriesFailure())
  }
}

export default all([
  takeLatest(Types.GET_PASSPORT_COUNTRIES_REQUEST, getPassportCountries),
])
