import { Types } from './types'
import { IConsortiumItem } from './iConsortium'

export function GetConsortiumDataRequest(cpf: string) {
  return {
    type: Types.GET_REQUEST,
    cpf,
  }
}

export function GetConsortiumDataSuccess(payload: IConsortiumItem[]) {
  return {
    type: Types.GET_SUCCESS,
    payload,
  }
}

export function GetConsortiumDataFailure() {
  return {
    type: Types.GET_FAILURE,
  }
}

export function OpenConsortiumLayer() {
  return {
    type: Types.OPEN_SOLICITATAION_LAYER,
  }
}

export function CloseConsortiumLayer() {
  return {
    type: Types.CLOSE_SOLICITATAION_LAYER,
  }
}
