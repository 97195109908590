import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'

import Icon from 'components/IconExporter'
import LoadingComponent from 'components/Loading'
import IconExporter, { dict } from 'components/IconExporter'

import {
  IFormConsortium,
  FormProposalConsortium,
} from 'pages/Consortium/SolicitationProcess/Types'

import { IProposalConsortiumState } from 'store/modules/Consortium/Proposal/types'
import { themeColors } from 'styles/theme'

import Wrapper from '../Wrapper'
import { loadDataType } from './types'

import * as s from './styles'

const LoadData: React.FC<loadDataType> = ({ fluxLoading, isPro, children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { form, updateForm } = useFormFluxManagement<
    IFormConsortium | FormProposalConsortium
  >()

  const [loadStep, setLoadStep] = React.useState<number>(0)

  const { isLoading, hasError, data } = useSelector((state: any) => {
    if (fluxLoading[loadStep]) {
      return state[fluxLoading[loadStep].selector]
    }

    return {
      isLoading: false,
      hasError: false,
      data: null,
    }
  })

  const { errorMsg } = useSelector(
    (state: { consortiumProposal: IProposalConsortiumState }) =>
      state.consortiumProposal
  )

  useEffect(() => {
    if (!isLoading && !hasError && !data && loadStep < fluxLoading.length) {
      fluxLoading[loadStep].method(dispatch, form)
    } else if (!isLoading && !hasError && data) {
      fluxLoading[loadStep].callBack(data, form, updateForm)
      setLoadStep(loadStep + 1)
    }
  }, [
    dispatch,
    updateForm,
    form,
    fluxLoading,
    isLoading,
    loadStep,
    hasError,
    data,
  ])

  const handleRetryCallback = useCallback(() => {
    fluxLoading[loadStep].method(dispatch, form)
  }, [dispatch, fluxLoading, loadStep, form])

  const icon = isPro ? <s.Logo /> : <Icon name="logoConquista" width={144} />

  if (hasError && errorMsg === 'Recurso não encontrado.') {
    return (
      <s.LoadingWrapper>
        <s.ErrorState>
          <IconExporter
            name={'time' as keyof typeof dict}
            color={'gray5' as keyof typeof themeColors}
            width="28px"
          />
          <s.ExpiredText type="headline" color="gray6" bold>
            Desculpe, esta proposta expirou.
          </s.ExpiredText>
          <s.Text type="body" color="gray5" bold>
            Por favor, entre em contato com seu parceiro para que ele possa
            enviar esta proposta novamente.
          </s.Text>
          <s.BackButton loading={isLoading} onClick={() => history.push('/')}>
            Início
          </s.BackButton>
        </s.ErrorState>
      </s.LoadingWrapper>
    )
  }

  if (hasError) {
    return (
      <Wrapper icon={icon}>
        <s.LoadingWrapper>
          <s.Text type="headline" color="magenta2">
            Desculpe, não foi possível carregar suas informações.
            <br />
            Aguarde alguns instantes e tente novamente.
          </s.Text>
          <s.TryAgainButton
            loading={isLoading}
            onClick={() => handleRetryCallback()}
          >
            TENTAR NOVAMENTE
          </s.TryAgainButton>
        </s.LoadingWrapper>
      </Wrapper>
    )
  }

  if (isLoading) {
    return (
      <Wrapper icon={icon} isLoading={isLoading}>
        <s.LoadingWrapper>
          <LoadingComponent
            placement="top"
            type="bar"
            textAlign="center"
            legend="Aguarde, estamos buscando suas informações..."
          />
        </s.LoadingWrapper>
      </Wrapper>
    )
  }

  if (loadStep === fluxLoading.length) {
    return <>{children}</>
  }

  return null
}

export default LoadData
