import { Types, IPepType } from './types'

export function GetPepRelationshipRequest() {
  return {
    type: Types.GET_PEP_RELATIONSHIP_REQUEST,
  }
}

export function GetPepRelationshipSuccess(payload: IPepType[]) {
  return {
    type: Types.GET_PEP_RELATIONSHIP_SUCCESS,
    payload,
  }
}

export function GetPepRelationshipFailure() {
  return {
    type: Types.GET_PEP_RELATIONSHIP_FAILURE,
  }
}
