import moment from 'moment'

import { capitalize } from 'helpers'

import { TSelected } from 'components/Inputs/Select/types'

import { ErrorForm } from 'pages/Consortium/commons/ErrorForm'
import { IPlaceholderContainerThirdInformationConfirmationStepHireFlux } from 'pages/Consortium/commons/Texts/types'
import {
  brazilianStates,
  validityError,
} from 'pages/Consortium/SolicitationProcess/Types'

import { IValidationFunction } from '../../types'

const {
  numberValidationMessage,
  dateValidationMessage,
  abbreviationValidationMessage,
  nifValidationMessage,
  passportNumberValidationMessage,
  afterDateValidationMessage,
} = ErrorForm()

export const placeholderExpediteBody = (
  documentType: TSelected,
  placeholder: IPlaceholderContainerThirdInformationConfirmationStepHireFlux
): string => {
  if (documentType.name === 'RG') {
    return placeholder.expeditedBodyRG
  }

  if (documentType.name === 'RNE') {
    return placeholder.expeditedBodyRNE
  }

  return ''
}

export const validationErrorDocument = (
  documentNumber: string,
  validationDocumentNumber: boolean,
  type: string
): IValidationFunction => {
  const value = documentNumber.replace(/\D/g, '')

  if (type === 'RG') {
    if (
      ((value.length < 7 && value.length !== 0) || value.length > 9) &&
      validationDocumentNumber
    ) {
      return numberValidationMessage
    }
  }

  if (type === 'RNE') {
    if (
      ((value.length < 8 && value.length !== 0) || value.length > 12) &&
      validationDocumentNumber
    ) {
      return numberValidationMessage
    }
  }

  return undefined
}

export const validationErrorDateDispatch = (
  dateDispatch: string,
  isTouched: boolean
): IValidationFunction => {
  if (dateDispatch && isTouched) {
    if (dateDispatch.length < 10) {
      return validityError
    }

    if (dateDispatch.length === 10) {
      const validate = moment(dateDispatch, 'DD/MM/YYYY')

      if (!validate.isValid()) {
        return validityError
      }

      if (moment().isBefore(validate)) {
        return dateValidationMessage
      }

      if (moment(moment().subtract(50, 'years')).isAfter(validate)) {
        return afterDateValidationMessage
      }
    }
  }

  return undefined
}

export const validationErrorExpeditedBody = (
  expeditedBody: string,
  validation: boolean
): IValidationFunction => {
  if (expeditedBody && expeditedBody.length < 6) {
    return abbreviationValidationMessage
  }

  if (validation) {
    const [, uf] = expeditedBody.split('/')

    const findState = brazilianStates.findIndex(value => value === uf)

    if (findState === -1) {
      return abbreviationValidationMessage
    }
  }

  return undefined
}

export const validationErrorNIF = (
  value: string,
  validation: boolean
): IValidationFunction => {
  if (value && value.length > 25 && validation) {
    return nifValidationMessage
  }

  return undefined
}

export const validationErrorPassportNumber = (
  value: string,
  validation: boolean
): IValidationFunction => {
  if (value && value.length > 8 && validation) {
    return passportNumberValidationMessage
  }

  return undefined
}

export const validateDateDispatch = (dateDispatch: string): boolean => {
  if (dateDispatch) {
    if (dateDispatch.length === 10) {
      const validate = moment(dateDispatch, 'DD/MM/YYYY')

      if (validate.isValid() && !moment().isBefore(validate)) {
        return true
      }
    }
  }

  return false
}

export const validateDocumentNumber = (
  documentNumber: string,
  documentType: string
): boolean => {
  if (documentType === 'RG') {
    if (
      documentNumber.length > 7 &&
      documentNumber.length !== 0 &&
      documentNumber.length <= 12
    ) {
      return true
    }
  }

  if (documentType === 'RNE') {
    if (
      documentNumber.length > 8 &&
      documentNumber.length !== 0 &&
      documentNumber.length <= 12
    ) {
      return true
    }
  }

  return false
}

export const validateExpeditedBody = (expeditedBody: string): boolean => {
  if (expeditedBody && expeditedBody.length === 6) {
    const [, uf] = expeditedBody.split('/')

    const findState = brazilianStates.findIndex(value => value === uf)
    if (findState !== -1) {
      return true
    }
  }
  if (expeditedBody === 'CGPI/DIREX/DPF') {
    return true
  }
  return false
}

export const formatOptionText = (optionLabel: string): string => {
  return optionLabel
    .split(' ')
    .map(character =>
      character.length > 2 ? capitalize(character) : character.toLowerCase()
    )
    .join(' ')
}
