import { AxiosResponse } from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { post, baseURLNamespaceConsortium } from 'services/api'

import { Types, IPayload, ProposalSimulationsResponse } from './types'
import {
  PostProposalSimulationsuccess,
  PostProposalSimulationsFailure,
} from './action'

export function* postProposalSimulations({
  payload,
  onErrorToast,
  onSuccess,
}: IPayload) {
  try {
    const { data }: AxiosResponse<ProposalSimulationsResponse> = yield call(
      post,
      `${baseURLNamespaceConsortium}v2/consorcios/propostas/simular`,
      payload
    )

    const normalizedSimulations = data.simulacoes.map(simulacao => {
      return {
        ...simulacao,
        id: Math.random().toString(),
      }
    })

    yield put(PostProposalSimulationsuccess(normalizedSimulations))
    if (onSuccess) onSuccess()
  } catch (err) {
    yield put(PostProposalSimulationsFailure())
    onErrorToast({
      type: 'error',
      description: 'Por favor, tente novamente',
      title: 'Erro ao enviar solicitação.',
    })
  }
}

export default all([
  takeLatest(Types.POST_PROPOSAL_SIMULATIONS_REQUEST, postProposalSimulations),
])
