import React from 'react'
import { useSelector } from 'react-redux'

import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'

import { TOptions, TSelected } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { formatMoney } from 'helpers'
import { getDataBank } from './utils'
import { IPaymentData } from '../types'
import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/PRO/PartnerOffer/TypesPartnerOffer'
import { ApplicationState } from 'store/ApplicationState'

import * as s from './styles'

const PaymentData: React.FC<IPaymentData> = ({
  paymentData: [paymentData, setPaymentData],
  updateForm,
  content,
  isDrawer = false,
}: IPaymentData) => {
  const { formDefault } = TextsConsortium()
  const { form } = useFormFluxManagement<IFormConsortium>()

  const { data: bankConsortiumData } = useSelector(
    (state: ApplicationState) => state.consortiumBank
  )

  const [agency, setAgency] = React.useState<string>(
    form.proposal.proponente.dados_bancarios.numero_agencia
  )

  const [currentAccount, setCurrentAccount] = React.useState<string>(
    form.proposal.proponente.dados_bancarios.numero_conta
  )

  const [bankSelect, setBankSelect] = React.useState<TSelected>({
    id: 0,
    name: form.proposal.proponente.dados_bancarios.nome_banco,
  })

  const [currentDigit, setCurrentDigit] = React.useState<string>(
    form.proposal.proponente.dados_bancarios.digito_conta
  )

  const [banksOption, setBankOption] = React.useState<TOptions[]>([])

  React.useEffect(() => {
    if (bankConsortiumData && bankConsortiumData.length) {
      const bankOption = getDataBank(
        bankConsortiumData,
        setBankSelect,
        (bank: string, code: string) => {
          if (bank) {
            setPaymentData({
              ...paymentData,
              bank,
              code,
            })
          }
        },
        updateForm
      )

      if (bankOption) {
        setBankOption(bankOption)
      }
    }
  }, [bankConsortiumData, paymentData, setPaymentData, updateForm])

  return (
    <s.WrapperPaymentData isDrawer={isDrawer}>
      <s.TitleText bold type="headline">
        {content.subtitle} {formatMoney(form.proposal.valor_demais_parcelas)}
      </s.TitleText>
      <s.RowPaymentData isDrawer={isDrawer}>
        <s.ColPaymentData col={12}>
          <s.SelectPaymentData
            data-testid="select-bank-id"
            label={content.label.bank}
            options={banksOption}
            selected={bankSelect}
            optionBoxSize={300}
          />
        </s.ColPaymentData>
        <s.InputsContainer isDrawer={isDrawer}>
          <s.ColPaymentData sm={isDrawer ? 12 : 5}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.agency}
              value={agency}
              placeholder={formDefault.placeholderInput}
              maxLength={6}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setAgency(
                  e.currentTarget.value.length === 5
                    ? e.currentTarget.value
                        .replace(/[^0-9]/g, '')
                        .replace(/^(\d{4})(\d{1})$/, '$1-$2')
                    : e.currentTarget.value.replace(/[^0-9]/g, '')
                )
                setPaymentData({
                  ...paymentData,
                  agency: e.currentTarget.value.replace('-', ''),
                  digit: e.currentTarget.value.substr(-1),
                })
                updateForm('numero_agencia', e.currentTarget.value)
              }}
            />
          </s.ColPaymentData>
          <s.ColPaymentData sm={isDrawer ? 12 : 5}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.currentAccount}
              value={currentAccount}
              maxLength={9}
              placeholder={formDefault.placeholderInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setCurrentAccount(e.currentTarget.value)
                setPaymentData({
                  ...paymentData,
                  currentAccount: e.currentTarget.value,
                })
                updateForm('numero_conta', e.currentTarget.value)
              }}
            />
          </s.ColPaymentData>
          <s.ColPaymentData sm={isDrawer ? 12 : 2}>
            <s.InputPaymentData
              data-testid="select-bank-id"
              label={content.label.digit}
              value={currentDigit}
              maxLength={1}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setCurrentDigit(e.currentTarget.value)
                setPaymentData({
                  ...paymentData,
                  digit: e.currentTarget.value,
                })
                updateForm('digito_conta', e.currentTarget.value)
              }}
            />
          </s.ColPaymentData>
        </s.InputsContainer>
      </s.RowPaymentData>
    </s.WrapperPaymentData>
  )
}

export default PaymentData
