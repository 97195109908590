export const Types = {
  GET_CLIENT_INFORMATION_REQUEST: 'GET_CLIENT_INFORMATION_REQUEST',
  GET_CLIENT_INFORMATION_SUCCESS: 'GET_CLIENT_INFORMATION_SUCCESS',
  GET_CLIENT_INFORMATION_FAILURE: 'GET_CLIENT_INFORMATION_FAILURE',
  GET_CLIENT_INFORMATION_INVALID: 'GET_CLIENT_INFORMATION_INVALID',
}

export interface IAction {
  type: keyof typeof Types
  payload: IClientInformationConsortium
}

export interface IClientInformationConsortiumState {
  data: IClientInformationConsortium | null
  isLoading: boolean
  hasError: boolean
  isInvalid: boolean
}

export interface IDocumentInformationClientConsortium {
  codigo: string
  numero: string
  orgao_emissor: string
  dt_expedicao: string
}

export interface IPepInformationClientConsortium {
  tipo: string
  grau_pep: string
  nome: string
  cpf: string
}

export interface IAddressInformationClientConsortium {
  cep: string
  logradouro: string
  numero: string
  bairro: string
  cidade: string
  complemento: string
  uf: string
  endereco_principal: boolean
}

export interface IPhoneInformationClientConsortium {
  ddi: string
  ddd: string
  numero: string
  tipo: string
}

export interface IFatcaInformationClientConsortium {
  possui_residencia_fiscal: boolean
  fatca: string
  pais_residencia_fiscal: string
  numero_identificacao_fiscal: string
  numero_passaporte: string
  passportType?: string
  pais_passaporte: string
}

export interface IProfessionDataInformationClientConsortium {
  codigo_profissao: string
  nome_empresa: string
  codigo_ramo_atividade: string
  enderecoComercial: IAddressInformationClientConsortium
  autonomo: string
  codigo_ccm: string
  numero_inscricao_estadual: string
  dt_inicio_atividade: string
  valor_renda_mensal: number
  valor_patrimonio: number
  possui_outras_rendas: boolean
  outra_profissao: string
  outro_ramo_atividade: string
  descricao_complemento_renda: string
  valor_mensal_outras_rendas: number
  codigo_procedencia: number
  codigo_tipo_aposentadoria: string
  descricao_tipo_aposentadoria: string
}

export interface IBankDataConsortium {
  codigo_banco: string
  nome_banco: string
  numero_agencia: string
  numero_conta: string
  digito_conta: string
  digito_agencia: string
}

export interface IClientInformationConsortium {
  nome: string
  cpf: string
  dt_nascimento: string
  codigo_nacionalidade: string
  codigo_pais_origem: string
  flag_estrangeiro: boolean
  nome_mae: string
  cidade_local_nascimento: string
  codigo_estado_civil: string
  nome_conjuge: string
  cpf_conjuge: string
  email: string
  observacao_declaracao_seguro_vida: string
  documento: IDocumentInformationClientConsortium
  pep: IPepInformationClientConsortium
  endereco_residencial: IAddressInformationClientConsortium
  telefone: string
  fatca: IFatcaInformationClientConsortium
  dados_profissionais: IProfessionDataInformationClientConsortium
  dados_bancarios: IBankDataConsortium
}
