import { all, takeLatest, call, put } from 'redux-saga/effects'

import { post, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { PostQuotesRequest, IQuoteReservationResponse, Types } from './types'
import { PostQuotesSuccess, PostQuotesFailure } from './action'

export function* postQuotes({
  request: { payload, onSuccess, onError },
}: PostQuotesRequest) {
  try {
    const { data }: AxiosResponse<IQuoteReservationResponse> = yield call(
      post,
      `${baseURLNamespaceConsortium}v2/consorcios/cotas/reservar`,
      payload
    )

    if (onSuccess) {
      onSuccess()
    }

    yield put(PostQuotesSuccess(data.numero_reserva))
  } catch (err) {
    if (onError) {
      onError()
    }
    yield put(PostQuotesFailure())
  }
}

export default all([takeLatest(Types.POST_QUOTES_REQUEST, postQuotes)])
