import React from 'react'

import { TOptions, TSelected } from 'components/Inputs/Select/types'
import { TextsConsortium } from 'pages/Consortium/commons'
import { IErrorResponse } from 'pages/Consortium/commons/ErrorForm/types'

import * as s from './styles'

interface ChangeSimulationDataDrawerProps {
  isOpen: boolean
  isValid: boolean
  isLoading: boolean
  statesOptions: TOptions[]
  selectedState: TSelected
  contemplatedValueInput: { value: string; touched: boolean }
  setContemplatedValueInput: React.Dispatch<
    React.SetStateAction<{
      value: string
      touched: boolean
    }>
  >
  handleClose: () => void
  handleMoneyChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  validateMoneyChange: () => false | IErrorResponse
  handleSimulationSubmit: () => void
}
const ChangeSimulationDataDrawer = ({
  handleClose,
  contemplatedValueInput,
  setContemplatedValueInput,
  isOpen,
  isLoading,
  handleSimulationSubmit,
  handleMoneyChange,
  validateMoneyChange,
  isValid,
  selectedState,
  statesOptions,
}: ChangeSimulationDataDrawerProps) => {
  const { buttons, creation } = TextsConsortium()

  return (
    <s.Drawer
      isOpen={isOpen}
      size="sm"
      forceHideOverflowY
      footerHeight="auto"
      drawerTitle={
        creation.steps.simulationResults.drawerTitleChangeDataSimulation
      }
      handleClose={handleClose}
      data-testid="extract-filter-drawer"
      footer={
        <s.Button onClick={handleSimulationSubmit} disabled={!isValid}>
          {buttons.updateSimulation}
        </s.Button>
      }
    >
      <s.Select
        testId="update-simulation-select"
        options={statesOptions}
        selected={selectedState}
        label="estado onde reside"
        optionBoxSize={220}
      />
      <s.UpdateSimulationTitle type="headline">
        {creation.steps.value.title}
      </s.UpdateSimulationTitle>
      <s.UpdateSimulationText type="body">
        {creation.steps.value.description}
      </s.UpdateSimulationText>

      <s.InputMoney
        data-testid="financial-application-money-input"
        data-gtm-form="input"
        data-gtm-name="quanto investir"
        maxLength={14}
        value={contemplatedValueInput.value}
        error={contemplatedValueInput.touched && validateMoneyChange()}
        placeholder={creation.steps.value.inputLabel}
        disabled={isLoading}
        onChange={handleMoneyChange}
        onBlur={() => {
          setContemplatedValueInput(prevState => ({
            ...prevState,
            touched: true,
          }))
        }}
      />
    </s.Drawer>
  )
}

export default ChangeSimulationDataDrawer
