import styled from 'styled-components'
import Drawer from 'components/Drawer'
import TextCmp from 'components/Text'
import { Divisor } from 'components'
import TextArea from 'components/TextArea'
import Checkbox from 'components/Inputs/Checkbox'
import ButtonCmp from 'components/Button'

export const DivisorCmp = styled(Divisor)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const WrapperDrawer = styled(Drawer)`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  max-width: 658px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
`

export const TitleLifeInsuranceDrawer = styled(TextCmp).attrs(() => ({
  type: 'headline',
  bold: true,
  color: 'gray6',
}))`
  align-self: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const ContainerText = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  & > div {
    display: flex;
    padding-left: ${({ theme }) => theme.spacing(4)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;

    & > span {
      margin-right: ${({ theme }) => theme.spacing(1)}px;
    }

    & > div {
      transform: translateY(-2px);
    }
  }
`

export const WrapperText = styled.div`
  display: flex;
`

export const ContentText = styled.div``

export const TextLifeInsuranceDrawer = styled.span`
  ${({ theme }) => theme.fontTypes.body};
  color: ${({ theme }) => theme.colors.gray5};
  font-weight: 400;
`

export const ContentLifeInsuranceDrawer = styled.div``

export const LinkLifeInsuranceDrawer = styled.span`
  ${({ theme }) => theme.fontTypes.body};
  color: ${({ theme }) => theme.colors.blue4};
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export const UlLifeInsuranceDrawer = styled.ol`
  padding-left: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  list-style-type: auto;

  li {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`

export const LiLifeInsuranceDrawer = styled.li`
  ${({ theme }) => theme.fontTypes.body};
  color: ${({ theme }) => theme.colors.gray5};
  font-weight: 400;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`

export const WrapperTextArea = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  textarea {
    width: 100%;
  }
`

export const CompTextArea = styled(TextArea)``

export const WrapperTextEnd = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  & > span {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const WrappperCheckbox = styled.div`
  align-self: flex-start;
`

export const Button = styled(ButtonCmp)`
  width: 100%;
`

export const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  & > button {
    &:last-child {
      border: 0;
      background: linear-gradient(
        135deg,
        ${({ theme }) => theme.colors.blue5} 0%,
        ${({ theme }) => theme.colors.blue4} 120%
      );
      color: ${({ theme }) => theme.colors.white};
      margin-left: ${({ theme }) => theme.spacing(3)}px;
    }
  }
`

export const CheckBoxCmp = styled(Checkbox)``
