import { cpf } from 'cpf-cnpj-validator'

import { dict } from 'components/IconExporter'

import { Proponent } from 'pages/Consortium/SolicitationProcess/Types/Form'

import { IConsortiumIncomeValidate } from 'store/modules/Consortium/IncomeValidate/types'

import { hasSpouse } from './PersonalDataCard/types'
import * as t from '../types'

import {
  validationErrorDateDispatch,
  validationErrorDocument,
  validationErrorExpeditedBody,
  validationErrorNIF,
  validationErrorPassportNumber,
} from './DocumentCard/utils'

const codState = [
  { cod: 'ACRE', uf: 'AC' },
  { cod: 'ALAGOAS', uf: 'AL' },
  { cod: 'AMAPA', uf: 'AP' },
  { cod: 'AMAZONAS', uf: 'AM' },
  { cod: 'BAHIA', uf: 'BA' },
  { cod: 'CEARA', uf: 'CE' },
  { cod: 'DISTRITO_FEDERAL', uf: 'DF' },
  { cod: 'ESPIRITO_SANTO', uf: 'ES' },
  { cod: 'GOIAS', uf: 'GO' },
  { cod: 'MARANHAO', uf: 'MA' },
  { cod: 'MATO_GROSSO', uf: 'MT' },
  { cod: 'MATO_GROSSO_DO_SUL', uf: 'MS' },
  { cod: 'MINAS_GERAIS', uf: 'MG' },
  { cod: 'PARA', uf: 'PA' },
  { cod: 'PARAIBA', uf: 'PB' },
  { cod: 'PARANA', uf: 'PR' },
  { cod: 'PERNANBUCO', uf: 'PE' },
  { cod: 'PIAUI', uf: 'PI' },
  { cod: 'RIO_DE_JANEIRO', uf: 'RJ' },
  { cod: 'RIO_GRANDE_DO_NORTE', uf: 'RN' },
  { cod: 'RIO_GRANDE_DO_SUL', uf: 'RS' },
  { cod: 'RONDONIA', uf: 'RO' },
  { cod: 'RORAIMA', uf: 'RR' },
  { cod: 'SANTA_CATARINA', uf: 'SC' },
  { cod: 'SAO_PAULO', uf: 'SP' },
  { cod: 'SERGIPE', uf: 'SE' },
  { cod: 'TOCANTINS', uf: 'TO' },
]

export const validForm = (
  formData: Proponent,
  grossMonthlyIncomeError: t.IValidationFunction | boolean
): boolean => {
  const personalDataForm = formData
  const address = formData.endereco_residencial
  const financial = formData.dados_profissionais
  const document = formData.documento
  const fatca = formData.fatca

  if (!personalDataForm.codigo_estado_civil) {
    return false
  }

  if (
    !address.bairro ||
    !address.cidade ||
    !address.logradouro ||
    !address.numero ||
    !address.cep ||
    !address.uf
  ) {
    return false
  }

  if (
    financial.codigo === undefined ||
    financial.valor_renda_mensal === 0 ||
    grossMonthlyIncomeError !== undefined
  ) {
    return false
  }

  if (document.codigo !== '') {
    if (
      validationErrorDocument(document.numero, true, document.codigo) !==
      undefined
    ) {
      return false
    }

    if (
      !document.dt_expedicao ||
      validationErrorDateDispatch(document.dt_expedicao, true) !== undefined
    ) {
      return false
    }

    if (
      document.codigo === 'RG' &&
      (!document.orgao_emissor ||
        validationErrorExpeditedBody(document.orgao_emissor, true) !==
          undefined)
    ) {
      return false
    }
  } else {
    return false
  }

  if (hasSpouse.includes(personalDataForm.codigo_estado_civil)) {
    if (
      !personalDataForm.cpf_conjuge ||
      !personalDataForm.nome_conjuge ||
      !cpf.isValid(String(personalDataForm.cpf_conjuge))
    ) {
      return false
    }
  }

  if (fatca && fatca.possui_residencia_fiscal) {
    if (
      !fatca.numero_identificacao_fiscal ||
      validationErrorNIF(fatca.numero_identificacao_fiscal, true) !==
        undefined ||
      !fatca.fatca ||
      !fatca.numero_passaporte ||
      validationErrorPassportNumber(fatca.numero_passaporte, true) !==
        undefined ||
      !document.codigo ||
      !fatca.pais_passaporte ||
      !fatca.pais_residencia_fiscal
    ) {
      return false
    }
  }

  return true
}

export const converteStateToUF = (uf: string): string => {
  if (uf.length === 2) {
    return uf
  }

  let stateCod = ''

  codState.forEach(item => {
    if (item.cod === uf) {
      stateCod = item.uf
    }
  })

  return stateCod
}

export const converteUFToState = (uf: string): string => {
  let stateCod = ''

  codState.forEach(item => {
    if (item.uf === uf) {
      stateCod = item.cod
    }
  })

  return stateCod
}

export const hasGrossMonthlyIncomeError = (
  grossMonthlyIncome: IConsortiumIncomeValidate
): boolean | { icon: keyof typeof dict; message: string } | undefined => {
  if (grossMonthlyIncome.isLoading) {
    return true
  }

  if (grossMonthlyIncome.hasError) {
    return true
  }

  if (grossMonthlyIncome.data && !grossMonthlyIncome.data.valido) {
    return {
      icon: 'information',
      message: grossMonthlyIncome.data.message.mensagem,
    }
  }

  return undefined
}
