import React from 'react'

import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'
import { StepComponentProps } from 'hooks/FluxManagement/types'
import { Steps } from '../../Types'

export default function PartnerOfferPRO() {
  const { getLast, getLength, instantiate } = useStackFluxManagement<
    StepComponentProps
  >()

  // Steps:
  // 1 - SIMULATION_PARTNER_PRO
  // 2 - CONTRACT_PARTNER_PRO

  React.useEffect(() => {
    if (getLength() === 0) instantiate(Steps.SIMULATION_PARTNER_PRO)
  }, [instantiate, getLength])

  return <div data-testid="consortium-vehicle-layer">{getLast()}</div>
}
