export const Types = {
  GET_EXTRACT_REQUEST: 'POINTS_GET_EXTRACT_REQUEST',
  GET_EXTRACT_SUCCESS: 'POINTS_GET_EXTRACT_SUCCESS',
  GET_EXTRACT_FAILURE: 'POINTS_GET_EXTRACT_FAILURE',
  GET_BALANCE_REQUEST: 'POINTS_GET_BALANCE_REQUEST',
  GET_BALANCE_SUCCESS: 'POINTS_GET_BALANCE_SUCCESS',
  GET_BALANCE_FAILURE: 'POINTS_GET_BALANCE_FAILURE',
  POST_REDEEM_POINTS_REQUEST: 'POST_REDEEM_POINTS_REQUEST',
  SET_REDEEM_LOADING: 'SET_REDEEM_LOADING',
}

interface IconsultaPontosResponseType {
  pontoasAvencer: string
  saldoAtual: string
  dataPontosAacumular: string
  pontosAacumular: string
  dataPontosAvencer: string
}

interface IextratoOptemaisType {
  saldoCliente: string
  saldoConsolidado: string
}

interface IMessage {
  TipoCritica: string
  Mensagem: string
}

export interface IPointRequest {
  consultaPontosResponseType: IconsultaPontosResponseType
  extratoOptemaisType: IextratoOptemaisType
  Message: IMessage
}
