import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { ApplicationState } from 'store/ApplicationState'

import PPEDrawer from '../../drawers/PPEDrawer'

import { CardDataType } from '../../types'
import * as s from '../../styles'
import { IPepType } from 'store/modules/Consortium/PpeTypes/types'

const PepData: React.FC<CardDataType> = ({
  proposalData: [proposalData, setProposalData],
}) => {
  const { hire } = TextsConsortium()

  const [isPepOpen, setPepIsOpen] = React.useState<boolean>(false)

  const { data } = useSelector(
    (state: ApplicationState) => state.consortiumPpeRelations
  )

  const normalizedPPEText = useCallback(() => {
    let ppeText = ''

    if (data) {
      switch (proposalData.proponente.pep.tipo) {
        case 'PEP':
          ppeText = 'Eu mesmo.'
          break

        case 'NAO_PEP':
          ppeText = 'Não.'
          break
        case 'RELACIONAMENTO_PROXIMO':
          data.forEach((item: IPepType) => {
            if (item.codigo === proposalData.proponente.pep.grau_pep) {
              ppeText = item.descricao
            }
          })
          break

        default:
          break
      }
    }

    return ppeText
  }, [data, proposalData.proponente])

  return (
    <>
      <PPEDrawer
        isOpen={isPepOpen}
        handleClose={() => setPepIsOpen(false)}
        proposalData={[proposalData, setProposalData]}
      />

      <s.cardStructure>
        <s.cardHeader>
          <s.cardHeaderTitle>
            {hire.steps.summary.pro.containerFifth.header.title}
          </s.cardHeaderTitle>
          <s.ButtonLink onClick={() => setPepIsOpen(true)} noIcon>
            {hire.steps.summary.pro.containerFifth.header.button}
            <s.Icon name="arrowright" color="blue4" />
          </s.ButtonLink>
        </s.cardHeader>
        <s.cardContent>
          {normalizedPPEText() ? (
            <s.cardContentItem>{normalizedPPEText()}</s.cardContentItem>
          ) : (
            <s.cardContentRevise>Não preenchido</s.cardContentRevise>
          )}
        </s.cardContent>
      </s.cardStructure>
    </>
  )
}

export default PepData
