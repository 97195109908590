import React from 'react'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import LifeInsuranceDrawer from '../../drawers/LifeInsuranceDrawer'

import * as s from '../../styles'
import { CardWithFormType } from '../../types'

const LifeInsuranceData: React.FC<CardWithFormType> = ({
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
}) => {
  const { hire } = TextsConsortium()

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  return (
    <>
      <LifeInsuranceDrawer
        isOpen={isDrawerOpen}
        handleClose={() => setIsDrawerOpen(false)}
        isValidForm={[isValidForm, setIsValidForm]}
        proposalData={[proposalData, setProposalData]}
      />
      <s.cardStructure>
        <s.cardHeader>
          <s.cardHeaderTitle>
            {hire.steps.summary.pro.containerTenth.header.title}
          </s.cardHeaderTitle>
          <s.ButtonLink onClick={() => setIsDrawerOpen(true)} noIcon>
            {hire.steps.summary.pro.containerTenth.header.button}
            {!isValidForm.lifeInsuranceForm && (
              <s.Notify name="ball" color="magenta2" />
            )}
            <s.Icon name="arrowright" color="blue4" />
          </s.ButtonLink>
        </s.cardHeader>
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerTenth.content.memberProposal}
            value={isValidForm.lifeInsuranceForm ? 'Sim' : 'Não'}
          />
        </s.cardContent>
        <s.Divider />
        <s.cardContent>
          <s.DataRow
            label={hire.steps.summary.pro.containerTenth.content.obsevations}
            value={proposalData.obs_seguro_vida}
            error={!proposalData.obs_seguro_vida}
          />
        </s.cardContent>
      </s.cardStructure>
    </>
  )
}

export default LifeInsuranceData
