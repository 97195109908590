import styled, { css } from 'styled-components'

import SelectComponent from 'components/Inputs/AutoCompleteSelect'
import MoneyInput from 'components/Inputs/Money'

const placeholderColor = css`
  & #prefix {
    color: ${({ theme }) => theme.colors.gray6};
  }
`

export const WrapperFinancialCard = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px 0`};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${({ theme }) => `0 ${theme.spacing(2)}px 0`};
  }

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 630px;
  }
`

export const DelimiterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.spacing(2)}px 0 ${theme.spacing(4)}`}px;
`

export const Select = styled(SelectComponent)`
  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-right: ${({ theme }) => `${theme.spacing(3)}px`};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 0;
  }
`

export const InputMoney = styled(MoneyInput)`
  margin-bottom: ${({ error, theme }) => !!error && theme.spacing(7)}px;
  ${({ value }) => !!value && placeholderColor}

  ${({ error }) =>
    error &&
    css`
      margin-bottom: 0;
    `}

  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-right: ${({ theme }) => `${theme.spacing(3)}px`};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 0;

    ${({ error }) =>
      error &&
      css`
        margin-bottom: ${({ theme }) => theme.spacing(4)}px;
      `}
  }
`
