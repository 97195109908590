import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { StepComponent } from 'hooks/FluxManagement/types'
import StepBar from 'components/StepBar'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'
import { ApplicationState } from 'store/ApplicationState'
import { SummaryItem } from '../Summary/localComponents/SummarySection/models'
import { formatMoney } from 'helpers'
import { Status, Summary, TextsConsortium } from 'pages/Consortium/commons'
import { summaryTitle } from './static'

import moment from 'moment'

import * as S from './styles'

const { hire, page } = TextsConsortium()

const {
  hire: {
    steps: {
      summary: { containerFirst, containerSecond, containerThird },
    },
  },
} = TextsConsortium()

const RequestResult: StepComponent = () => {
  const { form } = useFormFluxManagement<IFormConsortium>()
  const [formData, setFormData] = useState<SummaryItem[] | []>([])

  const { hasError, isLoading } = useSelector(
    (state: ApplicationState) => state.consortiumSendProposal
  )

  const status = hasError ? 'fail' : 'success'

  const formHandler = useCallback(() => {
    const AGENCY = `${form.proposal.proponente.dados_bancarios.numero_agencia}-${form.proposal.proponente.dados_bancarios.digito_agencia}`
    const ACCOUNT_NUMBER = `${form.proposal.proponente.dados_bancarios.numero_conta}-${form.proposal.proponente.dados_bancarios.digito_conta}`
    const SEGURO_VIDA =
      Number(form.proposal.seguro_vida) *
      (Number(form.proposal.valor_credito) / 100)

    const minimumDigitsHandler = (number: number, targetLength: number) => {
      const NUM = number
      const LENGTH = targetLength
      let output = `${NUM}`

      while (output.length < LENGTH) {
        output = `0${output}`
      }

      return `${output}`
    }

    const BANK_NAME = `${minimumDigitsHandler(
      Number(form.proposal.proponente.dados_bancarios.codigo_banco),
      3
    )} – ${form.proposal.proponente.dados_bancarios.nome_banco}`

    setFormData([
      {
        label: containerFirst.creditValue,
        value: formatMoney(`${form.proposal.valor_credito}`),
      },
      {
        label: containerFirst.reserveFund,
        value: `${form.proposal.fundo_reserva}%`.replace('.', ','),
      },
      {
        label: containerFirst.lifeInsurance,
        value: formatMoney(`${SEGURO_VIDA}`),
      },
      {
        label: containerFirst.administrativeFree,
        value: `${form.proposal.taxa_administrativa}%`,
      },
      {
        label: containerSecond.monthlyInstallment,
        value: formatMoney(`${form.proposal.valor_demais_parcelas}`),
      },
      {
        label: containerSecond.numberInstallment,
        value: `${form.proposal.prazo}`,
      },
      {
        label: containerSecond.dateForeseenFirstInstallment,
        value: String(
          moment(form.proposal.dt_venc_assembl, 'DD/MM/YYYY').format(
            'DD/MM/YYYY'
          )
        ),
      },
      {
        label: containerThird.firstInstallmentPaymentMethod,
        value:
          form.proposal.forma_pagamento_adesao === 'BOLETO_BANCARIO'
            ? 'Boleto'
            : 'Débito automático',
      },
      {
        label: containerThird.paymentRemainingInstallments,
        value: 'Débito automático em conta corrente',
      },
      { label: containerThird.bank, value: BANK_NAME },
      { label: containerThird.agency, value: AGENCY },
      { label: containerThird.currentAccount, value: ACCOUNT_NUMBER },
    ])
  }, [form])

  useEffect(() => {
    formHandler()
  }, [formHandler])

  return (
    <>
      <StepBar title={hire.title} type={page} showSteps={false} />
      <S.Container>
        <S.Row>
          <S.ColSummary md={3}>
            <Summary title={summaryTitle} items={formData} />
          </S.ColSummary>
          <S.ColStatus md={9}>
            {isLoading ? (
              <S.LoadingContainer>
                <S.Loading
                  type="bar"
                  legend="Carregando..."
                  textAlign="center"
                />
              </S.LoadingContainer>
            ) : (
              <Status status={status} />
            )}
          </S.ColStatus>
        </S.Row>
      </S.Container>
    </>
  )
}

export default RequestResult
