import React from 'react'
import { fitToMask } from 'react-masked'
import { useSelector } from 'react-redux'

import useMedia from 'hooks/useMedia'
import Divisor from 'components/Divisor'
import { dict } from 'components/IconExporter'
import { TOptions, TSelected } from 'components/Inputs/Select/types'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { CardType } from 'pages/Consortium/SolicitationProcess/Types/CardType'
import { ApplicationState } from 'store/ApplicationState'
import { ICivilStatus } from 'store/modules/Consortium/CivilStatus/types'
import { formatCpf } from 'helpers'
import {
  validationDocumentSpouse,
  validationSpouseName,
} from '../../../../PRO/SummaryProposal/drawers/PersonalDataDrawer/utils'

import { hasSpouse } from './types'
import * as s from './styles'

export interface errorObject {
  icon: keyof typeof dict
  message: string
}

const PersonalDataCard: React.FC<CardType> = ({
  formData: [formData, setFormData],
}) => {
  const [isTouched, setIsTouched] = React.useState({
    spouseName: false,
    spouseDocument: false,
  })

  const isSmUp = useMedia('smUp')

  const {
    formDefault,
    hire: {
      steps: {
        informationConfirmation: {
          containerFirst: { label },
        },
      },
    },
  } = TextsConsortium()

  const { civilStatusConsortium } = useSelector((state: ApplicationState) => ({
    civilStatusConsortium: state.consortiumCivilStatus.data,
  }))

  const [civilStatus, setCivilStatus] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const [isRestForm, setIsRestForm] = React.useState<boolean>(
    hasSpouse.includes(formData.codigo_estado_civil)
  )

  const [civilStatusOption, setCivilStatusOption] = React.useState<TOptions[]>(
    []
  )

  const updateFormState = React.useCallback(
    (key: string, value: string) => {
      setFormData({
        ...formData,
        [key]: value,
      })
    },
    [formData, setFormData]
  )

  React.useEffect(() => {
    if (civilStatusConsortium && civilStatusConsortium.length) {
      setCivilStatusOption(
        civilStatusConsortium.map((value: ICivilStatus) => ({
          id: value.codigo,
          text: value.descricao,
          onClick: () => {
            setCivilStatus({ id: value.codigo, name: value.descricao })
            updateFormState('codigo_estado_civil', value.codigo)
          },
        }))
      )

      if (civilStatus.id === 0 && civilStatus.name === '') {
        civilStatusConsortium.forEach((value: ICivilStatus) => {
          if (value.codigo === formData.codigo_estado_civil) {
            setCivilStatus({
              id: value.codigo,
              name: value.descricao,
            })
          }
        })
      }
    }
  }, [
    civilStatusConsortium,
    formData,
    civilStatus,
    setFormData,
    updateFormState,
  ])

  React.useEffect(() => {
    const isResult = hasSpouse.includes(formData.codigo_estado_civil)

    setIsRestForm(isResult)

    if (!isResult) {
      if (formData.nome_conjuge || formData.cpf_conjuge) {
        setFormData({
          ...formData,
          nome_conjuge: '',
          cpf_conjuge: '',
        })
      }
      setIsTouched({
        spouseDocument: false,
        spouseName: false,
      })
    }
  }, [formData, setFormData])

  return (
    <>
      <s.DataDisplay label={label.name} value={formData.nome} />
      <Divisor />
      <s.DataDisplay
        label={label.document}
        value={formatCpf(String(formData.cpf))}
      />
      <Divisor />
      <s.DataDisplay label={label.dateOfBirth} value={formData.dt_nascimento} />
      <Divisor />
      <s.DataDisplay
        label={label.nationality}
        value={
          formData.codigo_nacionalidade === 'BRASILEIRA' ? 'Brasileiro(a)' : ''
        }
      />
      <s.DelimiterWrapper>
        <s.Select
          data-testid="select-civil-status-id"
          label="Estado civil"
          options={civilStatusOption}
          selected={civilStatus}
          optionBoxSize={isSmUp ? 220 : 100}
        />
      </s.DelimiterWrapper>
      {isRestForm && (
        <>
          <s.DelimiterInputWrapper>
            <s.InputPersonalData
              label={label.spouseName}
              data-testid="input-spouse-name-id"
              placeholder={formDefault.placeholderInput}
              error={validationSpouseName(
                formData.nome_conjuge,
                isTouched.spouseName
              )}
              onBlur={() => setIsTouched({ ...isTouched, spouseName: true })}
              value={formData.nome_conjuge}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                updateFormState(
                  'nome_conjuge',
                  e.currentTarget.value.replace(/[0-9]/g, '')
                )
              }
              isLast
            />
            <s.InputPersonalData
              label="CPF do(a) cônjuge"
              placeholder="000.000.000-00"
              data-testid="input-spouse-document-id"
              error={validationDocumentSpouse(
                formData.cpf_conjuge,
                isTouched.spouseDocument
              )}
              onBlur={() =>
                setIsTouched({ ...isTouched, spouseDocument: true })
              }
              value={fitToMask(String(formData.cpf_conjuge), '999.999.999-99')}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateFormState(
                  'cpf_conjuge',
                  e.currentTarget.value.replace(/\D/g, '')
                )
              }}
              isLast
            />
          </s.DelimiterInputWrapper>
        </>
      )}
    </>
  )
}

export default PersonalDataCard
