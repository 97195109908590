import React, { memo } from 'react'
import { ModalContentProps } from './models'
import * as s from './styles'

const ModalContent: React.FC<ModalContentProps> = ({
  description,
  resetButton,
  continueButton,
}: ModalContentProps) => (
  <>
    <s.Description>{description}</s.Description>
    <s.ModalFooterWrapper>
      <s.GoBackButton outline onClick={resetButton.onClick}>
        {resetButton.title}
      </s.GoBackButton>
      <s.ContinueButton onClick={continueButton.onClick}>
        {continueButton.title}
      </s.ContinueButton>
    </s.ModalFooterWrapper>
  </>
)

export default memo(ModalContent)
