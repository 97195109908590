import React from 'react'
import { fitToMask } from 'react-masked'
import { useSelector } from 'react-redux'

import { maskRG } from 'helpers'

import { TSelected, TOptions } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { CardType } from 'pages/Consortium/SolicitationProcess/Types/CardType'

import { ApplicationState } from 'store/ApplicationState'
import { IDocument } from 'store/modules/Consortium/Documents/types'

import {
  placeholderExpediteBody,
  validationErrorDocument,
  validationErrorDateDispatch,
  validationErrorExpeditedBody,
} from '../utils'

import { IFormDocumentData } from '../types'
import * as s from '../styles'

const DocumentForm: React.FC<CardType> = ({
  formData: [formData, setFormData],
}) => {
  const {
    hire: {
      steps: {
        informationConfirmation: {
          containerThird: { label, placeholder, messageRNE },
        },
      },
    },
  } = TextsConsortium()

  /**
   * USESELECTOR
   */
  const { documentsConsortium } = useSelector((state: ApplicationState) => {
    if (state.consortiumDocuments.data) {
      return {
        documentsConsortium: state.consortiumDocuments.data,
      }
    }

    return {
      documentsConsortium: [],
    }
  })

  /**
   * USESTATE
   */
  const [fieldsTouched, setfieldsTouched] = React.useState<IFormDocumentData>({
    documentNumber: false,
    dateDispatch: false,
    expeditedBody: false,
  })

  const [documentTypeOption, setDocumentTypeOption] = React.useState<
    TOptions[]
  >([])

  const [documentTypeSelect, setDocumentTypeSelect] = React.useState<TSelected>(
    {
      id: formData.documento.codigo || 0,
      name: formData.documento.codigo || '',
    }
  )

  /**
   * USECALLBACK
   */
  const updateDocumentForm = React.useCallback(
    (codigo: string) => {
      setfieldsTouched({
        documentNumber: false,
        expeditedBody: false,
        dateDispatch: false,
      })

      setFormData({
        ...formData,
        documento: {
          codigo,
          numero: '',
          dt_expedicao: '',
          orgao_emissor: '',
        },
      })
    },
    [formData, setFormData, setfieldsTouched]
  )

  const setListOptionDocumentType = React.useCallback(
    (
      data: IDocument[],
      setMethod: React.Dispatch<React.SetStateAction<TOptions[]>>,
      setSelect: React.Dispatch<React.SetStateAction<TSelected>>,
      updateDocumentForm: (documentType: string) => void
    ) => {
      setMethod(
        data.map(value => ({
          id: value.codigo,
          text: value.descricao,
          onClick: () => {
            setSelect({ id: value.codigo, name: value.descricao })
            updateDocumentForm(value.codigo)
          },
        }))
      )
    },
    []
  )

  /**
   * USEFFECT
   */
  React.useEffect(() => {
    if (documentsConsortium && documentsConsortium.length > 0) {
      setListOptionDocumentType(
        documentsConsortium,
        setDocumentTypeOption,
        setDocumentTypeSelect,
        updateDocumentForm
      )
    }
  }, [documentsConsortium, setListOptionDocumentType, updateDocumentForm])

  const updateStateForm = (key: string, value: string) => {
    setFormData(prevState => {
      if (prevState) {
        return {
          ...prevState,
          documento: {
            ...prevState.documento,
            [key]: value,
          },
        }
      }
    })
  }

  const updatefieldTouched = React.useCallback(
    (key: string, value: boolean) => {
      setfieldsTouched({
        ...fieldsTouched,
        [key]: value,
      })
    },
    [fieldsTouched, setfieldsTouched]
  )

  const RNEPopover = () => (
    <s.Popover
      noMargin
      zIndex={14}
      placement="left"
      content={
        <s.TextContainer>
          <s.TextDescription color="gray5" bold>
            {messageRNE}
          </s.TextDescription>
        </s.TextContainer>
      }
    >
      <s.Icon name="information" color="blue4" width={21} />
    </s.Popover>
  )

  const formatExpediteBody = (TypedValue: string) => {
    const value = TypedValue.replace('SSP', '').replace('/', '')

    if (value !== '') {
      return 'SSP/' + value
    }

    return ''
  }

  return (
    <s.RowDocument>
      <s.ColDocument smAuto>
        <s.DelimiterWrapper>
          <s.Select
            data-testid="select-document-type-id"
            label={label.documentType}
            options={documentTypeOption}
            selected={documentTypeSelect}
          />
        </s.DelimiterWrapper>
      </s.ColDocument>
      <s.ColDocument sm={5}>
        {documentTypeSelect.name ? (
          <s.FormDocumentWrapper>
            <s.InputDocument
              errorLines={2}
              label={label.documentNumber}
              data-testid="input-document-number"
              suffix={documentTypeSelect.name === 'RNE' ? RNEPopover() : null}
              placeholder={placeholder.documentNumber}
              value={formData.documento.numero}
              maxLength={12}
              onBlur={() =>
                updatefieldTouched(
                  'documentNumber',
                  validationErrorDocument(
                    formData.documento.numero,
                    fieldsTouched.documentNumber,
                    documentTypeSelect.name || ''
                  )?.message !== ''
                )
              }
              error={validationErrorDocument(
                formData.documento.numero,
                fieldsTouched.documentNumber,
                documentTypeSelect.name
              )}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateStateForm(
                  'numero',
                  documentTypeSelect.id === 'RG'
                    ? maskRG(e.currentTarget.value)
                    : e.currentTarget.value.replace(/\D/g, '')
                )
              }}
            />
            <s.InputDocument
              errorLines={1}
              label={label.dateDispatch}
              value={fitToMask(formData.documento.dt_expedicao, '99/99/9999')}
              data-testid="input-date-dispatch"
              onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                updatefieldTouched('dateDispatch', e.currentTarget.value !== '')
              }
              placeholder={placeholder.dateDispatch}
              error={validationErrorDateDispatch(
                formData.documento.dt_expedicao,
                fieldsTouched.dateDispatch
              )}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateStateForm('dt_expedicao', e.currentTarget.value)
              }}
            />
            <s.InputDocument
              errorLines={2}
              label={label.expeditedBody}
              data-testid="input-spouse-name-id"
              maxLength={documentTypeSelect.name === 'RNE' ? 14 : 6}
              onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                updatefieldTouched(
                  'expeditedBody',
                  e.currentTarget.value !== ''
                )
              }
              value={
                documentTypeSelect.name === 'RNE'
                  ? 'CGPI/DIREX/DPF'
                  : formatExpediteBody(formData.documento.orgao_emissor)
              }
              disabled={documentTypeSelect.name === 'RNE'}
              placeholder={placeholderExpediteBody(
                documentTypeSelect,
                placeholder
              )}
              error={validationErrorExpeditedBody(
                formData.documento.orgao_emissor,
                fieldsTouched.expeditedBody
              )}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                updateStateForm(
                  'orgao_emissor',
                  e.currentTarget.value.toUpperCase()
                )
              }}
            />
          </s.FormDocumentWrapper>
        ) : null}
      </s.ColDocument>
    </s.RowDocument>
  )
}

export default DocumentForm
