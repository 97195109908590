import { Types, IClient, IUser } from './types'

export function GetTagRequest() {
  return {
    type: Types.GET_TAG_REQUEST,
  }
}

export function GetTagSuccess(payload: IClient) {
  return {
    type: Types.GET_TAG_SUCCESS,
    payload,
  }
}

export function GetTagFailure() {
  return {
    type: Types.GET_TAG_FAILURE,
  }
}

export function UpdateUserData(payload: IUser) {
  return {
    type: Types.UPDATE_USER_DATA,
    payload,
  }
}
