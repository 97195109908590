import React from 'react'

import PersonalData from 'pages/Consortium/SolicitationProcess/HireSteps/StepOne/PersonalDataForm/PersonalDataCard'
import ContactCard from 'pages/Consortium/SolicitationProcess/HireSteps/StepOne/PersonalDataForm/ContactCard'
import AddressCard from 'pages/Consortium/SolicitationProcess/HireSteps/StepOne/PersonalDataForm/AddressCard'
import DocumentCard from 'pages/Consortium/SolicitationProcess/HireSteps/StepOne/PersonalDataForm/DocumentCard'
import PpeCard from 'pages/Consortium/SolicitationProcess/HireSteps/StepOne/PersonalDataForm/PpeCard'
import FinancialCard from 'pages/Consortium/SolicitationProcess/HireSteps/StepOne/PersonalDataForm/FinancialCard'

import CardStructure from './CardStructure'
import { CardDataType } from '../../TypesPartnerOffer/Form'

import * as s from './styles'

const ConsortiumDataCard: React.FC<CardDataType> = ({
  proposalData: [proposalData, setProposalData],
}) => {
  const isFormEdit = React.useCallback(() => {
    const address = proposalData.endereco_residencial

    return (
      !address.bairro ||
      !address.cep ||
      !address.cidade ||
      !address.logradouro ||
      !address.numero ||
      !address.uf
    )
  }, [proposalData])

  return (
    <CardStructure>
      <s.TitleWrapper>Dados do consorciado</s.TitleWrapper>
      <s.WrapperForm>
        <s.TitleCardForm>dados pessoais</s.TitleCardForm>
        <PersonalData formData={[proposalData, setProposalData]} />
      </s.WrapperForm>

      <s.Divisor />

      <s.WrapperForm>
        <s.TitleCardForm>Dados para contato</s.TitleCardForm>
        <ContactCard formData={[proposalData, setProposalData]} />
      </s.WrapperForm>

      <s.Divisor />

      <s.WrapperForm>
        <s.TitleCardForm>Endereço</s.TitleCardForm>
        <AddressCard
          formData={[proposalData, setProposalData]}
          isFormEdit={isFormEdit()}
        />
      </s.WrapperForm>

      <s.Divisor />

      <s.WrapperForm>
        <s.TitleCardForm>Documentos</s.TitleCardForm>
        <DocumentCard formData={[proposalData, setProposalData]} />
      </s.WrapperForm>

      <s.Divisor />

      <s.WrapperForm>
        <s.TitleCardForm>pessoa politicamente exposta</s.TitleCardForm>
        <PpeCard formData={[proposalData, setProposalData]} />
      </s.WrapperForm>

      <s.Divisor />

      <s.WrapperForm>
        <s.TitleCardForm>Dados financeiros</s.TitleCardForm>
        <FinancialCard formData={[proposalData, setProposalData]} />
      </s.WrapperForm>
    </CardStructure>
  )
}

export default ConsortiumDataCard
