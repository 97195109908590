import styled from 'styled-components'
import LoadingCmp from 'components/Loading'
import {
  Container as SbgContainer,
  Row as SbgRow,
  Col as SbgCol,
} from 'styled-bootstrap-grid'

export const Container = styled(SbgContainer)`
  --step-bar-height: 56px;
  --min-height: calc(100% - var(--step-bar-height));

  display: flex;
  flex-direction: column;
  min-height: var(--min-height);
  padding: ${({ theme }) => theme.spacing(2)}px 0;

  @media ${props => props.theme.mediaQueries.smUp} {
    padding: ${({ theme }) => theme.spacing(3)}px 0;
    @media ${props => props.theme.mediaQueries.mdUp} {
      height: var(--min-height);
    }
  }

  @media ${props => props.theme.mediaQueries.sm} {
    padding: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const Row = styled(SbgRow)`
  flex-grow: 1;
  flex-direction: column;

  @media ${props => props.theme.mediaQueries.mdUp} {
    height: 100%;
    flex-direction: row;
  }
`

export const ColSummary = styled(SbgCol)`
  height: fit-content;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  @media ${props => props.theme.mediaQueries.mdUp} {
    height: 100%;
    margin-bottom: 0;
  }
`

export const ColStatus = styled(SbgCol)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const LoadingContainer = styled.div`
  max-width: 428px;
  margin: auto;
  display: flex;
  min-height: calc(100vh - 136px);
  align-self: center;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: 304px;
  }
`
export const Loading = styled(LoadingCmp)``
