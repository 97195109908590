import { capitalize } from 'helpers'

export const formatJobOption = (job: string): string => {
  return job
    .split(' ')
    .map(character =>
      character.length > 2 ? capitalize(character) : character.toLowerCase()
    )
    .join(' ')
}
