import { Dispatch } from 'react'

import { IToast } from 'hooks/toast'

import { capitalize } from 'helpers'

import { dict } from 'components/IconExporter'

import { IncomeValidateRequest } from 'store/modules/Consortium/IncomeValidate/action'
import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'
import { IConsortiumIncomeValidate } from 'store/modules/Consortium/IncomeValidate/types'

export const validationGrossMonthlyIncome = (
  valueSalary: string,
  proposalData: FormProposalConsortium,
  dispatch: Dispatch<any>,
  addToast: (toast: Omit<IToast, 'id'>) => void
): void => {
  const salary = Number(valueSalary.replace('.', '').replace(',', '.'))

  const planResponse = {
    codigo_grupo: proposalData?.grupo || '',
    numero_cota: Number(proposalData?.cota || ''),
    valor_parcela: Number(proposalData?.parcela_escolhida || ''),
  }

  dispatch(
    IncomeValidateRequest({
      payload: {
        valor_parcela_mensal: planResponse.valor_parcela,
        valor_renda_principal: salary,
        valor_outras_rendas: 0,
      },
      onErrorToast: addToast,
      onSuccess: () => {},
    })
  )
}

export const hasGrossMonthlyIncomeError = (
  grossMonthlyIncome: IConsortiumIncomeValidate
): boolean | { icon: keyof typeof dict; message: string } | undefined => {
  if (grossMonthlyIncome.isLoading) {
    return true
  }

  if (grossMonthlyIncome.hasError) {
    return true
  }

  if (grossMonthlyIncome.data && !grossMonthlyIncome.data.valido) {
    return {
      icon: 'information',
      message: grossMonthlyIncome.data.message.mensagem,
    }
  }

  return undefined
}

export const formatJobOption = (job: string): string => {
  return job
    .split(' ')
    .map(character =>
      character.length > 2 ? capitalize(character) : character.toLowerCase()
    )
    .join(' ')
}
