import React from 'react'
import { fitToMask } from 'react-masked'

import Divisor from 'components/Divisor'
import AddressForm from 'components/Form/AddressForm'
import { TSelected } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { CardType } from 'pages/Consortium/SolicitationProcess/Types/CardType'
import { Address } from 'pages/Consortium/SolicitationProcess/Types/Form/Address'

import { converteStateToUF } from '../utils'

import * as s from './styles'

const formatCep = (cep: string | number | undefined) => {
  if (cep) {
    return `${String(cep).slice(0, 5)}-${String(cep).slice(5)}`
  }

  return ''
}

interface AddressCardType extends CardType {
  isFormEdit: boolean
}

const AddressCard: React.FC<AddressCardType> = ({
  formData: [formData, setFormData],
  isFormEdit,
}) => {
  const {
    hire: {
      steps: {
        informationConfirmation: { containerFourth: texts },
      },
    },
  } = TextsConsortium()

  const [cep, setCep] = React.useState<string>(
    formatCep(formData.endereco_residencial.cep)
  )
  const [streetAddress, setStreetAddress] = React.useState<string>(
    formData.endereco_residencial.logradouro
  )
  const [number, setNumber] = React.useState<string>(
    formData.endereco_residencial.numero
  )
  const [complement, setComplement] = React.useState<string>(
    formData.endereco_residencial.complemento
  )
  const [neighbourhood, setNeighbourhood] = React.useState<string>(
    formData.endereco_residencial.bairro
  )
  const [city, setCity] = React.useState<string>(
    formData.endereco_residencial.cidade
  )
  const [type, setType] = React.useState<string>('Rua')
  const [state, setState] = React.useState<TSelected>({
    id: converteStateToUF(formData.endereco_residencial.uf) || 0,
    name: converteStateToUF(formData.endereco_residencial.uf) || '',
  })

  React.useEffect(() => {
    if (
      cep !== formData.endereco_residencial.cep ||
      streetAddress !== formData.endereco_residencial.logradouro ||
      number !== formData.endereco_residencial.numero ||
      complement !== formData.endereco_residencial.complemento ||
      neighbourhood !== formData.endereco_residencial.bairro ||
      city !== formData.endereco_residencial.cidade ||
      state.name !== formData.endereco_residencial.uf
    ) {
      setFormData({
        ...formData,
        endereco_residencial: {
          ...formData.endereco_residencial,
          complemento: complement,
          bairro: neighbourhood,
          numero: number,
          logradouro: streetAddress,
          cidade: city,
          uf: state.name || '',
          cep,
        },
      })
    }
  }, [
    formData,
    cep,
    streetAddress,
    number,
    complement,
    neighbourhood,
    city,
    state,
    setFormData,
  ])

  const customerAddress = (address: Address) => {
    const addressComplement =
      address.complemento.split(' ').join('') !== ''
        ? `- ${address.complemento} - `
        : ''

    return `${address.logradouro}, ${address.numero} ${addressComplement}${
      address.bairro
    } - ${address.cidade} - ${converteStateToUF(address.uf)}`
  }

  const dataDisplay = () => (
    <s.WrapperAddressCard data-testid="wrapper-address-card-id">
      <s.DataDisplay
        label={texts.label.zipCode}
        value={fitToMask(formData.endereco_residencial.cep, '99999-999')}
      />
      <Divisor />
      <s.DataDisplay
        label={texts.label.address}
        value={customerAddress(formData.endereco_residencial)}
      />
    </s.WrapperAddressCard>
  )

  return (
    <>
      {isFormEdit ? (
        <>
          <s.WrapperAddressForm data-testid="wrapper-address-form-id">
            <s.WrapperTextsAddressForm>
              <s.TextAddressCard>
                {texts.form.descriptionFirst}
              </s.TextAddressCard>
              <s.TextAddressCard>
                {texts.form.descriptionSecond}
              </s.TextAddressCard>
            </s.WrapperTextsAddressForm>
          </s.WrapperAddressForm>
          <s.AddressInputContainer>
            <AddressForm
              cep={[cep, setCep]}
              type={[type, setType]}
              streetAddress={[streetAddress, setStreetAddress]}
              number={[number, setNumber]}
              complement={[complement, setComplement]}
              neighbourhood={[neighbourhood, setNeighbourhood]}
              city={[city, setCity]}
              state={[state, setState]}
            />
          </s.AddressInputContainer>
        </>
      ) : (
        dataDisplay()
      )}
    </>
  )
}

export default AddressCard
