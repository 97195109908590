import React from 'react'
import { useParams } from 'react-router'

import { StepComponentProps } from 'hooks/FluxManagement/types'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'

import {
  IFormConsortium,
  Steps,
} from 'pages/Consortium/SolicitationProcess/Types'

import LoadData from '../../Components/LoadData'
import * as t from '../../HireSteps/StepOne/types'

import WrapperPro from './WrapperPro'
import { fluxLoading } from './steps'

const SummaryProposal: React.FC = () => {
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()

  const { redisKey } = useParams<{ redisKey: string }>()

  const { getLast, getLength, instantiate } = useStackFluxManagement<
    StepComponentProps
  >()

  React.useEffect(() => {
    if (getLength() === 0) instantiate(Steps.CLIENT_PRO)
  }, [instantiate, getLength])

  React.useEffect(() => {
    if (redisKey) {
      localStorage.setItem('redisKey', redisKey)
    }

    if (!form.proposal) {
      updateForm(t.INITIAL_STATE_FORM)
    }
  })

  return (
    <LoadData fluxLoading={fluxLoading} isPro>
      <WrapperPro>{getLast()}</WrapperPro>
    </LoadData>
  )
}

export default SummaryProposal
