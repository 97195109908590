import React, { useMemo } from 'react'
import 'styled-components/macro'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { Simulation } from 'store/modules/Consortium/ProposalSimulations/types'

import { formatMoney } from 'helpers'

import * as s from './styles'

interface PlanCardProps {
  simulation: Simulation
  isLoading: boolean
  planLoading: string
  isDisabled: boolean
  handleNextStep: (simulation: Simulation) => void
}
const PlanCard = ({
  simulation,
  handleNextStep,
  isDisabled,
  isLoading,
  planLoading,
}: PlanCardProps) => {
  const { buttons, creation } = TextsConsortium()

  const groupType = useMemo(() => {
    const isInProgress = simulation.tipo_grupo === 'EM_ANDAMENTO'
    const color = isInProgress ? 'primary' : ''
    const text = isInProgress ? 'grupo em andamento' : 'grupo em formação'
    return {
      color,
      text,
    }
  }, [simulation.tipo_grupo])

  const formatDecimalToPercentage = (value: number) =>
    `${value.toString().replace('.', ',')}%`

  return (
    <s.CardComponent>
      <s.Content>
        <div>
          <div>
            <s.Title>{creation.steps.simulationResults.cards.title}</s.Title>
          </div>
          <div>
            <s.ValueText>{formatMoney(simulation.valor_credito)}</s.ValueText>
          </div>
          <div>
            <s.Tag color={groupType.color as '' | 'primary' | 'secondary'}>
              {groupType.text}
            </s.Tag>
          </div>
        </div>
        <div>
          <s.SimulationInfoContainer>
            <s.VerticalList
              elements={[
                <s.SimulationInfoLabel>
                  {
                    creation.steps.simulationResults.cards.label
                      .monthlyInstallment
                  }
                </s.SimulationInfoLabel>,
                <s.SimulationInfoValue>
                  {formatMoney(simulation.valor_parcela)}
                </s.SimulationInfoValue>,
              ]}
            />
            <s.VerticalList
              elements={[
                <s.SimulationInfoLabel>
                  {
                    creation.steps.simulationResults.cards.label
                      .numberInstallment
                  }
                </s.SimulationInfoLabel>,
                <s.SimulationInfoValue>
                  {simulation.numero_participantes}
                </s.SimulationInfoValue>,
              ]}
            />
          </s.SimulationInfoContainer>
          <s.SimulationInfoContainer>
            <s.VerticalList
              elements={[
                <s.SimulationInfoLabel>
                  {creation.steps.simulationResults.cards.label.group}
                </s.SimulationInfoLabel>,
                <s.SimulationInfoValue>
                  {simulation.codigo_grupo}
                </s.SimulationInfoValue>,
              ]}
            />
            <s.VerticalList
              elements={[
                <s.SimulationInfoLabel>
                  {creation.steps.simulationResults.cards.label.quota}
                </s.SimulationInfoLabel>,
                <s.SimulationInfoValue>
                  {simulation.numero_cota}
                </s.SimulationInfoValue>,
              ]}
            />
          </s.SimulationInfoContainer>
        </div>
        <div>
          <s.Button
            onClick={() => handleNextStep(simulation)}
            loading={isLoading}
            loadingColor="green3"
            disabled={isDisabled && !(simulation.id === planLoading)}
            color="secondary"
          >
            {buttons.hire}
          </s.Button>
        </div>
        <s.Divider />
        <div>
          <s.TaxSummaryText>
            {creation.steps.simulationResults.cards.ratesSummary.title}
          </s.TaxSummaryText>
        </div>
        <div>
          <s.HorizontalList
            startItems={[
              <s.SimulationTaxLabel>
                {
                  creation.steps.simulationResults.cards.ratesSummary
                    .reserveFund
                }
              </s.SimulationTaxLabel>,
            ]}
            endItems={[
              <s.SimulationTaxValue>
                {formatDecimalToPercentage(simulation.fundo_reserva)}
              </s.SimulationTaxValue>,
            ]}
          />
        </div>
        <div>
          <s.HorizontalList
            startItems={[
              <s.SimulationTaxLabel>
                {
                  creation.steps.simulationResults.cards.ratesSummary
                    .administrativeFee
                }
              </s.SimulationTaxLabel>,
            ]}
            endItems={[
              <s.SimulationTaxValue>
                {formatDecimalToPercentage(simulation.taxa_administrativa)}
              </s.SimulationTaxValue>,
            ]}
          />
        </div>
        <div>
          <s.HorizontalList
            startItems={[
              <s.SimulationTaxLabel>
                {
                  creation.steps.simulationResults.cards.ratesSummary
                    .lifeInsurance
                }
              </s.SimulationTaxLabel>,
            ]}
            endItems={[
              <s.SimulationTaxValue>
                {formatDecimalToPercentage(simulation.seguro_vida)}
              </s.SimulationTaxValue>,
            ]}
          />
        </div>
      </s.Content>
    </s.CardComponent>
  )
}

export default PlanCard
