import styled, { css } from 'styled-components'
import {
  Container as ContainerCmp,
  Row as RowCmp,
  Col as ColCmp,
} from 'styled-bootstrap-grid'
import TextCmp from 'components/Text'
import DivisorCmp from 'components/Divisor'
import CardCmp from 'components/Card'
import UserCardCmp from 'components/Card/UserCard'
import SelectCmp from 'components/Inputs/Select'
import MoneyInputCmp from 'components/Inputs/Money'
import ButtonCmp from 'components/Button'
import PlanCardCmp from './PlanCard'

const placeholderColor = css`
  & #prefix {
    color: ${({ theme }) => theme.colors.gray6};
  }
`

export const Container = styled(ContainerCmp)`
  display: flex;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacing(3)}px;
`

export const Col = styled(ColCmp)``

export const Row = styled(RowCmp)``

export const SkeletonRow = styled(RowCmp)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

export const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px 0`};
`

export const Text = styled(TextCmp)``

export const SelectPlanText = styled(TextCmp)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const SendProposalText = styled(TextCmp)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const Divisor = styled(DivisorCmp)`
  margin: ${({ theme }) =>
    `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px 0`};
`

export const UserCard = styled(UserCardCmp)``

export const Card = styled(CardCmp)`
  padding: ${({ theme }) => theme.spacing(5)}px
    ${({ theme }) => theme.spacing(24)}px ${({ theme }) => theme.spacing(5)}px
    ${({ theme }) => theme.spacing(7)}px;
`

export const CardContainer = styled.div`
  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Select = styled(SelectCmp)``

export const InputMoney = styled(MoneyInputCmp)`
  ${({ value }) => !!value && placeholderColor}
`

export const FindProposalButton = styled(ButtonCmp).attrs(() => ({
  color: 'primary',
}))`
  width: 608px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
`

interface ICardsWrapper {
  cardsQuantity: number
}

export const CardsWrapper = styled.div<ICardsWrapper>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;

  ${({ cardsQuantity }) => cardsQuantity > 2 && `flex-wrap: wrap;`}

  @media ${({ theme }) => theme.mediaQueries.lgUp}{
    ${({ cardsQuantity }) =>
      cardsQuantity > 2 && `align-content: flex-start; justify-content: center`}
  }
`

export const PlanCard = styled(PlanCardCmp)`
  width: 407px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;

  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    &:nth-child(2n) {
      margin-right: 0px;
    }

    &:nth-child(odd) {
      margin-right: ${({ theme }) => theme.spacing(2)}px;
    }
    &:nth-child(n + 3) {
      margin-top: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`

const TextContainer = styled(TextCmp)`
  margin: ${({ theme }) => `${theme.spacing(6)}px auto`};
  width: 434px;
  text-align: center;
`

export const HintText = styled(TextContainer).attrs(() => ({
  type: 'headline',
  color: 'gray5',
}))``

export const ErrorText = styled(TextContainer).attrs(() => ({
  type: 'headline',
  color: 'magenta2',
}))``
