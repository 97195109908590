import { IToast } from 'hooks/toast'

export const Types = {
  POST_INCOME_VALIDATE_REQUEST: 'POST_INCOME_VALIDATE_REQUEST',
  POST_INCOME_VALIDATE_SUCCESS: 'POST_INCOME_VALIDATE_SUCCESS',
  POST_INCOME_VALIDATE_FAILURE: 'POST_INCOME_VALIDATE_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IIncomeValidateResponse
}

export interface IIncomeValidateRequest {
  valor_parcela_mensal: number
  valor_renda_principal: number
  valor_outras_rendas: number
}

export interface IIncomeValidateResponse {
  message: {
    tipo_critica: string
    mensagem: string
  }
  valido: boolean
}

export interface IPayload {
  type: keyof typeof Types
  payload: IIncomeValidateRequest
  onErrorToast: (toast: Omit<IToast, 'id'>) => void
  onSuccess?: () => void
}

export interface IConsortiumIncomeValidate {
  data: IIncomeValidateResponse | null
  isLoading: boolean
  hasError: boolean
}
