import styled from 'styled-components'

import Text from 'components/Text'
import DataShower from 'pages/Consortium/SolicitationProcess/Components/DataShower'

export const DataDisplay = styled(DataShower)`
  padding-left: ${({ theme }) => theme.spacing(2)}px;

  div {
    overflow-wrap: anywhere;
  }
`

export const WrapperAddressCard = styled.div`
  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    margin-left: 0;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const WrapperAddressForm = styled.div`
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) => `0 ${theme.spacing(2)}`}px;
  }

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    padding: ${({ theme }) => `0 ${theme.spacing(2)}`}px;
    width: 630px;
  }
`

export const AddressInputContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}`}px;

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    width: 630px;
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) =>
      `0 ${theme.spacing(3)}px ${theme.spacing(3)}px 0`};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0;
  }
`

export const WrapperTextsAddressForm = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const TextAddressCard = styled(Text).attrs(() => ({
  color: 'gray5',
  bold: true,
}))``
