import { TextsConsortium } from 'pages/Consortium/commons'
import { Summary } from './hooks'

const {
  hire: {
    steps: {
      summary: { containerFirst, containerSecond, containerThird },
    },
  },
} = TextsConsortium()

export const summaryMock: Summary = {
  consortium: {
    title: containerFirst.title,
    items: [
      {
        label: containerFirst.creditValue,
        value: 'R$ 127.264,50',
      },
      {
        label: containerFirst.reserveFund,
        value: '3%',
      },
      {
        label: containerFirst.lifeInsurance,
        value: 'R$ 20,89',
      },
      {
        label: containerFirst.administrativeFree,
        value: '18%',
      },
    ],
  },
  portion: {
    title: containerSecond.title,
    items: [
      {
        label: containerSecond.monthlyInstallment,
        value: 'R$ 1.345,29',
      },
      {
        label: containerSecond.numberInstallment,
        value: '200',
      },
      {
        label: containerSecond.dateForeseenFirstInstallment,
        value: '15/12/2020',
      },
    ],
  },
  payment: {
    title: containerThird.title,
    items: [
      {
        label: containerThird.firstInstallmentPaymentMethod,
        value: 'Boleto',
      },
      {
        label: containerThird.paymentRemainingInstallments,
        value: 'Débito automático em conta corrente',
      },
      {
        label: containerThird.bank,
        value: '001 – Banco do Brasil S.A.',
      },
      {
        label: containerThird.agency,
        value: '01234',
      },
      {
        label: containerThird.currentAccount,
        value: '051123-3',
      },
    ],
  },
}
