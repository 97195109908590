import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'styled-bootstrap-grid'
import 'styled-components/macro'

import useStackFluxManagement from 'hooks/FluxManagement/useStackFluxManagement'
import { StepComponentProps } from 'hooks/FluxManagement/types'
import useMedia from 'hooks/useMedia'
import Text from 'components/Text'
import { PostQuotesRequest } from 'store/modules/Consortium/QuoteReservation/action'
import { IQuoteReservation } from 'store/modules/Consortium/QuoteReservation/types'
import {
  IFormConsortium,
  TIPO_CONSORCIO,
  CODIGO_PRODUTO,
} from 'pages/Consortium/SolicitationProcess/Types'
import { useToast } from 'hooks/toast'
import { useFormFluxManagement } from 'hooks/FluxManagement'
import HorizontalList from 'components/HorizontalList'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { Simulation } from 'store/modules/Consortium/ProposalSimulations/types'
import { ApplicationState } from 'store/ApplicationState'

import PlanCard from './PlanCard'
import ChangeSimulationData from './ChangeSimulationData'

import { Steps } from '../../Types'
import * as s from './styles'

const SimulationResult: React.FC = () => {
  const { instantiate } = useStackFluxManagement<StepComponentProps>()
  const { buttons } = TextsConsortium()
  const isMobile = useMedia('xs')
  const dispatch = useDispatch()
  const { addToast } = useToast()
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()

  const [loadingPlanId, setLoadingPlanId] = useState('')
  const [isOpenChangeSimulationData, setIsOpenChangeSimulationData] = useState(
    false
  )

  const { data } = useSelector(
    (state: ApplicationState) => state.consortiumQuoteReservation
  )

  const {
    simulationsData,
    isQuoteReservationRequestLoading,
    cpf,
  } = useSelector((state: ApplicationState) => ({
    simulationsData: state.consortiumProposalSimulations.data,
    cpf: state.auth.cpf,
    isQuoteReservationRequestLoading:
      state.consortiumQuoteReservation.isLoading,
  }))

  React.useEffect(() => {
    if (data && !form.quoteReservation) {
      updateForm({
        ...form,
        proposal: {
          ...form.proposal,
          numero_sequencia_proposta: data,
        },
        quoteReservation: data,
      })
    }

    if (form.quoteReservation) {
      instantiate(Steps.HIRE_STEP_ONE)
    }
  }, [updateForm, instantiate, data, form])

  const onSubmit = (simulation: Simulation) => {
    const payload: IQuoteReservation = {
      codigo_produto: CODIGO_PRODUTO.AUTOMOVEL,
      codigo_grupo: simulation.codigo_grupo,
      numero_cota: simulation.numero_cota,
      uf: form.simulation.uf,
      prazo: simulation.prazo,
      valor_credito: simulation.valor_credito,
      valor_parcela: simulation.valor_parcela,
      fundo_reserva: simulation.fundo_reserva,
      taxa_administrativa: simulation.taxa_administrativa,
      seguro_vida: simulation.seguro_vida,
      codigo_bem: simulation.cod_bem,
      informacao_produto: simulation.informacao_produto,
      assemb_participacao: simulation.assemb_participacao,
      dt_assembleia: simulation.dt_assembleia,
      dt_avencto_assembl: simulation.dt_avencto_assembl,
      total_vagas: simulation.total_vagas,
      taxa_adesao: simulation.taxa_adesao,
      taxa_administracao: simulation.taxa_administracao,
      tipo_lance: simulation.tipos_de_lance,
      desc_produto: simulation.desc_produto,
      fd_reserva: simulation.fd_reserva,
      tipo_grupo: simulation.tipo_grupo,
      valor_primeira_parcela: simulation.valor_primeira_parcela,
      valor_demais_parcelas: simulation.valor_demais_parcelas,
      numero_participantes: simulation.numero_participantes,
      percentual_lance_fixo: simulation.percentual_lance_fixo,
      percentual_lance_livre: simulation.percentual_lance_livre,
      percentual_lance_embutido: simulation.percentual_lance_embutido,
      representante: 604377,
      cpf_cliente: cpf,
    }
    dispatch(
      PostQuotesRequest({
        payload,
        onSuccess: () => setLoadingPlanId(''),
        onError: () => {
          setLoadingPlanId('')
          addToast({
            type: 'error',
            title: 'Algo inesperado aconteceu',
            description: 'Tente novamente dentro de alguns instantes.',
          })
        },
      })
    )

    updateForm({
      ...form,
      proposal: {
        ...form.proposal,
        assemb_participacao: simulation.assemb_participacao,
        codigo_bem: '',
        codigo_canal: String(form.simulation.codigo_canal),
        codigo_produto: 'AUTOMOVEL',
        cota: simulation.numero_cota,
        credito_escolhido: simulation.valor_credito,
        data_primeiro_pagamento: simulation.dt_avencto_assembl,
        desc_produto: '',
        dt_assembleia: simulation.dt_assembleia,
        dt_venc_assembl: simulation.dt_avencto_assembl,
        fundo_reserva: simulation.fundo_reserva,
        grupo: simulation.codigo_grupo,
        informacao_produto: simulation.informacao_produto,
        numero_parcelas_escolhida: simulation.prazo,
        numero_participantes: simulation.numero_participantes,
        numero_sequencia_proposta: form.quoteReservation || 0,
        parcela_escolhida: simulation.valor_demais_parcelas,
        percentual_lance_embutido: simulation.percentual_lance_embutido,
        percentual_lance_fixo: simulation.percentual_lance_fixo || 0,
        percentual_lance_livre: simulation.percentual_lance_livre,
        prazo: simulation.prazo,
        produto_sugerido: simulation.produto_sugerido,
        representante: 604377,
        seguro_vida: simulation.seguro_vida,
        taxa_adesao: simulation.taxa_adesao,
        taxa_administrativa: simulation.taxa_administrativa,
        tipo_consorcio: TIPO_CONSORCIO.AUTOMOVEL,
        tipo_grupo: simulation.tipo_grupo,
        tipo_lance: simulation.tipos_de_lance,
        total_vagas: String(simulation.total_vagas),
        valor_credito: simulation.valor_credito,
        valor_demais_parcelas: simulation.valor_demais_parcelas,
        valor_primeira_parcela: simulation.valor_primeira_parcela,
      },
    })
  }

  const handleNextStep = (simulation: Simulation) => {
    setLoadingPlanId(simulation.id || 'uuid')
    onSubmit(simulation)
  }

  const renderHeader = () => (
    <s.HeaderWrapper>
      <HorizontalList
        noPadding
        size="small"
        startItems={[
          !isMobile ? (
            <Text color="gray4" type="headline">
              Simulação
            </Text>
          ) : null,
          <Text color="white" type="headline">
            Consórcio de Veículo
          </Text>,
        ]}
      />
    </s.HeaderWrapper>
  )

  const getJustify = () => {
    if (simulationsData && simulationsData.length === 1) {
      return `justify-content: center;`
    }
    return ''
  }

  const renderContent = () => (
    <>
      <s.BackgroundWrapper>
        <s.DarkBackground />
        <s.ContentSchemeContainer>
          <Row>
            <Col col={12}>
              {renderHeader()}
              <s.DividerContainer>
                <s.Divider />
              </s.DividerContainer>
            </Col>

            <s.ContentWrapper
              fullWidth={!!simulationsData && simulationsData.length > 2}
            >
              <s.TitleWrapper>
                {simulationsData && simulationsData.length > 1 ? (
                  <s.Title type={isMobile ? 'heading4' : 'heading3'}>
                    Escolha um dos planos mensais
                  </s.Title>
                ) : null}
              </s.TitleWrapper>
              <s.CardsWrapper>
                <s.PlanCardsFlexCol css={getJustify()}>
                  {simulationsData &&
                    simulationsData.map((simulation: Simulation) => (
                      <Col
                        lg={
                          !!simulationsData && simulationsData.length > 2
                            ? 4
                            : 6
                        }
                        md={6}
                        sm={6}
                        xs={12}
                        key={simulation.id}
                      >
                        <s.PlanCardWrapper>
                          <PlanCard
                            simulation={simulation}
                            planLoading={loadingPlanId}
                            isLoading={simulation.id === loadingPlanId}
                            isDisabled={isQuoteReservationRequestLoading}
                            handleNextStep={handleNextStep}
                          />
                        </s.PlanCardWrapper>
                      </Col>
                    ))}
                </s.PlanCardsFlexCol>
              </s.CardsWrapper>
            </s.ContentWrapper>
          </Row>
        </s.ContentSchemeContainer>
      </s.BackgroundWrapper>

      <s.Footer>
        <s.CmpButton
          data-gtm-name={buttons.changeData}
          data-gtm-type="click"
          data-gtm-clicktype="button"
          outline
          onClick={() => setIsOpenChangeSimulationData(true)}
        >
          {buttons.changeData}
        </s.CmpButton>
      </s.Footer>
      <ChangeSimulationData
        handleClose={() => setIsOpenChangeSimulationData(false)}
        isOpen={isOpenChangeSimulationData}
        isLoading={false}
      />
    </>
  )

  return (
    <s.Content autoHeight={!!simulationsData && simulationsData.length > 3}>
      {renderContent()}
    </s.Content>
  )
}
export default SimulationResult
