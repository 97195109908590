import styled from 'styled-components'
import Flexible from 'components/FlexibleLayerContent'
import { Content } from 'components/FlexibleLayerContent/styles'
import Text from 'components/Text'
import CardComponent from 'components/Card'
import DivisorComponent from 'components/Divisor'
import ButtonComponent from 'components/Button'

export const Card = styled(CardComponent)`
  flex-grow: 1;
  min-height: 267px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

export const FlexibleLayerContent = styled(Flexible)`
  ${Content} {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  height: fit-content;
`

export const ContainerIcon = styled.div`
  width: ${({ theme }) => theme.spacing(4)}px;
  height: ${({ theme }) => theme.spacing(4)}px;
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: ${({ theme }) => theme.spacing(50)}px;
  * {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Title = styled(Text)`
  @media ${props => props.theme.mediaQueries.xs} {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Subtitle = styled(Text)``

export const Divisor = styled(DivisorComponent)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Button = styled(ButtonComponent)`
  width: ${({ theme }) => theme.spacing(14)}px;
  align-self: flex-end;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;

  @media ${props => props.theme.mediaQueries.md} {
    width: 100%;
    margin: 0;
  }
`
