import { Col, Container, Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StepBar = styled.div`
  background-color: ${({ theme }) => theme.colors.grayblue1};
  height: ${({ theme }) => theme.spacing(8)}px;
  position: relative;
`

export const GridContainer = styled(Container)`
  @media ${props => props.theme.mediaQueries.mdUp} {
    padding: 0px;
  }
`

export const GridRowStepBar = styled(Row)`
  margin: 0;
  padding: 0 ${({ theme }) => theme.spacing(2)}px;
  @media ${props => props.theme.mediaQueries.xs} {
    padding: 0px;
  }
`

export const ColStepBar = styled(Col)`
  height: ${({ theme }) => theme.spacing(8)}px;
  display: flex;
  align-items: center;
  padding: 0;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
