import React from 'react'
import Drawer from 'components/Drawer'
import Modal from 'components/Modal/ModalNew'
import useMedia from 'hooks/useMedia'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import useFormFluxManagement from 'hooks/FluxManagement/useFormFluxManagement'
import { ITexts } from 'pages/Consortium/commons/Texts/types'
import { IFormConsortium } from 'pages/Consortium/SolicitationProcess/Types'
import { IPaymentDataForm } from '../StepTwo/types'
import * as c from '../StepTwo/cards'
import * as s from './styles'
import * as i from './types'

const ChangePaymentData: React.FC<i.IChangePaymentData> = ({
  isOpen,
  handleClose,
  ...rest
}) => {
  const [texts, setTexts] = React.useState<ITexts>(TextsConsortium())
  const { form, updateForm } = useFormFluxManagement<IFormConsortium>()
  const isMobile = useMedia('sm')
  const [paymentMethod, setPaymentMethod] = React.useState<string>(
    form.proposal.forma_pagamento_adesao || ''
  )

  const [paymentData, setPaymentData] = React.useState<IPaymentDataForm>({
    code: form.proposal.proponente.dados_bancarios.codigo_banco,
    bank: form.proposal.proponente.dados_bancarios.nome_banco,
    agency: form.proposal.proponente.dados_bancarios.numero_agencia,
    currentAccount: form.proposal.proponente.dados_bancarios.numero_conta,
    digit: form.proposal.proponente.dados_bancarios.digito_conta,
    agencyDigit: form.proposal.proponente.dados_bancarios.digito_agencia,
  })

  const handleClick = React.useCallback(() => {
    updateForm({
      ...form,
      proposal: {
        ...form.proposal,
        forma_pagamento_adesao: paymentMethod,
        proponente: {
          ...form.proposal.proponente,
          dados_bancarios: {
            codigo_banco: paymentData.code,
            nome_banco: paymentData.bank,
            numero_agencia: paymentData.agency,
            numero_conta: paymentData.currentAccount,
            digito_conta: paymentData.digit,
            digito_agencia: paymentData.agencyDigit,
          },
        }
      },
    })
    handleClose()
    return null
  }, [form, paymentData, paymentMethod, updateForm, handleClose])

  const renderContent = () => (
    <s.Wrapper>
      <s.CaptionText>
        {texts.hire.steps.payment.containerSecond.title}
      </s.CaptionText>
      <c.FirstInstallmentPayment
        paymentMethod={[paymentMethod, setPaymentMethod]}
        content={texts.hire.steps.payment.containerSecond}
        isDrawer
      />
      <s.CaptionTextSecond>
        {texts.hire.steps.payment.containerSecond.subtitleDrawer}
      </s.CaptionTextSecond>
      <c.PaymentData
        paymentData={[paymentData, setPaymentData]}
        content={texts.hire.steps.payment.containerThird}
        isDrawer
      />
    </s.Wrapper>
  )

  React.useEffect(() => {
    if (form && form.proposal && form.proposal.valor_primeira_parcela) {
      setTexts(TextsConsortium(''))
    }
  }, [form])

  const footerContent = () => (
    <s.Button
      data-gtm-name={texts.buttons.saveChanges}
      data-gtm-type="click"
      data-gtm-clicktype="button"
      onClick={() => handleClick()}
    >
      {texts.buttons.saveChanges}
    </s.Button>
  )

  return isMobile ? (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      modalTitle={texts.hire.steps.payment.drawerTitle}
      footer={footerContent()}
      {...rest}
    >
      {renderContent()}
    </Modal>
  ) : (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      drawerTitle={texts.hire.steps.payment.drawerTitle}
      footer={footerContent()}
      size="sm"
      {...rest}
    >
      {renderContent()}
    </Drawer>
  )
}

export default ChangePaymentData
