import { Types, IAction, IQuotesConsortiumState } from './types'

const INITIAL_STATE: IQuotesConsortiumState = {
  data: null,
  isLoading: false,
  hasError: false,
}

export default function(state = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case Types.POST_QUOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.POST_QUOTES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }
    case Types.POST_QUOTES_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    case Types.CLEAR_QUOTES:
      return {
        ...state,
        data: null,
        isLoading: false,
        hasError: false,
      }
    default:
      return state
  }
}
