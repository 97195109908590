import styled from 'styled-components'
import CardComponent from 'components/Card'
import { CardBody } from 'components/Card/styles'
import AccordionNewComponent from 'components/AccordionNew'
import Text from 'components/Text'

export const Card = styled(CardComponent)`
  padding: ${({ theme }) => theme.spacing(4)}px 0;
  min-height: 84px;
  max-height: 100%;
  display: flex;
  justify-content: center;
  ${CardBody} {
    justify-content: center;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
    padding-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const AccordionNew = styled(AccordionNewComponent)`
  padding: 0;
  height: 100%;
  overflow-y: auto;
`

export const ContainerTitle = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing(2)}px;
`

export const Title = styled(Text)`
  font-weight: 400;
`

export const List = styled.ul`
  list-style-type: none;
`

export const ListItem = styled.li``
