import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'

export const Types = {
  GET_PROPOSAL_REQUEST: 'GET_PROPOSAL_REQUEST',
  GET_PROPOSAL_SUCCESS: 'GET_PROPOSAL_SUCCESS',
  GET_PROPOSAL_FAILURE: 'GET_PROPOSAL_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: string
}
export interface IProposalConsortiumState {
  readonly data: FormProposalConsortium | null
  readonly errorMsg: string
  readonly isLoading: boolean
  readonly hasError: boolean
}
