import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { formatNumber } from 'helpers'

import { useToast } from 'hooks/toast'

import Loading from 'components/Loading'
import Modal from 'components/Modal/ModalNew'
import { TOptions, TSelected } from 'components/Inputs/Select/types'

import useMedia from 'hooks/useMedia'

import { ITexts } from 'pages/Consortium/commons/Texts/types'
import { TextsConsortium } from 'pages/Consortium/commons/Texts'

import { ApplicationState } from 'store/ApplicationState'
import { PostIncomeValidateFailure } from 'store/modules/Consortium/IncomeValidate/action'

import { DrawerType } from '../../types'
import { IFinancialForm } from './types'
import {
  validationGrossMonthlyIncome,
  hasGrossMonthlyIncomeError,
  formatJobOption,
} from './utils'
import * as s from './styles'
import { IJobs } from 'store/modules/Consortium/Jobs/types'

const FinancialDataDrawer: React.FC<DrawerType> = ({
  isOpen,
  proposalData: [proposalData, setProposalData],
  isValidForm: [isValidForm, setIsValidForm],
  handleClose,
}) => {
  const [texts] = React.useState<ITexts>(TextsConsortium())

  const dispatch = useDispatch()
  const { addToast } = useToast()
  const isMobile = useMedia('sm')

  const [financialData, setFinancialData] = React.useState<IFinancialForm>({
    profession: String(proposalData.proponente.dados_profissionais.codigo),
    grossMonthlyIncome: formatNumber(
      proposalData.proponente.dados_profissionais.valor_renda_mensal,
      { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'decimal' }
    ),
  })

  const [typingTimer, setTypingTimer] = React.useState(0)
  let typingValue: string = ''
  const doneTypingInterval = 1000
  const { data, isLoading } = useSelector(
    (state: ApplicationState) => state.consortiumJobs
  )

  const grossMonthlyIncome = useSelector(
    (state: ApplicationState) => state.consortiumIncomeValidate
  )

  const [jobsSelect, setJobsSelect] = React.useState<TSelected>({
    id: '',
    name: '',
  })

  const [jobsOption, setJobsOption] = React.useState<TOptions[]>([])
  const [isTouched, setIsTouched] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (data && data.length) {
      setJobsOption(
        data.map((value: IJobs) => {
          if (value.codigo === financialData.profession) {
            setJobsSelect({
              id: value.codigo,
              name: formatJobOption(value.descricao),
            })
          }

          return {
            id: value.codigo,
            text: formatJobOption(value.descricao),
            onClick: () => {
              setJobsSelect({
                id: value.codigo,
                name: formatJobOption(value.descricao),
              })
              setFinancialData({
                ...financialData,
                profession: value.codigo,
              })
            },
          }
        })
      )
    }
    if (!grossMonthlyIncome.data && !grossMonthlyIncome.isLoading) {
      validationGrossMonthlyIncome(
        financialData.grossMonthlyIncome,
        proposalData,
        dispatch,
        addToast
      )
    }
  }, [
    financialData,
    data,
    setFinancialData,
    grossMonthlyIncome,
    dispatch,
    addToast,
    proposalData,
  ])

  const suffixRender = () => {
    if (grossMonthlyIncome.isLoading) {
      return <Loading type="spinner" color="blue4" />
    }

    return ''
  }

  const isValidFinancialForm = React.useCallback(() => {
    return (
      !grossMonthlyIncome.data ||
      !grossMonthlyIncome.data.valido ||
      jobsSelect.id === 0
    )
  }, [grossMonthlyIncome, jobsSelect])

  const handleClick = React.useCallback(() => {
    setProposalData({
      ...proposalData,
      proponente: {
        ...proposalData.proponente,
        dados_profissionais: {
          ...proposalData.proponente.dados_profissionais,
          codigo: financialData.profession,
          valor_renda_mensal: Number(
            financialData.grossMonthlyIncome
              .split('.')
              .join('')
              .replace(',', '.')
          ),
        },
      },
    })

    setIsValidForm({
      ...isValidForm,
      financialForm: true,
    })

    handleClose()
  }, [
    proposalData,
    isValidForm,
    financialData,
    setIsValidForm,
    setProposalData,
    handleClose,
  ])

  const resetForm = () => {
    setJobsSelect({ id: '', name: '' })

    setFinancialData({
      profession: String(proposalData.proponente.dados_profissionais.codigo),
      grossMonthlyIncome: formatNumber(
        proposalData.proponente.dados_profissionais.valor_renda_mensal,
        { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'decimal' }
      ),
    })

    setIsTouched(false)

    handleClose()
  }

  const renderContent = () => (
    <>
      <s.Select
        data-testid="select-Profissão"
        label={texts.pro.financialDataDrawer.profession}
        options={jobsOption}
        selected={jobsSelect}
        optionBoxDirection="bottom"
        optionBoxSize={200}
        isLoading={isLoading}
      />

      <s.InputMoney
        data-testid="financial-money-input"
        value={
          financialData.grossMonthlyIncome === '0'
            ? ''
            : financialData.grossMonthlyIncome
        }
        label={texts.pro.financialDataDrawer.grossMonthlyIncome}
        placeholder={texts.pro.financialDataDrawer.placeholderInputMoney}
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
          setFinancialData({
            ...financialData,
            grossMonthlyIncome: target.value,
          })
        }}
        onKeyUp={e => {
          typingValue = e.currentTarget.value

          clearTimeout(typingTimer)

          dispatch(PostIncomeValidateFailure())

          setTypingTimer(
            Number(
              setTimeout(() => {
                validationGrossMonthlyIncome(
                  typingValue,
                  proposalData,
                  dispatch,
                  addToast
                )
              }, doneTypingInterval)
            )
          )
        }}
        onKeyDown={() => {
          clearTimeout(typingTimer)
          setTypingTimer(0)
        }}
        onBlur={() => {
          setIsTouched(true)
        }}
        error={
          isTouched ? hasGrossMonthlyIncomeError(grossMonthlyIncome) : false
        }
        suffix={suffixRender()}
      />
    </>
  )

  const footerContent = () => (
    <s.Button
      data-gtm-name={texts.buttons.saveChanges}
      data-gtm-type="click"
      data-gtm-clicktype="button"
      onClick={() => handleClick()}
      disabled={isValidFinancialForm()}
    >
      {texts.buttons.saveChanges}
    </s.Button>
  )

  return isMobile ? (
    <Modal
      isOpen={isOpen}
      handleClose={resetForm}
      modalTitle={texts.pro.personalDataDrawer.title}
      footer={footerContent()}
    >
      {renderContent()}
    </Modal>
  ) : (
    <s.Drawer
      isOpen={isOpen}
      handleClose={resetForm}
      drawerTitle={texts.pro.financialDataDrawer.title}
      footer={footerContent()}
      size="sm"
    >
      {renderContent()}
    </s.Drawer>
  )
}

export default FinancialDataDrawer
