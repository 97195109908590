import React from 'react'
import { useSelector } from 'react-redux'

import { TOptions, TSelected } from 'components/Inputs/Select/types'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { FormProposalConsortium } from 'pages/Consortium/SolicitationProcess/Types'
import { PEPType } from 'pages/Consortium/SolicitationProcess/Types/Form/ConsortiumPEPType'

import { ApplicationState } from 'store/ApplicationState'
import { IPepType } from 'store/modules/Consortium/PpeTypes/types'

import * as s from './styles'
import { TRadioOptions } from '../../types'

interface Props {
  proposalData: FormProposalConsortium
  ppe: [PEPType, React.Dispatch<React.SetStateAction<PEPType>>]
  ppeSelect: [TSelected, React.Dispatch<React.SetStateAction<TSelected>>]
}

const PEPContent: React.FC<Props> = ({
  proposalData,
  ppe: [ppeData, setPpeData],
  ppeSelect: [ppeSelect, setPpeSelect],
}) => {
  const {
    pro: {
      summaryProposal: {
        politicallyExposedPerson: { drawer },
      },
    },
  } = TextsConsortium()

  const getSelectOptions = (optionsData: IPepType[] | null) => {
    const options: TOptions[] = [
      {
        id: 'PEP',
        text: 'Eu mesmo',
        onClick: () => {
          setPpeData({
            ...ppeData,
            tipo: 'PEP',
            grau_pep: '',
          })
          setPpeSelect({ id: 'PEP', name: 'Eu mesmo' })
        },
      },
    ]

    if (proposalData.proponente.pep.tipo === 'PEP' && ppeSelect.name === '') {
      setPpeSelect({ id: 'PEP', name: 'Eu mesmo' })
    }

    if (optionsData) {
      optionsData.forEach((option: IPepType) => {
        options.push({
          id: option.codigo,
          text: option.descricao,
          onClick: () => {
            setPpeData({
              ...ppeData,
              grau_pep: option.codigo,
              tipo: 'RELACIONAMENTO_PROXIMO',
            })
            setPpeSelect({ id: option.codigo, name: option.descricao })
          },
        })

        if (ppeSelect.id === option.codigo && ppeSelect.name === '') {
          setPpeSelect({ id: option.codigo, name: option.descricao })
        }
      })
    }

    return options
  }

  const getRadionOptions = (optionsData: IPepType[] | null) => {
    const options: TRadioOptions[] = []

    if (optionsData && optionsData.length) {
      optionsData.forEach((element: IPepType) => {
        if (element.codigo !== 'RELACIONAMENTO_PROXIMO') {
          options.push({
            value: element.codigo,
            name: element.descricao,
          })
        }
      })
    }

    if (options.length === 0) {
      return [
        {
          value: '1',
          name: 'Sim',
        },
        {
          value: '2',
          name: 'Não',
        },
      ]
    }

    return options
  }

  const { pepConsortiumRelations, ConsortiumPpeType } = useSelector(
    (state: ApplicationState) => {
      return {
        pepConsortiumRelations: getSelectOptions(
          state.consortiumPpeRelations.data
        ),
        ConsortiumPpeType: getRadionOptions(state.consortiumPpeType.data),
      }
    }
  )

  return (
    <>
      <s.TextTitlePpe>{drawer.question1.question}</s.TextTitlePpe>
      <s.TextPpe>{drawer.question1.description}</s.TextPpe>
      <s.RadioOptionsWrapper data-testid="radio-ppe">
        <s.RadioPpeCard
          options={ConsortiumPpeType}
          checkedItem={
            ppeData.tipo !== 'NAO_PEP'
              ? ConsortiumPpeType[0].value
              : ConsortiumPpeType[1].value
          }
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setPpeData({
              ...ppeData,
              tipo: e.currentTarget.value,
              grau_pep: '',
            })
          }
        />
      </s.RadioOptionsWrapper>
      {ppeData.tipo !== 'NAO_PEP' ? (
        <s.DelimiterWrapper>
          <s.Select
            label={drawer.question2.question}
            options={pepConsortiumRelations}
            selected={ppeSelect}
            data-testid="ppe-select"
            autoComplete={false}
          />
        </s.DelimiterWrapper>
      ) : null}
    </>
  )
}

export default PEPContent
