import ChosePlan from '../SimulationSteps/ChosePlan'
import ChooseState from '../SimulationSteps/ChooseState'
import ContemplatedValue from '../SimulationSteps/ContemplatedValue'
import SimulationLoading from '../SimulationSteps/SimulationsState'
import StepOne from '../HireSteps/StepOne'
import StepTwo from '../HireSteps/StepTwo'
import StepThree from '../HireSteps/StepThree'
import Summary from '../HireSteps/Summary'
import RequestResultPro from '../PRO/RequestResult'
import RequestResult from '../HireSteps/RequestResult'
import ContractData from '../PRO/PartnerOffer/ContractData'
import SimulationStep from '../PRO/PartnerOffer/SimulationStep'
import Proposal from '../PRO/SummaryProposal/Proposal'

export const Steps = {
  CHOOSE_STATE: ChooseState,
  CONTENPLATED: ContemplatedValue,
  SIMULATIONS_STATE: SimulationLoading,
  CHOOSE_PLAN: ChosePlan,
  HIRE_STEP_ONE: StepOne,
  HIRE_STEP_TWO: StepTwo,
  HIRE_STEP_THREE: StepThree,
  SUMMARY_STEP: Summary,
  REQUEST_RESULT: RequestResult,
  SIMULATION_PARTNER_PRO: SimulationStep,
  CONTRACT_PARTNER_PRO: ContractData,
  REQUEST_RESULT_PRO: RequestResultPro,
  CLIENT_PRO: Proposal,
}
