import { all, takeLatest, call, put } from 'redux-saga/effects'

import { post, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { IPayload, IReturnRequestType, Types } from './types'
import { PostSendProposalSuccess, PostSendProposalFailure } from './action'

export function* postSendProposal({ payload }: IPayload) {
  try {
    const { data }: AxiosResponse<IReturnRequestType> = yield call(
      post,
      `${baseURLNamespaceConsortium}v2/consorcios/propostas/transmitir`,
      payload
    )

    yield put(PostSendProposalSuccess(data))
  } catch (err) {
    yield put(PostSendProposalFailure())
  }
}

export default all([
  takeLatest(Types.POST_SEND_PROPOSAL_REQUEST, postSendProposal),
])
