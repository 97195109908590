export enum Types {
  FLUX_MANAGEMENT_ADD_TO_STACK = 'FLUX_MANAGEMENT_ADD_TO_STACK',
  FLUX_MANAGEMENT_POP_FROM_STACK = 'FLUX_MANAGEMENT_POP_FROM_STACK',
  FLUX_MANAGEMENT_UPDATE_FORM = 'FLUX_MANAGEMENT_UPDATE_FORM',
  FLUX_MANAGEMENT_RESET_FLUX = 'FLUX_MANAGEMENT_RESET_FLUX',
  FLUX_MANAGEMENT_RESET_FORM = 'FORM_MANAGEMENT_RESET_FLUX',
}

export interface ApplicationState {
  stack: React.ReactElement[]
  form: Record<string, never>
}

export interface ApplicationAction {
  stack: []
  form: any
  updatedKeys: any
  element: any
  type: string
}
