import { all, takeLatest, call, put } from 'redux-saga/effects'

import { get, baseURLNamespaceConsortium } from 'services/api'
import { AxiosResponse } from 'axios'
import { IBank, IBankConsortium, Types } from './types'
import { GetBankSuccess, GetBankFailure } from './action'

export function* getBank() {
  try {
    const { data }: AxiosResponse<IBankConsortium> = yield call(
      get,
      `${baseURLNamespaceConsortium}v2/consorcios/dados/bancos`
    )

    const banks = data.bancos.sort((a: IBank, b: IBank) => {
      if (a.descricao > b.descricao) {
        return 1
      }
      if (a.descricao < b.descricao) {
        return -1
      }
      return 0
    })

    yield put(GetBankSuccess(banks))
  } catch (err) {
    yield put(GetBankFailure())
  }
}

export default all([takeLatest(Types.GET_BANK_REQUEST, getBank)])
