import { IToast } from 'hooks/toast'
import { Types, IProposalSimulationsRequest, Simulation } from './types'

export function PostProposalSimulationsRequest({
  payload,
  onErrorToast,
  onSuccess,
}: {
  payload: IProposalSimulationsRequest
  onErrorToast: (toast: Omit<IToast, 'id'>) => void
  onSuccess: () => void
}) {
  return {
    type: Types.POST_PROPOSAL_SIMULATIONS_REQUEST,
    payload,
    onErrorToast,
    onSuccess,
  }
}

export function PostProposalSimulationsuccess(payload: Simulation[]) {
  return {
    type: Types.POST_PROPOSAL_SIMULATIONS_SUCCESS,
    payload,
  }
}

export function PostProposalSimulationsFailure() {
  return {
    type: Types.POST_PROPOSAL_SIMULATIONS_FAILURE,
  }
}

export function ProposalSimulationsClearData() {
  return {
    type: Types.PROPOSAL_SIMULATIONS_CLEAR_DATA,
  }
}
