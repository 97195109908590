import styled from 'styled-components'
import Text from 'components/Text'
import PopoverCmp from 'components/Popover'
import InputText from 'components/NewInput'

export interface IOptionsWrapper {
  optionBoxDirection: 'top' | 'bottom'
  optionBoxSize: number
  optionPadding: number
}

export interface IContainerWrapper {
  autoComplete: 'relative' | 'absolute'
  isOptionsOpen: boolean
}

export const Container = styled.div`
  position: relative;
`

export const InputContainer = styled.div<IContainerWrapper>`
  position: relative;

  &:after {
    content: '';
    cursor: pointer;
    height: 100%;
    left: 0;
    position: ${({ autoComplete }) => autoComplete};
    top: 0;
    width: 100%;
  }

  ${({ isOptionsOpen, theme }) => {
    if (isOptionsOpen) {
      return `border-bottom: 2px solid ${theme.colors.blue4};
    #prefix,
    #suffix {
      color: ${theme.colors.gray6};
    }`
    }
  }}
`

export const Input = styled(InputText)`
  cursor: pointer;
`

export const OptionsWrapper = styled.div<IOptionsWrapper>`
  position: absolute;
  width: 100%;
  z-index: 14;
  box-shadow: ${({ theme }) => theme.shadows.gray2};
  max-height: ${({ optionBoxSize }) => `${optionBoxSize}px`};
  padding: ${({ theme }) => `${theme.spacing(2)}px 0`};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ optionBoxDirection, theme, optionPadding }) =>
    optionBoxDirection === 'top'
      ? `bottom: ${theme.spacing(10)}px`
      : `top: ${theme.spacing(optionPadding)}px`};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    max-height: 165px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.grid.breakpoints.sm}px) and (orientation: landscape) {
    max-height: 110px;
  }
`

interface IOptionsItem {
  selected?: boolean
}

export const OptionsItem = styled.div<IOptionsItem>`
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.gray1 : theme.colors.white};
  cursor: pointer;
  display: flex;
  height: 49px;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
  text-transform: capitalize;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray1};
  }
  svg {
    margin-right: ${({ theme }) => theme.spacing(3)}px;
  }
`

export const Overlay = styled.div`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
`

export const TooltipText = styled(Text)`
  width: 213px;
`

export const Popover = styled(PopoverCmp)`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(4)}px 0
    ${({ theme }) => theme.spacing(4)}px 0;
`
