export const Types = {
  GET_PEP_RELATIONSHIP_REQUEST: 'GET_PEP_RELATIONSHIP_REQUEST',
  GET_PEP_RELATIONSHIP_SUCCESS: 'GET_PEP_RELATIONSHIP_SUCCESS',
  GET_PEP_RELATIONSHIP_FAILURE: 'GET_PEP_RELATIONSHIP_FAILURE',
}

export interface IAction {
  type: keyof typeof Types
  payload: IPepRelationshipResponse
}

export interface IPepType {
  codigo: string
  descricao: string
}

export interface IPepRelationshipResponse {
  graus_relacionamento: IPepType[]
}

export interface IPayload {
  type: keyof typeof Types
}

export interface IConsortiumPepRelationship {
  data: IPepType[] | null
  isLoading: boolean
  hasError: boolean
}
