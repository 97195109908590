import React from 'react'

import { IUseHandleScrollReturn } from './types'

const useHandleScroll = (sectionsQuantity: number): IUseHandleScrollReturn => {
  const [activeMenuItem, setActiveMenuItem] = React.useState('section-1')
  const sectionOne = React.useRef<HTMLTableSectionElement | null>(null)
  const sectionTwo = React.useRef<HTMLTableSectionElement | null>(null)
  const sectionThree = React.useRef<HTMLTableSectionElement | null>(null)

  const setActiveSection = (section: string) => {
    setActiveMenuItem(section)
  }

  const scrollListenerFunc = React.useCallback(() => {
    const layerBody = window.document.getElementById('scroll-container')
    let sectionOnePos = null
    let sectionTwoPos = null
    let sectionThreePos = null

    if (
      !!sectionOne.current &&
      !!sectionTwo.current &&
      !!sectionThree.current
    ) {
      sectionOnePos = sectionOne.current.offsetTop
      sectionTwoPos = sectionTwo.current.offsetTop
      sectionThreePos = sectionThree.current.offsetTop

      if (sectionsQuantity === 3 && !!sectionThree.current) {
        sectionThreePos = sectionThree.current.offsetTop
      }

      if (layerBody !== null) {
        const { offsetHeight, scrollTop, scrollHeight } = layerBody

        if (
          offsetHeight + scrollTop >= scrollHeight ||
          (sectionThreePos && scrollTop >= sectionThreePos)
        ) {
          setActiveMenuItem('section-3')
        } else if (scrollTop <= sectionOnePos) {
          setActiveMenuItem('section-1')
        } else if (scrollTop > sectionOnePos && scrollTop <= sectionTwoPos) {
          setActiveMenuItem('section-2')
        }
      }
    }
  }, [sectionsQuantity])

  React.useEffect(() => {
    setActiveMenuItem('section-1')
  }, [sectionsQuantity])

  React.useLayoutEffect(() => {
    const section = document.querySelector('#scroll-container')
    if (sectionOne.current) {
      return section?.scrollTo({
        top: sectionOne.current.offsetTop,
      })
    }
  }, [])

  React.useEffect(() => {
    const layerBody = window.document.getElementById('scroll-container')
    if (layerBody !== null) {
      layerBody.addEventListener('scroll', scrollListenerFunc)
    }
    return () => layerBody?.removeEventListener('scroll', scrollListenerFunc)
  }, [scrollListenerFunc])

  React.useLayoutEffect(() => {
    document.querySelector('html')?.setAttribute('style', 'overflow-y: hidden;')
  }, [])

  const scrollToSection = (ref: React.RefObject<HTMLElement>) => {
    const section = document.querySelector('#scroll-container')

    if (ref.current) {
      return section?.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      })
    }
    return false
  }

  return {
    activeMenuItem,
    sectionOne,
    sectionTwo,
    sectionThree,
    setActiveSection,
    scrollToSection,
  }
}

export default useHandleScroll
