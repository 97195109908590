import styled from 'styled-components'
import {
  Container as ContainerComp,
  Row as RowComp,
  Col,
} from 'styled-bootstrap-grid'
import { Text, Button as ButtonComp } from 'components'
import { themeColors } from 'styles/theme'

export const Container = styled(ContainerComp)`
  margin: ${({ theme }) => `${theme.spacing(3)}px auto 0px auto`};
`

export const Row = styled(RowComp)``

export const ContainerPageTip = styled(Col).attrs(() => ({
  lg: 3,
  md: 10,
  mdOffset: 1,
  lgOffset: 0,
}))`
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    height: fit-content;
    position: sticky;
    top: 0;
  }
`
export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(10)}`}px;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    flex-direction: row;
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) => `0 ${theme.spacing(1)}px ${theme.spacing(3)}`}px;
  }
  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${({ theme }) => `0 ${theme.spacing(1)}px ${theme.spacing(10)}`}px;
  }
`
export const Column = styled(Col)`
  padding: 0;
`
export const ColumnSide = styled(Col)`
  margin-bottom: 0;
  @media ${({ theme }) => theme.mediaQueries.lgUp} {
    position: sticky;
    top: ${({ theme }) => theme.spacing(3)}px;
  }
`
export const SummaryContent = styled(Col)`
  padding-bottom: 0;
  @media ${({ theme }) => theme.mediaQueries.mdUp} {
    @media ${({ theme }) => theme.mediaQueries.lg} {
      padding-bottom: 0;
    }
    padding: 0;
  }
  @media ${({ theme }) => theme.mediaQueries.md} {
    padding: 0;
  }
`

export const ButtonLinkTitle = styled(Text).attrs(() => ({
  type: 'button',
  bold: true,
  color: 'blue4',
  textAlign: 'right',
}))``

export const Button = styled(ButtonComp).attrs(() => ({
  color: 'secondary',
  loadingColor: 'white' as keyof typeof themeColors,
}))`
  min-width: 136px;
  background: ${({
    theme: {
      colors: { green5, green4 },
    },
  }) => `linear-gradient(to left, ${green5} -35%, ${green4})`};

  margin-right: ${({ theme }) => theme.spacing(4)}px;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    width: 100%;
  }
`
