import React, { useMemo } from 'react'
import { IAvatar } from './types'
import * as S from './styles'

export const initials = (fullName: string): string => {
  const [firstName, secondName] = fullName.split(' ').map(i => i.toUpperCase())
  if (secondName) return firstName.charAt(0) + secondName.charAt(0)
  if (firstName.length >= 2) return firstName.charAt(0) + firstName.charAt(1)
  return firstName.charAt(0)
}

const Avatar: React.FC<IAvatar> = ({ name }: IAvatar) =>
  useMemo(
    () => (
      <S.Container data-testid="user-card-avatar">
        <S.Initials {...S.InitialsProps}>{initials(name)}</S.Initials>
      </S.Container>
    ),
    [name]
  )

export default Avatar
