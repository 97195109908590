import styled from 'styled-components'
import {
  Drawer as DrawerComp,
  ModalNew as ModalComp,
  Button as ButtonComp,
} from 'components'
import * as DrawerStyles from 'components/Drawer/styles'
import * as ModalStyles from 'components/Modal/stylesNew'
import * as InputRadioStyles from 'components/Inputs/Radio/styles'
import * as InputSelectStyles from 'components/Inputs/Select/styles'

export const Drawer = styled(DrawerComp)`
  width: ${({ theme }) => theme.spacing(56)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;

  ${DrawerStyles.DrawerHeader} {
    padding: 0;
  }

  ${DrawerStyles.DrawerBody} {
    padding-top: ${({ theme }) => theme.spacing(3)}px;
  }

  ${InputRadioStyles.RadioWrapper} {
    display: flex;
    justify-content: flex-start;
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
    label {
      margin: 0;
      color: ${({ theme }) => theme.colors.gray6};
      font-weight: 400;
      &:first-child {
        margin-right: ${({ theme }) => theme.spacing(4)}px;
      }
      input {
        margin-right: ${({ theme }) => theme.spacing(2)}px;
      }
    }
  }

  ${DrawerStyles.DrawerFooter} {
    padding: 0;
  }
`

export const Modal = styled(ModalComp)`
  ${ModalStyles.ModalContainer} {
    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    @media ${props => props.theme.mediaQueries.xs} {
      height: 100%;
    }
  }

  ${ModalStyles.ChildrenWrapper} {
    flex-grow: 1;
    margin-bottom: ${({ theme }) => theme.spacing(7)}px;
  }

  ${InputRadioStyles.RadioWrapper} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
    label {
      margin: 0;
      color: ${({ theme }) => theme.colors.gray6};
      font-weight: 400;
      &:first-child {
        margin-right: ${({ theme }) => theme.spacing(4)}px;
      }
      input {
        margin-right: ${({ theme }) => theme.spacing(2)}px;
      }
    }
  }

  ${InputSelectStyles.Overlay} {
    height: fit-content;
  }

  ${ModalStyles.ModalFooter} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
    padding-top: 0;
    background-color: ${({ theme }) => theme.colors.white};
    border-top: 1px solid ${({ theme }) => theme.colors.gray3};
    width: calc(100% - 28px);
    margin: auto;
  }
`

export const Button = styled(ButtonComp)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`
