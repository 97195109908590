import React from 'react'

import Divisor from 'components/Divisor'

import { CardType } from 'pages/Consortium/SolicitationProcess/Types/CardType'

import FatcaForm from './FatcaForm'
import DocumentForm from './DocumentForm'

import * as s from './styles'

const DocumentCard: React.FC<CardType> = ({
  formData: [formData, setFormData],
}) => {
  return (
    <s.WrapperDocument>
      <DocumentForm formData={[formData, setFormData]} />

      <Divisor />

      <FatcaForm formData={[formData, setFormData]} />
    </s.WrapperDocument>
  )
}

export default DocumentCard
