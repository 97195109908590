import React from 'react'
import { useSelector } from 'react-redux'

import { TOptions, TSelected } from 'components/Inputs/Select/types'

import { IPepType } from 'store/modules/Consortium/PpeTypes/types'
import { ApplicationState } from 'store/ApplicationState'

import { TextsConsortium } from 'pages/Consortium/commons/Texts'
import { CardType } from 'pages/Consortium/SolicitationProcess/Types/CardType'

import * as s from './styles'

const PpeCard: React.FC<CardType> = ({ formData: [formData, setFormData] }) => {
  const {
    hire: {
      steps: {
        informationConfirmation: {
          containerFifth: { text },
        },
      },
    },
  } = TextsConsortium()

  const [ppeSelect, setPpeSelect] = React.useState<TSelected>({
    id: 0,
    name: '',
  })

  const getSelectOptions = (optionsData: IPepType[] | null) => {
    const options: TOptions[] = [
      {
        id: 'PEP',
        text: 'Eu mesmo',
        onClick: () => {
          setFormData({
            ...formData,
            pep: {
              ...formData.pep,
              tipo: 'PEP',
              grau_pep: '',
            },
          })
          setPpeSelect({ id: 'PEP', name: 'Eu mesmo' })
        },
      },
    ]
    if (formData.pep.tipo === 'PEP' && ppeSelect.name === '') {
      setPpeSelect({ id: 'PEP', name: 'Eu mesmo' })
    }
    if (optionsData) {
      optionsData.forEach((option: IPepType) => {
        options.push({
          id: option.codigo,
          text: option.descricao,
          onClick: () => {
            setFormData({
              ...formData,
              pep: {
                ...formData.pep,
                grau_pep: option.codigo,
                tipo: 'RELACIONAMENTO_PROXIMO',
              },
            })
            setPpeSelect({ id: option.codigo, name: option.descricao })
          },
        })

        if (ppeSelect.id === option.codigo && ppeSelect.name === '') {
          setPpeSelect({ id: option.codigo, name: option.descricao })
        }
      })
    }

    return options
  }

  const getRadionOptions = (optionsData: IPepType[] | null) => {
    const options: any = []

    if (optionsData && optionsData.length) {
      optionsData.forEach((element: any) => {
        if (element.codigo !== 'RELACIONAMENTO_PROXIMO') {
          options.push({
            value: element.codigo,
            name: element.descricao,
          })
        }
      })
    }

    if (options.length === 0) {
      return [
        {
          value: '1',
          name: 'Sim',
        },
        {
          value: '2',
          name: 'Não',
        },
      ]
    }

    return options
  }

  const { pepConsortiumRelations, ConsortiumPpeType } = useSelector(
    (state: ApplicationState) => {
      return {
        pepConsortiumRelations: getSelectOptions(
          state.consortiumPpeRelations.data || []
        ),
        ConsortiumPpeType: getRadionOptions(state.consortiumPpeType.data || []),
      }
    }
  )

  return (
    <s.ContainerPpeCard>
      <s.TextTitlePpeCard>{text.title}</s.TextTitlePpeCard>
      <s.TextPpeCard>{text.content}</s.TextPpeCard>
      <s.RadioOptionsWrapper data-testid="radio-ppe">
        <s.RadioPpeCard
          options={ConsortiumPpeType}
          checkedItem={
            formData.pep.tipo !== 'NAO_PEP'
              ? ConsortiumPpeType[0].value
              : ConsortiumPpeType[1].value
          }
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFormData({
              ...formData,
              pep: {
                ...formData.pep,
                tipo: e.currentTarget.value,
              },
            })
          }
        />
      </s.RadioOptionsWrapper>
      {formData.pep.tipo !== 'NAO_PEP' ? (
        <s.DelimiterWrapper>
          <s.Select
            label={text.label}
            options={pepConsortiumRelations}
            selected={ppeSelect}
            data-testid="ppe-select"
          />
        </s.DelimiterWrapper>
      ) : null}
    </s.ContainerPpeCard>
  )
}

export default PpeCard
